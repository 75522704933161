const EventImageList =[
  {
    imageDir: "item-1Thousand", itemType: "1000", enjoyWithFriend: true, options: [
       {imageURL: "drone.png", itemName: "small drone with camera" },
       {imageURL: "arcadeGame.png", itemName: "arcade game" },
    ]
  },
 {
    imageDir: "item-1Thousand-noFriend", itemType: "1000", enjoyWithFriend: false, options: [
      {imageURL: "bicycle.png", itemName: "bicycle" },
      {imageURL: "camera.png", itemName: "camera" },
      {imageURL: "cellphone.png", itemName: "cell phone" },
      {imageURL: "guitar.png", itemName: "guitar" },
      {imageURL: "watch.png", itemName: "smart watch" },
    ]
 },

  {
    imageDir: "item-5Thousand", itemType: "5000", enjoyWithFriend: true, options: [
      {imageURL: "ATV.png", itemName: "all-terrain-vehicle (ATV)" },
      {imageURL: "virtualRealityHeadset.png", itemName: "virtual reality headset and gaming setup" },
    ]
  },
  
  {
    imageDir: "item-5Thousand-noFriend", itemType: "5000", enjoyWithFriend: false, options: [
      {imageURL: "television.png", itemName: "television, sound bar and streaming devices" },
      {imageURL: "electricKeyboard.png", itemName: "electric piano (keyboard)"},
      {imageURL: "designerHandbag.png", itemName: "designer handbag"},
    ]
  },

  {imageDir: "item-purchase-default", itemType: "default", enjoyWithFriend: false, options: [
      {imageURL: "thingamajig.png", itemName: "thingamajig"},
    ]
  },
  
  { imageDir: "puppies", options:[
      {imageURL: "puppy.png"}
    ]
  },
  
  
];

export default EventImageList;