import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DisplayPurchasesCard from './display_purchases_card';

class DisplayPurchasesModal extends Component {
  constructor(props) {
    super(props);
//    this.gameInfo = this.props.gameInfo();
    this.state={
        
    };   
  }
  
  
  render() { 
   
      this.purchases = this.props.purchaseTable.map((purchaseInfo, key) =>
          <DisplayPurchasesCard key={key} purchaseList={purchaseInfo}
              />
      );
   
  return <>
   
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} scrollable="true"
            size="med" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Your Game Purchases</Modal.Title> 
           
          </Modal.Header>
          <Modal.Body className="purchases-background">
              <h5 className="text-center decorative mb-4">
                  Here are all the cool things that you bought!!!
              </h5>
              <table className="mt-2S">
                {this.purchases}
              </table>
          </Modal.Body>
          <Modal.Footer className="backgroundTopBottom">
              <Button  variant="info"   onClick={() => this.props.onClose()}>
                   Close
              </Button>
          </Modal.Footer>
          </Modal>
    
    </>
  }
 
}
export default DisplayPurchasesModal;