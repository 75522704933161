const updateAllGameData = {
    assetTable: [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Electrical Engineer --> Annual Salary: $64,000.00",
          "assetAmt": "5333.33",
          "assetStartDate": "2022-10",
          "assetNbrMonths": "-1"
        },
        {
            "assetItem": "Job Salary test of update all",
            "assetDesc": "Testing new entry",
            "assetAmt": "8888.88",
            "assetStartDate": "2024-02",
            "assetNbrMonths": "-1"
          }
      ],
    expenseTable: [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": "2291.83",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Meadow Hills-1 bedroom, 0 roommates",
          "expAmount": 1751,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "115.56",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 380,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Test Expense",
          "expDesc": "This expense is added using the update all router.",
          "expAmount": 9999,
          "expStartDate": "2024-02",
          "expNbrMonths": "-1"
        }
      ],
    loanTable: [
        {
          "loanType": "Car Loan",
          "loanDesc": "Mid-Size Car - 3 to 5 Years Old",
          "loanAmt": "300.21",
          "loanStartDate": "2022-10",
          "loanNbrMonths": 72,
          "loanTotAmt": 16205,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2022-10",
          "loanNbrMonths": 120,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        },
        {
          "loanType": "Test Loan",
          "loanDesc": "Test loan for the update all router",
          "loanAmt": "9999.99",
          "loanStartDate": "2024-02",
          "loanNbrMonths": 999,
          "loanTotAmt": "99999.99",
          "loanIntRate": 0.099,
          "loanTerm": 99
        }
      ],
    conditionsTable: [
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "electrical-engineer",
          "conditionClass": "career"
        },
        {
          "condName": "test-condition-update-all",
          "conditionClass": "game"
        }
      ],
    iconTable: [
        {
          "iconName": "gameBasicHelp",
          "iconCategory": "information",
          "className": "small_sign_thumbnail_img information-sign-position",
          "imageSrc": "gameHelp.png",
          "validInd": true,
          "firstTime": true,
          "additionalInfo": ""
        },
        {
          "iconName": "testingNewIcon",
          "iconCategory": "information",
          "className": "small_sign_thumbnail_img information-sign-position",
          "imageSrc": "gameHelp.png",
          "validInd": true,
          "firstTime": true,
          "additionalInfo": ""
        }
      ],
    pendingJob: {test: "testing an entry", date: "2024-02", description: "test entry for update all"},
    gameNbrMonths: 999,
    nbrMonthsNegative: -5,
    nbrMonthsMoodNegative: -5,
    carInfo: {
        "carID": "999updateAll99999",
        "carAge": 4,
        "carYear": "2024",
        "carType": "test"
      },
    jobInfo: {
        "careerID": "999updateAll99999",
        "healthInsInd": "false",
        "jobRank": "8",
        "nbrYearsCollege": "4",
        "nbrSkillSurvey": "1",
        "jobStartDate": "2022-10"
      },
    spendingHistory: {
        "currentMonthSpendAmt": 999,
        "current10YearSpendAmt": 9999,
        "current10YearMonths": 1,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
    currentMoodPoints: 99999,
    moodPointHistory: {
        "currentMonthMoodPoints": 999,
        "totalMoodPoints": 99999,
        "moodPointMonths": 1
      }
}

export default updateAllGameData;