const newArchiveGameData = {
    "assetTable": [
      {
        "assetItem": "Job Salary",
        "assetDesc": "Accountant --> Annual Salary: $54,762.00",
        "assetAmt": "4563.50",
        "assetStartDate": "2023-11",
        "assetNbrMonths": "-1"
      }
    ],
    "expenseTable": [
      {
        "expItem": "Payroll taxes",
        "expDesc": "Federal, FICA (Social Security)",
        "expAmount": 678.33,
        "expStartDate": "2023-11",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Apartment Rent",
        "expDesc": "Brookside-2 bedrooms, 1 roommate",
        "expAmount": "561.00",
        "expStartDate": "2023-11",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Health Insurance",
        "expDesc": "Health Insurance 70% Paid by Employer",
        "expAmount": "138.85",
        "expStartDate": "2023-11",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Automobile Insurance",
        "expDesc": "Insurance for car repair or replacement",
        "expAmount": "77.25",
        "expStartDate": "2023-11",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Renter's Insurance",
        "expDesc": "Insurance for furniture and other personal items",
        "expAmount": 30,
        "expStartDate": "2023-11",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Misc Expenses",
        "expDesc": "Food, Clothes, Personal Items, etc.",
        "expAmount": "413.10",
        "expStartDate": "2023-11",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Phone",
        "expDesc": "Phone - Full Data Plan",
        "expAmount": 100,
        "expStartDate": "2023-12",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Pet Expenses",
        "expDesc": "Dog Food (Black Lab)",
        "expAmount": "60",
        "expStartDate": "2023/11/18",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Automobile Insurance",
        "expDesc": "Auto Insurance Claim Increase - Speeding Ticket",
        "expAmount": "16.50",
        "expStartDate": "2024-07",
        "expNbrMonths": 20
      },
      {
        "expItem": "Automobile Insurance",
        "expDesc": "Auto Insurance Increase - Body Work",
        "expAmount": "13.50",
        "expStartDate": "2024-07",
        "expNbrMonths": 20
      },
      {
        "expItem": "Streaming Movies",
        "expDesc": "Basic Package($)",
        "expAmount": "50",
        "expStartDate": "2025-04",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Misc Expenses",
        "expDesc": "Save the Earth Charity",
        "expAmount": "50",
        "expStartDate": "2025-05",
        "expNbrMonths": 6
      },
      {
        "expItem": "Transportation",
        "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
        "expAmount": "173.33",
        "expStartDate": "2025-07",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Misc Expenses",
        "expDesc": "Feed the World Charity",
        "expAmount": "50",
        "expStartDate": "2025-09",
        "expNbrMonths": 10
      }
    ],
    "loanTable": [
      {
        "loanType": "College Loan",
        "loanDesc": "Business Degree-Accounting(4 years)",
        "loanAmt": "474.65",
        "loanStartDate": "2023-11",
        "loanNbrMonths": 96,
        "loanTotAmt": "48000.00",
        "loanIntRate": 0.035,
        "loanTerm": 10
      },
      {
        "loanType": "Car Loan",
        "loanDesc": "Mid-Size Car - More than 5 Years Old",
        "loanAmt": "197.93",
        "loanStartDate": "2025-07",
        "loanNbrMonths": 68,
        "loanTotAmt": "10683.88",
        "loanIntRate": 0.1,
        "loanTerm": 6
      }
    ],
    "conditionsTable": [
      {
        "condName": "roommate",
        "conditionClass": "home"
      },
      {
        "condName": "homeType-apartment",
        "conditionClass": "home"
      },
      {
        "condName": "cheap-furniture",
        "conditionClass": "furniture"
      },
      {
        "condName": "cheap-dining-chairs",
        "conditionClass": "furniture"
      },
      {
        "condName": "cheap-bed",
        "conditionClass": "furniture"
      },
      {
        "condName": "cheap-lounge-chair",
        "conditionClass": "furniture"
      },
      {
        "condName": "accountant",
        "conditionClass": "career"
      },
      {
        "condName": "own-a-dog",
        "conditionClass": "game",
        "duration": -1
      },
      {
        "condName": "auto-insurance-increase-ticket",
        "conditionClass": "game",
        "duration": 20
      },
      {
        "condName": "broken-refrigerator",
        "conditionClass": "home",
        "duration": -1
      },
      {
        "condName": "auto-insurance-increase-accident",
        "conditionClass": "game",
        "duration": 20
      },
      {
        "condName": "1-year-elapsed-in-game",
        "conditionClass": "game"
      },
      {
        "condName": "1-year-after-job-start",
        "conditionClass": "job"
      },
      {
        "condName": "save-the-earth",
        "conditionClass": "game",
        "duration": 6
      },
      {
        "condName": "level1-promotion",
        "conditionClass": "careerRelated",
        "duration": -1
      },
      {
        "condName": "car-gt-5-years-old",
        "conditionClass": "car"
      },
      {
        "condName": "feed-the-world",
        "conditionClass": "game",
        "duration": 10
      },
      {
        "condName": "good-manager",
        "conditionClass": "manager",
        "duration": -1
      },
      {
        "condName": "in-college",
        "conditionClass": "job"
      },
      {
        "condName": "college-exam",
        "conditionClass": "college",
        "duration": -1
      }
    ],
    "skillSurvey": [
      "4",
      "5",
      "5",
      "5",
      "5",
      "4",
      "5",
      "4",
      "5",
      "4",
      "5",
      "5"
    ],
    "iconTable": [
      {
        "iconName": "gameBasicHelp",
        "iconCategory": "information",
        "className": "small_sign_thumbnail_img information-sign-position",
        "imageSrc": "gameHelp.png",
        "validInd": true,
        "firstTime": false,
        "additionalInfo": ""
      },
      {
        "iconName": "savingsUnlocked",
        "iconCategory": "goodNews",
        "className": "small_sign_thumbnail_img good-news-savings-position",
        "imageSrc": "savings.png",
        "validInd": true,
        "firstTime": false
      },
      {
        "iconName": "pendingJob",
        "iconCategory": "goodNews",
        "className": "small_sign_thumbnail_img good-news-college-position",
        "imageSrc": "CollegeSign.png",
        "validInd": true,
        "firstTime": false,
        "additionalInfo": {
          "firstTimeText": true,
          "careerName": "Administrative Assistant"
        }
      }
    ],
    "unlockedFeatures": [
      {
        "featureName": "funStuff",
        "setup": "active",
        "level": 1,
        "lastNotifyDate": "2024-01"
      },
      {
        "featureName": "savings",
        "setup": "none",
        "level": 1,
        "lastNotifyDate": "2025-11"
      }
    ],
    "friendList": [],
    "badgesEarned": [
      {}
    ],
    "user": "StudentX",
    "jobInfo": {
      "careerID": "612bc849600d610c45b6e569",
      "healthInsInd": "true",
      "jobRank": "1",
      "nbrYearsCollege": "4",
      "nbrSkillSurvey": "2",
      "jobStartDate": "2025-11"
    },
    "homeInfo": {
      "homeID": "6132415297d929c9a8cf870c",
      "homeType": "apartment",
      "roommates": "1",
      "bedrooms": "2"
    },
    "furnitureID": "6132a41a97d929c9a8cf8717",
    "carInfo": {
      "carID": "6132c3f897d929c9a8cf8725",
      "carAge": 100,
      "carYear": "2017",
      "carType": "200"
    },
    "startingCash": "540.00",
    "currentCash": "17188.07",
    "currentMoodPoints": "7977",
    "startGameDate": "2023-11",
    "currentGameDate": "2025-11",
    "gameNbrMonths": "336",
    "prevGameMonths": "33",
    "nbrMonthsNegative": "0",
    "nbrMonthsMoodNegative": "0",
    "pendingJob": {
      "class": "career",
      "jobStartDate": "2027-04",
      "jobInfo": {
        "careerID": "6130f3c4de8f072b97fd259c",
        "careerName": "Administrative Assistant",
        "newMonthlySalary": "2918.33",
        "healthInsInd": "true",
        "changeHealthIns": false,
        "jobRank": "3",
        "totalNbrYearsCollege": 5,
        "nbrYearsCollege": 1
      },
      "jobConditions": [
        {
          "condName": "administrative-assistant",
          "conditionClass": "career"
        }
      ],
      "loanTable": [
        {
          "loanType": "College Loan",
          "loanDesc": "Associates Degree-Business Admin(Year 1)",
          "loanAmt": "71.20",
          "loanStartDate": "2026-10",
          "loanNbrMonths": 120,
          "loanTotAmt": "7200.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Associates Degree-Business Admin(Year 2)",
          "loanAmt": "35.60",
          "loanStartDate": "2027-10",
          "loanNbrMonths": 120,
          "loanTotAmt": "3600.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "pictureInfo": {
        "careerID": "6130f3c4de8f072b97fd259c",
        "careerName": "Administrative Assistant",
        "careerPictureImg": "./assets/Jobs/business_casual.png",
        "careerBackgroundImg": "./assets/jobBackground/office-cubicles.png",
        "jobMaxSalary": "6439.74",
        "jobLevel3": "Executive Administrative Assistant"
      }
    },
    "spendingHistory": {
      "currentMonthSpendAmt": 15,
      "current10YearSpendAmt": 78715.58,
      "current10YearMonths": 25,
      "previous10YearSpendAmt": 0,
      "previous10YearMonths": 0
    },
    "moodPointHistory": {
      "currentMonthMoodPoints": -200,
      "totalMoodPoints": -893,
      "moodPointMonths": 20
    },
    "dateArchived": "2024/01/15",
  }

  export default newArchiveGameData;