import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import '../components/game-views/GameViews/gameViews.css';
import {Tooltip} from "@mui/material";
import reduxSetGameInfo from './Objects/redux_set_game_info';
import LinkTab from '../components/shared-components/link_tab';
import GameBoard from '../components/game-views/game-board';
import ChangeGameChoices from '../components/game-views/change_game_choices';
import ChangeSkillsSurvey from '../components/game-views/change_skills_survey';
import ChangeCareer from '../components/game-views/change_career';
import HomeChange from '../components/game-views/home_change';
import CarChange from '../components/game-views/car_change';
import UnlockedFeatures from '../components/game-views/unlocked_features';
import StatusDisplay from '../components/game-views/status_display';
import EndGame from '../components/end-game/end_game';
import CommentModal from '../components/shared-components/comment_modal';
import BadgesRibbon from '../components/game-views/badgesRibbon';
import SpinningBadge from '../components/game-views/spinning_badge';
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import LeaderBoardModal from '../components/game-views/leader_board_modal';
import MoodRetirementStatusModal from '../components/game-views/mood_retirement_status_modal';
import WaitTimeSpinner from '../components/shared-components/wait_time_spinner';

import GameChoicePictures from './PageComponents/game_choice_pictures';
import getGameChoicePictures from './Objects/get_game_choice_pictures';
import screenSizeFunction from '../objects/CommonUse/screen_size_function';
import { calculateMoodRetirementScores } from '../components/game-views/objects/calculate_mood_retirement_scores';
import BadgesInfoDataset from '../components/game-views/badgesDatasets/badges_info_dataset';
import EarnBadge from '../components/game-views/earn_badge';
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import {buildUrl } from '../objects/CommonUse/build_URL';
import Config from '../config';
import ConfigFile from '../datasets/config_file';
import { rollForwardCash } from '../components/game-views/objects/roll_forward_cash_function';
import { reconcileUnlockedFeatures } from '../components/game-views/objects/reconcile_unlocked_features';
import tooltipCommentIconText from './Text/tooltip_text';
import { resetPicturesForChanges } from '../components/game-views/objects/reset_pictureInfo_for_changes';
import beforeAfterCompare from '../objects/CommonUse/before_after_compare';


class GameView extends React.Component {
    constructor(props) {
        super(props);

        this.inputSelected = (inputID) => {
            for (const input of this.state.inputArr) {
                input.selected = false;
            }
            this.state.inputArr[inputID].selected = true;
            if (this._isMounted === true){
                this.setState({inputArr: this.state.inputArr});
            }
            if (inputID ==3){
                this.statusProcess = "";
            }
        };

        this.prevIndex = 0;
        this.openCommentModal = this.openCommentModal.bind(this);
        this.closeCommentModal = this.closeCommentModal.bind(this);

        this.state = {
            inputArr: [
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                }
            ],
            inputCompleted: this.inputCompleted,
            inputSelected: this.inputSelected,
            nbrMonthsOfPlay: 0,
            modalOpen: false,
            commentIcon: "./assets/ContactUs/pencilPaper.png",
            commentModalOpen: false,
            fromProcessName: "GameView",
            displayLeaderboard: false,
            prevProcess: "",
            username: null,
            moodRotation: -45,
            retirementRotation: 45,
            moodRetirementOpen: false,
            screenSize: sessionStorage.getItem("GameScreenSize"),
            badgeArr: [],
            nextBadge: 0,
            screenWidth: screenSizeFunction().screenWidth,
            screenHeight: screenSizeFunction().screenHeight,
            initialLeft: 0,
            initialTop: 0,
            gameLoaded: false,
            eventConditionsLoaded: false,
            creditCardLoaded: false,
            savingsLoaded: false,
            funStuffLoaded: false,
            timeWizardLoaded: false,
            gamePurchasesLoaded: false,
            friendsLoaded: false,
            loadMoneyMgmtTest: false,
            headers: setHeadersForFetch(),
            environment: Config.dataURL.currentEnv,
            subProcess: "gameView",
            gameInfo:{},
            jobChoiceLoaded: false,
            carChoiceLoaded: false,
            homeChoiceLoaded: false
        };

        switch(props.selected) {
            case "gameBoard":
                this.inputSelected(0);
                break;
            case "gameChoices":
                this.inputSelected(1);
                break;
            case "unlockedFeatures":
                this.inputSelected(2);
                break;
            case "statusDisplay":
                this.inputSelected(3);
                break;
            case "endGame":
                this.inputSelected(4);
                break;
        }
        this.previousProcess = "GameView";
        this.allDataLoaded = false;
        this.totalNbrGameMonths = ConfigFile.gameStartItems.totalNbrGameMonths;
        this.readyToLoadPictures = false;
      }
    
      render() {
            if (this.state.gameLoaded === true && this.state.friendsLoaded === true &&
                this.state.creditCardLoaded === true && this.state.eventConditionsLoaded === true &&
                this.state.funStuffLoaded === true && this.state.savingsLoaded === true &&
                this.state.timeWizardLoaded === true && this.state.gamePurchasesLoaded === true && 
                this.state.loadMoneyMgmtTest === true && 
                this.state.gameInfo.pictureInfo.jobID !== undefined){
                    this.gameInfoDisplay=this.state.gameInfo;
                    if (this.allDataLoaded === false){
                        console.log("totalNbrGameMonths: ", this.totalNbrGameMonths)
                        this.allDataLoaded = true;
                        this.setGameInfo(this.allDataLoaded, "allDataLoaded");
                    }
            }

            if (this.allDataLoaded === true){
                if (this.state.gameInfo.gameData.currentCash != undefined){
                    this.calcMoodRetirementScores();
                }
            }
                   
            this.setScreenSize();
            if (this.state.screenSize == "narrow"){
                 this.badgeRibbonClass = "badge-ribbon-position-narrow";
                 this.firstColumnClass = "col-sm-1 mt-4 ml-3"
            }else{
                if (this.state.screenSize == "medium"){
                    this.badgeRibbonClass = "badge-ribbon-position-medium";
                    this.firstColumnClass = "col-sm-1 mt-4 ml-3"
                }else{
                    this.badgeRibbonClass = "badge-ribbon-position";
                    this.firstColumnClass = "col-sm-2 mt-4 ml-3"
                }
            }
            if (this.props.selected !== "gameBoard"){
                this.previousProcess = this.props.selected;
            }
           
            let badgeList = this.state.badgeArr.map((badge, index) =>
                <BadgesRibbon key={index} badgeInfo={badge} index={index} 
                    initialLeft={this.state.initialLeft} initialTop={this.state.initialTop}
                    openBadge={(index) =>this.openBadgeDisplay(index)}/>
            );
        return <>
            <span className="container m-0 p-0">
            <span className="row w-100 mx-0">
                    <div className={this.firstColumnClass}>
                        <div>
                            {this.readyToLoadPictures ?
                                <div>
                                    <GameChoicePictures 
                                        screenSize={this.state.screenSize}
                                        pictureInfo={this.state.gameInfo.pictureInfo}
                                    />
                                </div>
                            :
                                    null
                            }
                        </div>
                    </div>
                    {!this.allDataLoaded ? 
                        <div className={this.waitSpinnerClass}>
                            <WaitTimeSpinner/>
                        </div>
                    :
                        null
                    }

                        {this.state.moodRetirementOpen  ?
                            <div>
                                <MoodRetirementStatusModal 
                                    screenSize={this.state.screenSize}
                                    modalOpen={this.state.moodRetirementOpen} 
                                    onClose={() => this.closeMoodRetirementModal()}
                                    gameInfo = {() => this.getGameInfo()}
                                />
                            </div> 
                        :
                            null
                        }

                        <div>
                            <Tooltip title={tooltipCommentIconText} arrow >
                                <img src={this.state.commentIcon} 
                                    className="comment-icon-position comment-img cursor-is-pointer" 
                                    alt="comment"
                                    onClick={this.openCommentModal}/>
                            </Tooltip>
                        </div> 
                            
                        {this.state.commentModalOpen ?
                            <CommentModal modalOpen={this.state.commentModalOpen} 
                                onClose={() => this.closeCommentModal()}
                                gamePart={this.state.fromProcessName}
                                processName={this.props.selected}
                                btnText={this.btnText}
                                gameInfo = {() => this.getGameInfo()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                        :
                            null
                        }

                        <div className={this.badgeRibbonClass}>
                            {badgeList}
                        </div>

                        {this.state.openBadge ?
                            <div >
                                <SpinningBadge
                                    show={this.state.openBadge}
                                    parameters={this.state.badgeArr[this.badgeIndx]}
                                    pictureURL={this.pictureURL}
                                    awardType={this.awardType}
                                    leftStyle={this.leftStyle}
                                    modalClass={this.modalClass}
                                    textClass={this.badgeTextClass}
                                    badgeImgClass={this.badgeImgClass}
                                    onClose={() => this.closeBadge()}/>
                            </div>
                        :
                            null
                        }

                        {this.props.selected === "gameBoard"  &&
                            this.allDataLoaded ? 
                            <GameBoard completed={() => this.inputCompleted(0)}
                                onOpen ={()=> this.inputSelected(0)} 
                                onEnd={(endGameReason) => this.endGame(endGameReason)}
                                homeChangeEnable = {()=> this.inputUncompleted(1)}
                                carChangeEnable= {()=> this.inputUncompleted(2)}
                                openMoodRetirement={this.state.moodRetirementOpen}
                                closeMoodRetirementModal={()=> this.closeMoodRetirementModal()}
                                gameInfo = {() => this.getGameInfo()} 
                                moodEmoji = {this.state.moodEmoji}
                                screenSize={this.state.screenSize}
                                nextBadge={this.state.nextBadge}
                                previousProcess={this.previousProcess}
                                totalNbrGameMonths={this.totalNbrGameMonths}
                                loadBadges = {(badgesEarned) => this.updateBadgeArr(badgesEarned)}
                                setGameInfo = {(value, propertyName, subPropertyName) => 
                                    this.setGameInfo(value, propertyName, subPropertyName)}
                                checkDataAccuracy = {(dataObj) => this.checkDataAccuracy(dataObj)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            /> : <span/>}
                        
                            {this.props.selected === "gameChoices"  && 
                                this.allDataLoaded ? 
                                    <ChangeGameChoices 
                                    screenSize={this.state.screenSize}
                                    completed={() => this.inputCompleted(1)}
                                    onOpen ={()=> this.inputSelected(1)} 
                                    gameInfo = {() => this.getGameInfo()}
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    />
                            : <span/>}
                            
                    
                            {this.props.selected === "changeSkillsSurvey" &&
                                this.allDataLoaded ? 
                                <ChangeSkillsSurvey 
                                    gameInfo = {()=> this.getGameInfo()}
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    /> 
                            : <span/>}
                            {this.props.selected === "changeCareer"  &&
                                this.allDataLoaded ?  
                                <ChangeCareer
                                    screenSize={this.state.screenSize}
                                    onOpen={()=>this.inputSelected(1)}
                                    onChangeComplete ={()=> this.setPrevProcess("jobChange")} 
                                    gameInfo = {() => this.getGameInfo()} 
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                />
                            : <span/> }                               
                        {this.props.selected === "changeHome" ? 
                            <HomeChange 
                                screenSize={this.state.screenSize}
                                onChangeComplete ={()=> this.setPrevProcess("homeChange")} 
                                gameInfo = {() => this.getGameInfo()}
                                setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                        : <span/>}
                        {this.props.selected === "changeCar" &&
                            this.allDataLoaded ?  
                            <CarChange 
                                screenSize={this.state.screenSize}
                                onChangeComplete ={()=> this.setPrevProcess("carChange")} 
                                gameInfo = {() => this.getGameInfo()} 
                                setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                            : <span/>}
                        
                            {((this.props.selected === "unlockedFeatures" || this.props.selected === "setupSavings" ||
                               this.props.selected === "setupCreditCard"  || this.props.selected === "setupFunStuff" ||
                               this.props.selected === "setupTimeWizard" )  &&
                               this.allDataLoaded) ?  
                                <UnlockedFeatures 
                                    screenSize={this.state.screenSize}
                                    selected={this.props.selected}
                                    totalNbrGameMonths={this.totalNbrGameMonths}
                                    completed={() => this.inputCompleted(1)}
                                    onOpen ={()=> this.inputSelected(1)} 
                                    gameInfo = {() => this.getGameInfo()}
                                    checkUnlockedFeatures = {() => this.checkAndValidateUnlockedFeatures()}
                                    resetTotalNbrGameMonths={(totalNbrGameMonths) => 
                                        this.resetTotalNbrGameMonths(totalNbrGameMonths)}
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                />
                                : <span/>}
                                                
                            {(this.props.selected === "statusDisplay" || 
                              this.props.selected == "monthlyStatus")  &&
                              this.allDataLoaded ?  
                                <StatusDisplay
                                    screenSize={this.state.screenSize}
                                    selected={this.props.selected}
                                    onOpen ={()=> this.inputSelected(3)}
                                    prevProcess={this.state.prevProcess}
                                    gameInfo = {() => this.getGameInfo()} 
                                    clearPrevProcess ={()=> this.setPrevProcess("clear")}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    /> 
                                : <span/>}
                            {this.props.selected ==="endGame"   &&
                                this.allDataLoaded ? 
                                <EndGame
                                    totalNbrGameMonths={this.totalNbrGameMonths}    
                                    screenSize={this.state.screenSize}
                                    endGameReason={this.endGameReason}
                                    onOpen ={()=> this.inputSelected(4)}
                                    completed={() => this.inputCompleted(4)}
                                    gameInfo = {() => this.getGameInfo()} 
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                />
                                : <span/>    
                            }

                            {this.props.selected ==="earnBadge"  ?
                                <EarnBadge
                                    screenSize={this.state.screenSize}
                                    backgroundURL={this.state.gameInfo.backgroundURL}
                                    parameters={this.state.badgeArr[this.state.nextBadge - 1]}
                                    nextBadge={this.state.nextBadge}
                                    gameInfo = {() => this.getGameInfo()} 
                                    updateBadgeArr={(badgeObj) => this.updateBadgeArr(badgeObj)}
                                    openBadge={(badgeNbr) => this.openBadgeDisplay(badgeNbr)}
                                    onClose={() => this.closeBadge()}
                                    setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                />
                                : <span/>    
                            }
                        
                            {!this.props.selected ? 
                                <div className="col-sm-6 ml-0 mb-5 input-page-base-card">
                                <div className="card mt-3 mr-5 rounded-corners-lg text-center"></div></div> 
                            : <span/>}
                            {this.state.displayLeaderboard === true ?
                                <LeaderBoardModal modalOpen={true}
                                    onClose={() => this.closeOutGame()} 
                                    username={this.state.username}
                                    nbrGameMonths={this.totalNbrGameMonths}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                    />
                                :
                                <span/>
                            }
                            
                    
                        <div className="col-sm-2 ml-5 ">
                            <div className={this.moodRetirementClass}
                                onClick={() => this.openMoodRetirementModal()}>
                                    <img src={'./assets/arrow.png'} 
                                        className={this.moodArrowClass}  alt={'arrow'}
                                        style={{transform: `rotate(${this.state.moodRotation}deg)`}}/>
                                    <img src={'./assets/arrow.png'}
                                        className={this.retirementArrowClass} alt={'arrow'}
                                        style={{transform: `rotate(${this.state.retirementRotation}deg)`}}/>
                            </div>
                                                            
                            {!this.state.inputArr[0].completed ?
                                <Link to="game-board" onClick={() => this.handleClick(0)}>
                                    <LinkTab inputArr={this.state.inputArr[0]} name="Game Board" validToComplete="true" 
                                        screenSize={this.state.screenSize}/>
                                </Link>
                            :
                                <LinkTab inputArr={this.state.inputArr[0]} name="Game Board" 
                                    screenSize={this.state.screenSize}/>
                            }           
                             {!this.state.inputArr[1].completed ?
                                <Link to="game-choices" onClick={() => this.handleClick(1)}>
                                    <LinkTab inputArr={this.state.inputArr[1]} name="Change Game Choices" validToComplete="true" 
                                        screenSize={this.state.screenSize}/>
                                </Link>
                            :
                                <LinkTab inputArr={this.state.inputArr[1]} name="Change Game Choices"
                                        screenSize={this.state.screenSize}/>
                            }                                       
                            {!this.state.inputArr[2].completed ?
                                <Link to="unlocked-features" onClick={() => this.handleClick(2)}>
                                    <LinkTab inputArr={this.state.inputArr[2]} name="Unlocked Features" validToComplete="true" 
                                        screenSize={this.state.screenSize}/>
                                </Link>
                            :
                                <LinkTab inputArr={this.state.inputArr[2]} name="Unlocked Features" 
                                    screenSize={this.state.screenSize}/>
                            }                 
                            {!this.state.inputArr[3].completed ?
                                <Link to="status-display" onClick={() => this.handleClick(3)}>
                                    <LinkTab inputArr={this.state.inputArr[3]} name="How am I Doing?" validToComplete="true" 
                                        screenSize={this.state.screenSize}/>
                                </Link>
                            :
                                <LinkTab inputArr={this.state.inputArr[3]} name="How am I Doing?"
                                     screenSize={this.state.screenSize}/>
                            }                                     
                                                   
                            <Link to="end-game" onClick={() => this.endGame()}>
                                <LinkTab inputArr={this.state.inputArr[4]} name="Finish Game" validToComplete="true"
                                     screenSize={this.state.screenSize}/>
                            </Link>
                        </div>
                    </span>
                   
                </span>
        </>
    }

    componentDidMount(){
        this._isMounted = true;
        const gameInfo = reduxSetGameInfo("");
        this.setState({gameInfo: gameInfo});
        this.state.gameInfo = gameInfo;
        if (gameInfo.allDataLoaded === false){
            this.refreshGameInfo();
        }else{
            this.allDataLoaded = gameInfo.allDataLoaded;
            this.readyToLoadPictures = true;
            if (this.state.gameInfo.timeWizardInfo.gameID !== undefined){
                this.saveGameYears(this.state.gameInfo.timeWizardInfo);
            }
        }
    }

    componentWillUnmount(){
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    setScreenSize(){  
        this.className = "mt-5 mx-3";
        this.textClass = "mx-4 mt-4 homepage-text decorative";
        this.imgClass = "mt-5 ml-3";
        this.moodRetirementClass = "mood_retirement_icon_container mt-2 cursor-is-pointer";
        switch (this.state.screenSize){
            case "narrow":
                this.className = "col-sm-1 " + this.className;
                this.imgClass = this.imgClass + " medium_size_img2";
                this.moodRetirementClass = this.moodRetirementClass + " mood_retirement_icon_narrow_size";
                this.retirementArrowClass = "retirement_arrow_position_narrow";
                this.moodArrowClass = "mood_arrow_position_narrow";
                this.state.initialLeft = (.504 * this.state.screenWidth) - 253;
                this.state.initialTop = (-.08 * this.state.screenHeight) + 660;
                this.waitSpinnerClass="col-sm-8 mb-5 input-page-base-card";
                break;
            case "medium":
                this.className = "col-sm-1 " + this.className;
                this.imgClass = this.imgClass + " medium_size_img2";
                this.moodRetirementClass = this.moodRetirementClass + " mood_retirement_icon_medium_size";
                this.retirementArrowClass = "retirement_arrow_position_medium";
                this.moodArrowClass = "mood_arrow_position_medium";
                this.state.initialLeft = (.389 * this.state.screenWidth) - 215.55;
                this.state.initialTop = (-.11 * this.state.screenHeight) + 639.08;
                this.waitSpinnerClass="col-sm-8 mb-5 input-page-base-card";
                break;
            default:    
                this.className = "col-sm-3 " + this.className;
                this.moodRetirementClass = this.moodRetirementClass + " mood_retirement_icon_wide_size";
                this.retirementArrowClass = "retirement_arrow_position_wide";
                this.moodArrowClass = "mood_arrow_position_wide";
                this.state.initialLeft = (.504 * this.state.screenWidth) - 320.45;
                this.state.initialTop = (-.21 * this.state.screenHeight) + 709.08;
                this.waitSpinnerClass="col-sm-7 mb-5 input-page-base-card";
                break;
        }
        //  the following is calculating initial (left) and height for badges ribbon
       
    }

    calcMoodRetirementScores(){
        let savings = 0;
        if (this.state.gameInfo.savingsInfo.totalSavings != undefined){
             savings = this.state.gameInfo.savingsInfo.totalSavings;
        }
        let rotationObj = calculateMoodRetirementScores(this.state.gameInfo.gameData.currentMoodPoints,
            this.state.gameInfo.gameData.currentCash, savings, this.state.gameInfo.gameData.spendingHistory, 
            this.state.gameInfo.gameData.gameNbrMonths, this.totalNbrGameMonths);
        this.state.moodRotation = rotationObj.moodRotation;
        this.state.retirementRotation = rotationObj.retirementRotation;
        this.state.moodEmoji = rotationObj.emojiURL;
     //   this.state.moodEmoji = "./assets/moodFaceIndifferent.png"
    }

    openCommentModal(){
        this.btnText = sessionStorage.getItem("gameBtnText");
        this.state.commentModalOpen = true;
    }

    closeCommentModal(){
        this.state.commentModalOpen = false;
        this.setState({commentModalOpen: false});
    }

    updateBadgeArr(badgesEarned){
        this.state.badgeArr = BadgesInfoDataset;
        for (let i=0; i<badgesEarned.length; i++){
            const badgeIndx = this.state.badgeArr.findIndex(elem => elem.badgeCode === 
                badgesEarned[i].badgeCode);
            if (badgeIndx != -1){
                 this.state.badgeArr[badgeIndx] = {...this.state.badgeArr[badgeIndx], 
                    awardType: badgesEarned[i].awardType,
                    awardAmount: badgesEarned[i].awardAmount};
                 this.state.badgeArr[badgeIndx].badgeEarned = true;
            }
         }
         this.state.nextBadge = 0;
         for (let i=0; i<this.state.badgeArr.length; i++){
             if (this.state.badgeArr[i].badgeEarned == false){
                  this.state.nextBadge = (i + 1);
                  break;
             }
         }
    }

    openBadgeDisplay(index){
        this.badgeName = this.state.badgeArr[index].badgeName;
        this.pictureURL = "./assets/Badges/" + this.state.badgeArr[index].pictureName;
        let awardType = "";
        this.badgeIndx = index;
        if (this.state.badgeArr[index].awardType == undefined){
             awardType = "cash";
             this.awardAmount = 100;
        }else{
             awardType = this.state.badgeArr[index].awardType;
        }
        this.awardType = awardType.charAt(0).toUpperCase() + awardType.slice(1).toLowerCase();
        let offset = 45;
        //  the left and top properties control where the badge card is displayed within
        //  the game board
        let left = (this.state.initialLeft + (index * offset) - 135).toFixed(2);
        let top = (this.state.initialTop - 440).toFixed(2);
        this.modalClass = "badgeModal"
        this.badgeTextClass = "mx-2 text-center";
        this.badgeImgClass = "spinPictureAround badgeImage";
        if (this.state.screenSize == "medium"){
            top = (this.state.initialTop - 400).toFixed(2);
        }
        if (this.state.screenSize == "narrow"){
            this.modalClass = "badgeModalNarrow";
            left += 20;
            top  -= 20;
            this.badgeTextClass = this.badgeTextClass + " badge-small-text";
            this.badgeImgClass = "spinPictureAround badgeImage-narrow";
        }
        this.leftStyle = {left: left + "px", top: top + "px"};
        this.setState({openBadge: true});
    }
     
    closeBadge(){
        this.setState({openBadge: false});
    }

    handleClick = index => {
        this.state.inputSelected(index);
    }

    configurationAvailable()
    {
        return false;
    }

    setPrevProcess(type)
    {
        if (type == "jobChange" || type == "carChange" || type == "homeChange"){
            this.checkAndValidateUnlockedFeatures();
            this.state.prevProcess = "ChangeGameChoices";
        }else{
            this.state.prevProcess = "";
        }
    }

    checkAndValidateUnlockedFeatures(){
        let activeFeatureObj = {};
        let activeFeatureArr = [];
        // this.state.gameInfo
        let cashAmt = +this.state.gameInfo.gameData.currentCash;
        if (this.state.gameInfo.savingsInfo.gameID !== undefined &&
            this.state.gameInfo.savingsInfo.currentlyActive === true){
                activeFeatureObj = {featureName: "savings", level: 1};
                activeFeatureArr.push(activeFeatureObj);
                cashAmt += +this.state.gameInfo.savingsInfo.totalSavings;
        }
        let creditCardActive = false;
        if (this.state.gameInfo.creditCardInfo.gameID !== undefined &&
            this.state.gameInfo.creditCardInfo.currentlyActive === true){
                activeFeatureObj = {featureName: "creditCard", level: this.state.gameInfo.creditCardInfo.cardLevel}
                activeFeatureArr.push(activeFeatureObj);
                creditCardActive = true;
        }
        if (this.state.gameInfo.timeWizardInfo.gameID !== undefined &&
            this.state.gameInfo.timeWizardInfo.status !== "inactive"){
                activeFeatureObj = {featureName: "timeWizard", level: this.state.gameInfo.timeWizardInfo.level}
                activeFeatureArr.push(activeFeatureObj);
        }
        let nbrMonths = 1;
        let monthlyAmounts = rollForwardCash(this.state.gameInfo.gameData.assetTable, 
            this.state.gameInfo.gameData.expenseTable, this.state.gameInfo.gameData.loanTable, nbrMonths, 
            this.state.gameInfo.gameData.currentGameDate, creditCardActive);
        this.monthlyIncome = monthlyAmounts.totalNetAmt;
        const unlockedFeatureObj = reconcileUnlockedFeatures(this.state.gameInfo.gameData.gameNbrMonths,
            cashAmt, this.monthlyIncome, this.state.gameInfo.gameData.unlockedFeatures, 
            activeFeatureArr, this.totalNbrGameMonths);
        if (unlockedFeatureObj.changed === true){
            this.unlockedFeatures = unlockedFeatureObj.unlockedFeatures;
            this.updateUnlockedFeatures();
        }
    }
       
    openMoodRetirementModal(){
        this.state.moodRetirementOpen = true;
    }

    closeMoodRetirementModal(){
        this.state.moodRetirementOpen = false;
    }

    endGame(endGameReason)
    {
        this.handleClick(4);
        this.endGameReason = endGameReason;
        if (!this.endGameReason){
            this.endGameReason = "PlayersChoice"
        }
        if (this.endGameReason === "NegativeCash" ||
            this.endGameReason === "NegativeMoodPoints" ||
            this.endGameReason === "TimeElapsed"){
            var i;
            for (i=0; i<4; i++){
                this.state.inputArr[i].completed = true;
            }
        }
        this.props.history.push('/end-game');
    }

    closeOutGame()
    {
        // Migrate current cash position to leaderboard (need to set up Node endpoint for this)
        // right now this code will never be executed
        this.props.history.push('/');
    }

    resetTotalNbrGameMonths(totalNbrGameMonths){
        this.totalNbrGameMonths = totalNbrGameMonths;
    }

    getGameInfo()
    {
        const gameInfo = reduxSetGameInfo("");
        this.setState({gameInfo: gameInfo});
        return this.state.gameInfo;
    }

    setGameInfo(value, propertyName = null, subPropertyName)
    { 
        let gameInfo = {};
        if (propertyName !== undefined){
            if (subPropertyName !== undefined){
                gameInfo = reduxSetGameInfo("SETGAMEDATA", value, subPropertyName);
            }else{
                gameInfo = reduxSetGameInfo("SETINFO", value, propertyName);
            }
            this.setState({gameInfo: gameInfo});
        }else{
            const errorObj={function: "SetGameInfo", subProcess: this.state.subProcess, 
                status: 999, message: "propertyName is undefined", errorObject: value,
                silentAlert: true};
            this.processErrorReturn(errorObj);
        }
    } 

    //  add initial fetches required for game processes....

    refreshGameInfo(){
        this.state.gameLoaded = false;
        this.state.eventConditionsLoaded = false;
        this.state.savingsLoaded = false;
        this.state.creditCardLoaded = false;
        this.state.funStuffLoaded = false;
        this.state.timeWizardLoaded = false;
        this.state.friendsLoaded = false;
        this.state.gamePurchasesLoaded = false;
        this.loadGameRelatedInfo();
        if (this.state.gameInfo.eventConditions.length === 0){
            this.loadEventConditions();
        }else{
            this.setState({eventConditionsLoaded: true});
        }
    }

    loadGameRelatedInfo() {
        this.gameData = this.state.gameInfo.gameData;
        this.processBadgeInfo();
        this.checkForUnlockedFeatures();
        this.loadFriends();
        this.loadPlayerTest();
        this.setState({gameLoaded: true});
    }
    
    processBadgeInfo(){
        if (this.gameData.badgesEarned == undefined) {
            this.gameData.badgesEarned = [];
        }
        this.updateBadgeArr(this.gameData.badgesEarned);
    }

    checkForUnlockedFeatures(){
        let funStuffLoaded = false;
        let savingsLoaded = false;
        let creditCardLoaded = false;
        let timeWizardLoaded = false;
        for (let i=0; i<this.gameData.unlockedFeatures.length; i++){
            let unlockedFeature = this.gameData.unlockedFeatures[i];
            if (unlockedFeature.featureName == "funStuff"){
                funStuffLoaded = true;
                this.loadFunStuff(this.gameData._id);
            }
            if (unlockedFeature.featureName == "savings"){
                savingsLoaded = true;
                this.loadSavings(this.gameData._id);
            }
            if (unlockedFeature.featureName == "creditCard"){
                creditCardLoaded = true;
                this.loadCreditCard(this.gameData._id);
            }
            if (unlockedFeature.featureName == "timeWizard"){
                timeWizardLoaded = true;
                this.loadTimeWizard(this.gameData._id);
            }
        }
      
        if (funStuffLoaded == false){
            this.setState({funStuffLoaded: true});
            this.buildGamePicturesObj("funStuff", "");
            this.funStuffExists = false;
        }
        if (savingsLoaded == false){
            this.setState({savingsLoaded: true});
        }
        if (creditCardLoaded == false){
            this.setState({creditCardLoaded: true});
        }
        if (timeWizardLoaded === false){
            if (this.state.gameInfo.timeWizardInfo.gameID !== undefined){
                this.saveGameYears(this.state.gameInfo.timeWizardInfo);
            }
            this.setState({timeWizardLoaded: true});
        }
        this.loadGamePurchases(this.gameData._id);
        this.loadJob();
        this.loadHome();
        this.loadCar();
    }
    
    loadEventConditions() {
        const funcName = "eventConditionsList";
        const urlParm = "?triggeredByList=monthly,event,funStuff";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.setGameInfo(output.body, 'eventConditions');
                        this.setState({eventConditionsLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            });
    }

    loadSavings(gameID) {
        const funcName = "loadSavings";
     //   let gameID = sessionStorage.getItem("gameID");
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        let savings = output.body;
                        this.setGameInfo(savings, "savingsInfo");
                        this.setState({savingsLoaded: true});
                    }else{
                        if (statusCode == 250){
                            // there is no savings for this player;
                            this.setState({savingsLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    }
                });
            });
    }
    
    loadCreditCard(gameID) {
        const funcName = "loadCreditCardInfo";
      //  let gameID = sessionStorage.getItem("gameID");
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        let creditCard = output.body;
                        this.setGameInfo(creditCard, "creditCardInfo")
                        this.setState({creditCardLoaded: true});
                    }else{
                        if (statusCode == 250){
                             // there is no credit card record for this player
                             this.setState({creditCardLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    }
                });
            });
    }
            
    loadFunStuff(gameID) {
        const funcName = "loadFunStuff";
     //   const gameID = sessionStorage.getItem("gameID");
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        let funStuff = output.body;
                        this.setGameInfo(funStuff, "funStuffInfo");
                        this.buildGamePicturesObj("funStuff", funStuff);
                        this.setState({funStuffLoaded: true});
                        this.funStuffExists = true;                        
                    }else{
                        if (statusCode == 250){
                               // there is no credit card record for this player
                               this.funStuffExists = false;
                               this.buildGamePicturesObj("funStuff", "");
                               this.setState({funStuffLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    }
                });
            });
    }
      
    loadTimeWizard(gameID) {
        const funcName = "loadTimeWizard";
     //   const gameID = sessionStorage.getItem("gameID");
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        let timeWizard = output.body;
                        this.saveGameYears(timeWizard);
                        this.setGameInfo(timeWizard, "timeWizardInfo");
                        this.setState({timeWizardLoaded: true});
                    }else{
                        if (statusCode == 250){
                               // there is no time wizard record for this player
                               this.setState({timeWizardLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    }
                });
            });
    }

    
    loadJob(){
        const funcName = "jobInfo";
        let jobID = this.state.gameInfo.gameData.jobInfo.careerID;
        const urlParm = "?jobId=" + jobID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.jobChoiceData = output.body;
                        this.buildGamePicturesObj("job", this.jobChoiceData);
                        this.setState({jobChoiceLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
    }

    loadHome(){
        const funcName = "homeInfo";
        let homeID = this.state.gameInfo.gameData.homeInfo.homeID;
        const urlParm = "?homeId=" + homeID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.homeChoiceData = output.body;
                        this.buildGamePicturesObj("home", this.homeChoiceData);
                        this.setState({homeChoiceLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
    }

    loadCar(){
        const funcName = "carInfo";
        let carID = this.state.gameInfo.gameData.carInfo.carID;
        const urlParm = "?carId=" + carID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.carChoiceData = output.body;
                        this.buildGamePicturesObj("car", this.carChoiceData);
                        this.setState({carChoiceLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
    }

    buildGamePicturesObj(dataType, dataObj){
        switch (dataType){
            case "job":
                this.pictureInfoObj = getGameChoicePictures(dataType, dataObj, 
                   this.state.gameInfo.gameData.jobInfo, this.pictureInfoObj);
                break;
            case "car":
                this.pictureInfoObj = getGameChoicePictures(dataType, dataObj, 
                    this.state.gameInfo.gameData.carInfo, this.pictureInfoObj);
                break;
            case "home":
                this.pictureInfoObj = getGameChoicePictures(dataType, dataObj,  
                    this.state.gameInfo.gameData.homeInfo, this.pictureInfoObj);
                break;
            case "funStuff":
                this.pictureInfoObj = getGameChoicePictures(dataType, 
                    dataObj, "", this.pictureInfoObj);
                this.funStuffPictureLoaded = true;
                break;
            default:
                break;
        }
        if (this.pictureInfoObj.jobID !== undefined && this.pictureInfoObj.carID !== undefined &&
            this.pictureInfoObj.homeID !== undefined && 
            this.pictureInfoObj.funStuffInfo !== undefined){
                this.setGameInfo(this.pictureInfoObj, "pictureInfo");
                this.readyToLoadPictures = true;
        }
    }
     
    saveGameYears(timeWizard){
        if (timeWizard.status !== "inactive"){
            this.setGameInfo("NbrGameYears", timeWizard.gameNbrYears);
            this.totalNbrGameMonths = (+timeWizard.gameNbrYears * 12).toFixed(0);
        }
    }
    
    loadGamePurchases(gameID) {
        const funcName = "loadGamePurchases";
      //  let gameID = sessionStorage.getItem("gameID");
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        let gamePurchases = output.body;
                        this.setGameInfo(gamePurchases, "gamePurchases");
                        this.setState({gamePurchasesLoaded: true});
                    }else{
                        if (statusCode == 250){
                             // there is no credit card record for this player
                             this.setState({gamePurchasesLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    }
                });
            });
    }

    addFunStuffToPictureInfo() {
        //   this just makes sure that the funstuff picture info has been updated (if not done in game choice pictures)
        if (this.state.gameInfo.pictureInfo.funStuffInfo.funStuffExists == undefined ||
            this.state.gameInfo.pictureInfo.funStuffInfo.funStuffExists == false) {
            const pictureInfoObj = resetPicturesForChanges("funStuff", this.state.gameInfo.pictureInfo, 
                "", "", "", this.state.gameInfo.funStuffInfo );
            this.setGameInfo(pictureInfoObj, 'pictureInfo');
        }
    }

    loadFriends() {
        const funcName = "loadFriends";
        let friendArr = [];
        const friendList = this.gameData.friendList;
        for (let i = 0; i < friendList.length; i++) {
            let friendName = friendList[i].name;
            friendArr.push(friendName);
        }
        const friendParm = friendArr.join(',');
        const urlParm = "?friendArr=" + friendParm;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => {
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.buildFriendInfoArr(output.body);
                        this.setState({friendsLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message}
                        this.processErrorReturn(errorObj);
                    }
                });
            });
    }
    
    buildFriendInfoArr(friendData) {
        let friendArr = [];
        let startIdx = friendData.length - 1;
        for (let i = startIdx; i >= 0; i--) {
            let friendImgURL = './assets/friends/' + friendData[i].friendImg
            let friendObj = {
                friendName: friendData[i].friendName, friendImgURL: friendImgURL,
                weightedOdds: friendData[i].weightedOdds
            };
            friendArr.push(friendObj);
        }
        this.setGameInfo(friendArr, "friendInfo");
    }
    
  loadPlayerTest(){
    const funcName = "loadMoneyMgmtTest";
    const username = sessionStorage.getItem("username");
    let playerArr = [];
    playerArr.push(username);
    const urlParm = "?userArr=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                       this.setGameInfo(true, "moneyMgmtTest");
                       this.setState({loadMoneyMgmtTest: true});
                    }else{
                        if (statusCode === 250){
                            this.setState({loadMoneyMgmtTest: true});           
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                    status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
            };
        });
  }

  updateUnlockedFeatures(){
        this.state.gameInfo.gameData.unlockedFeatures = this.unlockedFeatures;
        this.setGameInfo(this.state.gameInfo.gameData, 'gameData');
        this.bodyObj = {
            "currentCash": this.state.gameInfo.gameData.currentCash,
            "unlockedFeatures": this.state.gameInfo.gameData.unlockedFeatures,
            "iconTable": this.state.gameInfo.gameData.iconTable
        };
        const funcName = "updateUnlockedFeatures";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
            {method:'PUT', headers: this.state.headers,
            body: JSON.stringify(this.bodyObj)})
            .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                   //  update of gameData successful
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.processErrorReturn(errorObj);
                }
            });
            }
        });
    }
    
    checkDataAccuracy(dataObj){
        const dataCompareObj = beforeAfterCompare(dataObj.dataType, dataObj.beforeObj, dataObj.afterObj);
        if (dataCompareObj.dataValid === false){
            let cardTitle = "";
            if (dataObj.cardTitle !== undefined){
                cardTitle = dataObj.cardTitle;
            }
            const errorDataObject = {processName: dataObj.processName, paragraphName: dataObj.paragraphName, 
                dataType: dataObj.dataType, cardTitle: cardTitle, beforeObject: dataObj.beforeObj, 
                afterObject: dataObj.afterObj, dataSubTypeArr: dataCompareObj.dataSubTypeArr};
            const errorMessage = "Discrepancy found in before/after compare of redux vs. mongo.";
            this.generalErrorLog(errorMessage, errorDataObject);
        }
    }

    generalErrorLog(errorDesc, dataObject) {
        if (dataObject === undefined){
            dataObject = {currentCard: this.gameInfo.currentCard,
                currentCash: this.gameInfo.gameData.currentCash,
                assetTable: this.gameInfo.gameData.assetTable,
                expenseTable: this.gameInfo.gameData.expenseTable,
                loanTable: this.gameInfo.gameData.loanTable,
                conditionsTable: this.gameInfo.gameData.conditionsTable};
        }
        const message = errorDesc;
        const errorObj={function: "GeneralError", subProcess: this.state.subProcess, 
            status: 999, message: message, errorObject: dataObject, silentAlert: true};
        this.processErrorReturn(errorObj);
    }

    processErrorReturn(errorObj){
        this.addRecordToLogfile(errorObj);
        if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
            alert ("Uh oh!  Something unexpected has occurred " +
                "error has been logged and will be addressed. " +
                "For now click OK, then return to the process " +
                "where you were and try again.");
            this.props.history.push('/');
        }
    }

    addRecordToLogfile(errorObj){
        const headers=setHeadersForFetch();
        if (errorObj.status == 250){
            errorObj.message = "No record found";
        }
        let subProcess = "";  
        if (errorObj.subProcess == undefined){
            subProcess = "unknown"
        }else{
            subProcess = errorObj.subProcess;
        }
        if (errorObj.errorObject == undefined){
            errorObj.errorObject = {};
        }
        const logFileBody =  {
            mainProcess: "GameView",
            subProcess: subProcess,
            function: errorObj.function,
            status: errorObj.status,
            message: errorObj.message,
            errorObj: errorObj.errorObject
        }
        console.log("logFileBody is: ", logFileBody)
        const urlParm = "";
        const uri = buildUrl(this.state.environment, "saveLogFile", urlParm);
        fetch (uri,  
            {method:'POST',
                headers: headers,
                body: JSON.stringify(logFileBody)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            console.log("logfile create was successful");
                        }else{
                            console.log("Response from save log file not valid!" +
                            " status is: ", output.statusCode,
                            " message is: ", output.body);
                           alert ("Response from save log file not valid!");
                               
                        }
                });
                
            }
        });
      
    }
    
}

export default withRouter(GameView);