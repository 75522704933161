const lifeStyleAssessmentDataset =[
  {title: "Spending Habits", color: "blue",
    description:  "How many times do you plan to go out to eat or order food to be " +
          "delivered?", 
          lastCard: true,
          cardType: "card",
          backgroundType: "home",
          eventCategory: "endTurn",
          lastCardButtons: [{type: "LifeStyle", btnText: "1 time each month", activity: 1},
                            {type: "LifeStyle", btnText: "2 times each month", activity: 2},
                            {type: "LifeStyle", btnText: "1 time each week", activity: 3},
                            {type: "LifeStyle", btnText: "Nearly Every Day", activity: 4}]
  },

  {title: "Spending Habits", color: "blue",
   description:  "You want to buy some new clothes.  You plan to buy a couple " +
        "of new outfits each month.  Where do you plan to shop? ", 
        lastCard: true,
        cardType: "card",
        backgroundType: "home",
        eventCategory: "endTurn",
        lastCardButtons: [], buttonCategory: "clothesShopping"
  },
  
  {title: "Spending Habits", color: "blue",
   description:  "If you decide to cook some of your meals at home, what kind of groceries " +  
        "do you want to buy?", 
        lastCard: true,
        cardType: "card",
        backgroundType: "home",
        eventCategory: "endTurn",
        lastCardButtons: [{type: "LifeStyle", btnText: "Organic/Gourmet", activity: 4},
                  {type: "LifeStyle", btnText: "Name Brand Only", activity: 3},
                  {type: "LifeStyle", btnText: "Regular Groceries", activity: 2},
                  {type: "LifeStyle", btnText: "Only Buy With Coupons", activity: 1}]
  },
  
  {title: "Spending Habits", color: "blue",
   description:  "If you decide to go out for dinner.  What restaurant are you most likely " +  
        "to choose?", 
        lastCard: true,
        cardType: "card",
        backgroundType: "home",
        eventCategory: "endTurn",
        lastCardButtons: [], buttonCategory: "diningOut"
  },

];

export default lifeStyleAssessmentDataset;