import { calcInterestAmt } from "../../../objects/CommonUse/calc_interest_amt";
import { rollForwardDate } from "./roll_forward_date_function";
import { calcBiasedRandomNbr } from "../../../objects/CommonUse/randomBias";

export const moveFromCashToSavings = (savingsInfo, currentCash, gameDate, status, nbrMonths) => {
   
    if (nbrMonths == undefined){
        nbrMonths = 1;
    }
  
    let newCashAmt = +currentCash;
    if (status == "active"){
        newCashAmt = +currentCash - (+savingsInfo.monthlySavingsAmt * nbrMonths);
    }else{
        const amt = 0;
        savingsInfo.monthlySavingsAmt = amt.toFixed(2);
    }
  
    let intEndDate = "";
    const intRateStartDate = savingsInfo.intRateStartDate;
    if (intRateStartDate !== ""){
        intEndDate = rollForwardDate(intRateStartDate, 12);
    }
   
    for (let i=0; i<nbrMonths; i++){
        // interest rate is changed once each year
        gameDate = rollForwardDate(gameDate, 1);
        if (gameDate > intEndDate || savingsInfo.intRateStartDate === ""){
            //  calc a random number from 1 to 12 that is biased toward 7%
            const intRate = (calcBiasedRandomNbr(1, 12, 7, .75))/100;  
            savingsInfo.currentIntRate = intRate.toString();
            savingsInfo.intRateStartDate = gameDate;
            intEndDate = rollForwardDate(savingsInfo.intRateStartDate, 12);
        }
        let intAmt = calcInterestAmt(+savingsInfo.totalSavings, savingsInfo.currentIntRate, "month");
        savingsInfo.totalIntAmt = (+savingsInfo.totalIntAmt + +intAmt).toFixed(4);
        savingsInfo.totalDeposits = (+savingsInfo.totalDeposits + +savingsInfo.monthlySavingsAmt).toFixed(2);
        savingsInfo.totalSavings = (+savingsInfo.totalIntAmt + +savingsInfo.totalDeposits - 
            +savingsInfo.totalWithdrawals).toFixed(2);
    }
         
    let savingsInfoObj = {savingsInfo: savingsInfo, cashAmt: newCashAmt};
    
    return savingsInfoObj;
   
}





