const initialState = {
    turnCount: 2,
    allDataLoaded: false,
    gameExists: null,
    extraCreditExists: null,
    gameData: {},
    currentCard: {},
    currentCardLoaded: false,
    eventConditions: [],
    pictureInfo: "",
    backgroundURL: "",
    funStuffInfo: "",
    savingsInfo: "",
    creditCardInfo: {},
    timeWizardInfo: {},
    gamePurchases: "",
    friendInfo: [],
    activityTracker: {},
    gamePhase: "",  
    cardInProcess: false,
    cardImageObj: {},
    cardList: [],
    extraCredit: {},
    playerRole: "",
    totalGameYears: 0,
    friendCardInfo: {},
    moneyMgmtTest: false,
    prevNextMonth: {}
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INCREMENT':
            return {
                ...state,
                turnCount: state.turnCount + 1
            };
        case "SETGAMEDATA":
            const newGameData = state.gameData;
            newGameData[action.dataName] = action.payload;
            return {
                ...state,
                newGameData
            }
        case 'SETINFO':
            return {
                ...state,
                [action.dataName]: action.payload
            };           
        case "INITIALIZE":
            return {
               ...initialState,
               cardList: [],
            }
        default:
            return state;
    };
};

