import LinkTab from '../components/shared-components/link_tab';
import "../css/styles.css";
import { withRouter } from "react-router";
import {Tooltip} from "@mui/material";
import ForParentsMain from '../components/for-parents/for_parents_main';
import ParentsGameInfo    from '../components/for-parents/parents_game_info';
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import React from 'react';
import {Link} from "react-router-dom";
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import RelatedPlayersDisplay from '../components/for-parents/related_players_display';
import CommentModal from '../components/shared-components/comment_modal';
import PlayerReports from '../components/for-parents/player_reports';
import WaitTimeSpinner from '../components/shared-components/wait_time_spinner';
import DiscussionTopics from '../components/for-parents/discussion_topics';
import {buildUrl } from '../objects/CommonUse/build_URL';
import Config from '../config';
import tooltipCommentIconText from './Text/tooltip_text';

class ForParents extends React.Component {
    constructor(props) {
        super(props);
          
    this.inputCompleted = (inputID) => {
        this.state.inputArr[inputID].completed = true;
        this.state.inputArr[inputID].selected = false;
        this.state.inputArr[0].selected = true;
        if (this._isMounted === true){
            this.setState({inputArr: this.state.inputArr}); 
        }
    };

    this.inputSelected = (inputID) => {
        for (const input of this.state.inputArr) {
            input.selected = false;
        }
        this.state.inputArr[inputID].selected = true;
        if (this._isMounted === true){
            this.setState({inputArr: this.state.inputArr}); 
        }
    };
    this.openCommentModal = this.openCommentModal.bind(this);
    this.closeCommentModal = this.closeCommentModal.bind(this);
       
     this.state = {
        inputArr: [
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false,
            },
            {
                completed: false,
                selected: false,
            },
           
        ],
        inputCompleted: this.inputCompleted,
        inputSelected: this.inputSelected,
        resetParentInfo: true,  
        headers: setHeadersForFetch(),
        commentIcon: "./assets/ContactUs/pencilPaper.png",
        commentModalOpen: false,
        fromProcessName: "ForParents",
        playerSelectionComplete: false,
        configurationAvailable: null,
        relatedPlayerArr: [],
        doneLoadingConfigs: false,
        userRole: sessionStorage.getItem("userRole"),
        screenSize: sessionStorage.getItem("GameScreenSize"),
        environment: Config.dataURL.currentEnv,
        subProcess: "forParents",
        parentInfo: {
            loading: true,
            gameData: {},
            savingsInfo: "",
            creditCardInfo: "",
            savingsLoaded: false,
            relatedPlayers: [],
            configsLoaded: false,
            accountInfo: "",
            accountLoaded: false,
            userListLoaded: false,
            nbrPrimarySignons: 0,
            accountIndividual: false,
        }
    
    };
    this.componentStartup();       
    switch(props.selected) {
        case "forParentsMain":
            this.inputSelected(0);
            break;
        case "parentsparentInfo":
            this.inputSelected(1);
            break;
        case "relatedPlayers":
            this.inputSelected(2);
            break;
        case "playerReports":
            this.inputSelected(3);
            break;
        case "discussionTopics":
            this.inputSelected(4);
            break;
      
    }

}

componentStartup(){
    if (this.state.userRole == "parent" || this.state.userRole == "teacher"){
        //  all options are valid for this user    
    }else{
        this.state.inputArr[3].completed = true;
        this.state.inputArr[4].completed = true;
    }
    this.accountNbr = sessionStorage.getItem("userAccountNbr");
    if (this.accountNbr == "Individual"){
       this.state.accountIndividual = true;
    }else{
        this.getPlayerAccount();
        this.getUserList();
    }
}
           
    render(){
        this.parentInfoDisplay=this.getParentInfo();
        if (this.props.selected != "gameStatus" && this.parentInfoDisplay.loading == true){
                this.state.resetParentInfo = true; 
        }
        if (this.state.screenSize == "wide"){
            this.commentIconClass = "comment-icon-position comment-img";
        }else{
            this.commentIconClass = "comment-icon-position-medium comment-img"; 
        }
                         
    return <>
        <span className="container m-0 p-0">
 
            {!this.parentInfoDisplay.userListLoaded  && !this.state.accountIndividual ?
                <WaitTimeSpinner />
            :
            <span className="row w-100 mx-0">
                    {this.state.screenSize == "wide" ?
                            <div className="col-sm-2 mt-4 ml-3">
                                <div>
                                    <img  className="train-track-img" src={'./assets/ExtraCredit.png'} 
                                            alt="MoneyTrain Picture"/>
                                </div>   
                            </div>
                    :
                            null
                    }

                        <div>
                            <Tooltip title={tooltipCommentIconText} arrow >
                                <img src={this.state.commentIcon} 
                                    className={this.commentIconClass} alt="comment"
                                    onClick={this.openCommentModal}/>
                            </Tooltip>
                        </div>

                        {this.state.commentModalOpen ?
                            <CommentModal modalOpen={this.state.commentModalOpen} 
                                onClose={() => this.closeCommentModal()}
                                gamePart={this.state.fromProcessName}
                                processName={this.props.selected}
                                gameInfo = {() => this.getParentInfo()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                        :
                            null
                        }

                        {this.props.selected === "forParentsMain"  ?
                            <ForParentsMain 
                                screenSize={this.state.screenSize}
                                goBack = {() => this.goToHomePage()}
                                onOpen ={()=> this.inputSelected(0)} /> 
                        : 
                            null
                        }
                       
                        {this.props.selected === "parentsGameInfo" ? 
                            <ParentsGameInfo
                                screenSize={this.state.screenSize} 
                                goBack = {() => this.goToHomePage()}
                                onOpen ={()=> this.inputSelected(1)}  /> 
                        : 
                            null
                        }

                        {this.props.selected === "relatedPlayers"  ?
                             <RelatedPlayersDisplay
                                screenSize={this.state.screenSize}
                                goBack = {() => this.goToHomePage()}
                                onOpen ={()=> this.inputSelected(3)}
                                parentInfo = {() => this.getParentInfo()} 
                                setParentInfo = {(value, propertyName) => this.setParentInfo(value, propertyName)}
                                checkForConfigs = {() => this.checkForConfigs()}
                                relatedPlayerArr={this.state.relatedPlayerArr}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            /> 
                        : 
                            null
                        }

                        {this.props.selected === "playerReports" ?
                            <PlayerReports
                                screenSize={this.state.screenSize}
                                goBack = {() => this.goToHomePage()}
                                onOpen ={()=> this.inputSelected(3)}
                                parentInfo = {() => this.getParentInfo()} 
                                setParentInfo = {(value, propertyName) => this.setParentInfo(value, propertyName)}
                                selected={this.props.selected}
                                configAvailable={this.state.configurationAvailable}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                            
                        :
                            null
                        }
                        
                        {this.props.selected === "discussionTopics"  ?
                            <DiscussionTopics
                                screenSize={this.state.screenSize}
                                playerRole={this.state.userRole}
                                goBack = {() => this.goToHomePage()}
                                onOpen ={()=> this.inputSelected(4)}
                                onInput={(index) => this.handleClick(index)}
                            />
                        :
                            null
                        }
                        
                                                                                           
                    <div className="col-sm-2 ml-5"> 
                        <Link to="for-parents-main" onClick={() => this.handleClick(0)}>
                            <LinkTab inputArr={this.state.inputArr[0]} name="Main Page" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>
                        <Link to="parents-game-info" onClick={() => this.handleClick(1)}>
                            <LinkTab inputArr={this.state.inputArr[1]} name="Game Information" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>
                        <Link to="discussion-topics" onClick={() => this.handleClick(2)}>
                            <LinkTab inputArr={this.state.inputArr[2]} 
                                name="Video Presentations" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>
                       
                        {!this.state.inputArr[3].completed ?
                            <Link to="player-reports" onClick={() => this.handleClick(3)}>
                                <LinkTab inputArr={this.state.inputArr[3]} 
                                    name="Player Reports" validToComplete="true" 
                                    screenSize={this.state.screenSize}/>
                            </Link>
                        :
                            <LinkTab inputArr={this.state.inputArr[3]} name="Player Reports" 
                                screenSize={this.state.screenSize}/>
                        }

                        {!this.state.inputArr[4].completed ?
                            <Link to="related-players" onClick={() => this.handleClick(4)}>
                                <LinkTab inputArr={this.state.inputArr[4]} name="Review Related Players" validToComplete="true" 
                                    screenSize={this.state.screenSize}/>
                            </Link>
                        :
                            <LinkTab inputArr={this.state.inputArr[4]} name="Review Related Players" 
                                screenSize={this.state.screenSize}/>
                        }
                        
                      
                    </div>
                 </span>  
            }
        </span>
    </>
}

componentDidMount(){
    this._isMounted = true;
}

getPlayerAccount(){
    const funcName = "loadAccount";
    const urlParm = "?accountNbr=" + this.accountNbr + "&test=test";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.setParentInfo(output.body, "accountInfo");
                        this.setParentInfo(true, "accountLoaded");
                    }else{  
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
}


processSelectedPlayer(playerName){
    this.state.selectPlayer = false;
    if (playerName != undefined){
        this.state.playerName = playerName;
        this.loadGame(playerName);
        this.state.playerSelectionComplete = true;
    }
}

resetParentInfo(){
     this.state.playerSelectionComplete = false;
}

getUserList(){
    let accountNbr = sessionStorage.getItem("userAccountNbr");
    const funcName = "userCollectionList";
    const urlParm = "?accountNbr=" + accountNbr + "&test=test";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.processUserList(output.body);
                        this.setParentInfo(true, "userListLoaded");    
                    }else{  
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
}

processUserList(data) {
    let userName = sessionStorage.getItem("username");
    let userArr = data;
    for (let i=0; i<userArr.length; i++){
        if (userArr[i].username == userName && 
            (userArr[i].role == "teacher" || userArr[i].role == "parent")){
                this.state.playerRoleOK = true;
                break;
        }
    }
    this.buildPlayerArr(userArr);
}
   

buildPlayerArr(data){
    let playerArr = [];
    let nbrPrimary = 0;
    if (this.state.playerRoleOK == true){
        let userArr = data;
        for (let i=0; i<userArr.length; i++){
            if (userArr[i].role == "player"){
                   playerArr.push(userArr[i].username);
            }else{
                 nbrPrimary += 1;
            }
        }
     }
     this.setParentInfo(playerArr, "relatedPlayers");
     this.setParentInfo(nbrPrimary, "nbrPrimarySignons");
}

  
loadGame()
{
    const funcName = "loadGame";
    const urlParm = "?mode=fullLoad";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.setParentInfo(output.body, 'gameData');
                        this.setParentInfo(false, 'loading');
                    }else{  
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
}
 
openCommentModal(){
    this.state.commentModalOpen = true;
}

closeCommentModal(){
    this.state.commentModalOpen = false;
    this.setState({commentModalOpen: false});
}

handleClick = index => {
    this.state.inputSelected(index);
}

getParentInfo()
{
    return this.state.parentInfo;
}

setParentInfo(value, propertyName = null)
{
    if (propertyName)
    {
        this.state.parentInfo[propertyName] = value;
        this.setState({parentInfo: this.state.parentInfo});
    }
    else this.setState({parentInfo: value});
              
}

goToHomePage(){
    this.props.history.push('/');
}

processErrorReturn(errorObj){
    this.addRecordToLogfile(errorObj);
    if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
        alert ("Uh oh!  Something unexpected has occurred " +
            "error has been logged and will be addressed. " +
            "For now click OK, then return to the process " +
            "where you were and try again.");
        this.props.history.push('/');
    }
}

addRecordToLogfile(errorObj){
    const headers=setHeadersForFetch();
    if (errorObj.status == 250){
        errorObj.message = "No record found";
    }
    let subProcess = "";  
    if (errorObj.subProcess == undefined){
        subProcess = "unknown"
    }else{
        subProcess = errorObj.subProcess;
    }
    if (errorObj.errorObject == undefined){
        errorObj.errorObject = {};
    }
    const logFileBody =  {
        mainProcess: "ForParents",
        subProcess: subProcess,
        function: errorObj.function,
        status: errorObj.status,
        message: errorObj.message,
        errorObj: errorObj.errorObject
    }
    console.log("logFileBody is: ", logFileBody)
    const urlParm = "";
    const uri = buildUrl(this.state.environment, "saveLogFile", urlParm);
    fetch (uri,  
        {method:'POST',
            headers: headers,
            body: JSON.stringify(logFileBody)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        console.log("logfile create was successful");
                    }else{
                        console.log("Response from save log file not valid!" +
                        " status is: ", output.statusCode,
                        " message is: ", output.body);
                        alert ("Response from save log file not valid!");
                           
                    }
            });
        }
    });
  
}

}

export default withRouter(ForParents);