import store from "../../redux/store";

export const reduxIncrementTurnCount = (actionType) => {

      if (actionType !== undefined && actionType !== ""){
        const setGameInfo = () => {
          return {
            type: actionType
            }
        }
        store.dispatch(setGameInfo());
      }
      const turnCount = store.getState().turnCount;
      return turnCount;
      
}
             

export default reduxIncrementTurnCount;