
const FunctionListDataset =  [
  // test cases for 'GET' functions follow.  Test case 1-->success, remainder-->variable parms
  {funcName: 'skillsQuestions', testCases: [{testNbr: "1", urlParm: ""}], method: "GET"},
  {funcName: 'jobsList', 
      testCases: [{testNbr: "1", urlParm: "?skills=['3','5','5','5','5','5','5','3','2','3','4','2','5']"}], 
      method: "GET"},
  {funcName: 'jobListAll', testCases: [{testNbr: "1", urlParm: ""}], method: "GET"},
  //  calcObject = {nbrYears: this.yearsDiff, downPayMinPct: this.downPayMinPct,
  //  interest: this.interestRate, loanTerm: this.loanTerm, savingsAmt: +this.props.savings, 
  //  monthlyAmt: +this.props.monthlyIncome, currentHomeID: this.props.currentHomeID}
  // "?beds=2&roommates=1&homeType=apartment&savingsAmt=0&monthlyAmt=2000";
  {funcName: 'homeList', testCases: [
    {testNbr: "1", urlParm: "?beds=1&roommates=0&homeType=apartment&" +
        "&savingsAmt=500&monthlyAmt=2000"},
    {testNbr: "2", urlParm: "?beds=1&roommates=0&homeType=house&nbrYears=2&downPayMinPct=5" +
        "&interest=5&loanTerm=360&savingsAmt=600000&monthlyAmt=2000&currentHomeID=61323ec697d929c9a8cf8706"},
    ], 
    method: "GET"},
  {funcName: 'furnitureList', testCases: [{testNbr: "1", urlParm: ""}], method: "GET"},
  {funcName: 'carList', testCases: [{testNbr: "1", urlParm: "?sizeNbr=100&currentGameYear=2024"}], method: "GET"},
  {funcName: 'loginChk', testCases: [{testNbr: "1", urlParm: ""},
    {testNbr: "2", urlParm: "?rqstType=UserOnly"}, 
    {testNbr: "3", urlParm: "?rqstType=UserEmail"}],
     method: "GET"},
  {funcName: 'leaderBoardList', testCases: [{testNbr: "1", urlParm: "?nbrEntries=10&accountNbr=game"},
        {testNbr: "2", urlParm: "?nbrEntries=10&accountNbr=13571359"},
        {testNbr: "3", urlParm: "?nbrEntries=10&accountNbr=11111359"}],
        method: "GET"},
  {funcName: 'carInfo', testCases: [{testNbr: "1", urlParm: "?carId=6132c54897d929c9a8cf8729"},
        {testNbr: "2", urlParm: "?carId=7132c54897d929c9a8cf8729"} ], 
        method: "GET"},
  {funcName: 'homeInfo', testCases: [{testNbr: "1", urlParm: "?homeId=6132427d97d929c9a8cf8710"},
        {testNbr: "2", urlParm: "?homeId=7132427d97d929c9a8cf8710"} ], 
        method: "GET"},
  {funcName: 'jobInfo', testCases: [{testNbr: "1", urlParm: "?jobId=61311b4b2afc7f761668ac1b"},
        {testNbr: "2", urlParm: "?jobId=71311b4b2afc7f761668ac1b"}], 
        method: "GET"},
  {funcName: 'eventsCollectionList', testCases: [{testNbr: "1", urlParm: ""}], method: "GET"},
  {funcName: 'eventConditionsList', testCases: [{testNbr: "1", urlParm: ""}], method: "GET"},
  {funcName: 'loadEventsTracker', testCases: [{testNbr: "1", urlParm: ""}], method: "GET"},
  {funcName: 'activityList', 
      testCases: [{testNbr: "1", urlParm: "?activityCategory=HaveFun&season=all&sampleSize=5"},
      {testNbr: "2", urlParm: "?activityCategory=XXXXX&season=all&sampleSize=5"}], method: "GET"},
  {funcName: 'archivedGameData', testCases:
     [{testNbr: "1", urlParm: ""},
     {testNbr: "2", urlParm: "?totalGameMonths=360&rqstType=CountOfGames"}],
       method: "GET"},
  {funcName: 'loadSavings', testCases: [
      {testNbr: "1", urlParm: "?gameID=64c2d8588e74d60f3a730050"},
      {testNbr: "2", urlParm: "?returnType=Multiple&gameID=64c2d8588e74d60f3a730050,63a5e0e9e93ce29244cc4754"},
      {testNbr: "3", urlParm: "?returnType=Single&gameID=64c2d8588e74d60f3a730050,63a5e0e9e93ce29244cc4754"},
      {testNbr: "4", urlParm: "?gameID='74c2d8588e74d60f3a730050'"}], 
      method: "GET"},
  {funcName: 'loadCreditCardInfo', testCases: [{testNbr: "1", urlParm: "?gameID=64c2d8588e74d60f3a730050"},
      {testNbr: "2", urlParm: "?gameID=64c2d8588e74d60f3a730050,6376c251bbc46ced17497b05&returnType=Multiple"}, 
      {testNbr: "3", urlParm: "?gameID=64c2d8588e74d60f3a730050,6376c251bbc46ced17497b05&returnType=Single"}, 
      {testNbr: "4", urlParm: "?gameID=[74c2d8588e74d60f3a730050]"}], 
      method: "GET"},
  {funcName: 'loadFunStuff', testCases: [
      {testNbr: "1", urlParm: "?gameID=6536e5436f0d71df6295b495"},
      {testNbr: "2", urlParm: "?gameID=74c2d8588e74d60f3a730050"}], 
      method: "GET"},
  {funcName: 'loadFriends', testCases: [{testNbr: "1", urlParm: "?friendArr=Jennifer,Cameron,Cindy,Charlie"},
      {testNbr: "2", urlParm: "?friendArr="}], 
      method: "GET"},   
  {funcName: 'loadGameActivityTracker', testCases: [
      {testNbr: "1", urlParm: "?gameID=64c2d8588e74d60f3a730050&category=Volunteer"},
      {testNbr: "2", urlParm: "?gameID=74c2d8588e74d60f3a730050&category=Volunteer"}], 
      method: "GET"}, 
  {funcName: 'loadAllGameActivityTracker', testCases: [{testNbr: "1", urlParm: "?gameID=64c2d8588e74d60f3a730050"},
      {testNbr: "2", urlParm: "?gameID=74c2d8588e74d60f3a730050"}], 
      method: "GET"},   
  {funcName: 'loadGame', testCases: [{testNbr: "1", urlParm: ""},
      {testNbr: "2", urlParm: "?mode=fullLoad"},
      {testNbr: "3", urlParm: "?mode=validateAvailable"}], method: "GET"},
  {funcName: 'loadExtraCreditInfo', testCases: [{testNbr: "1", urlParm: ""}], method: "GET"},
  {funcName: 'checkGameConfigs', testCases: [
      {testNbr: "1", urlParm: "?userNameArr=student4,student1,student2,student7"},
      {testNbr: "2", urlParm: "?userNameArr=studentX"}],     
      method: "GET"},
  {funcName: 'userCollectionList', testCases: [
      {testNbr: "1", urlParm: "?accountNbr=13571359"},
      {testNbr: "2", urlParm: "?accountNbr=11111135"}], 
      method: "GET"},
  {funcName: 'loadAccount', testCases: [
      {testNbr: "1", urlParm: "?accountNbr=12345678&rqstType=Validate"},
      {testNbr: "2", urlParm: "?accountNbr=13571359"},
      {testNbr: "3", urlParm: "?accountNbr=11111135"}],
      method: "GET"}, 
   {funcName: 'loadUserVerify', testCases: [{testNbr: "1", urlParm: ""}], method: "GET"},
   {funcName: 'loadAccountList', testCases: [{testNbr: "1", urlParm: ""}], method: "GET"},
   {funcName: 'loadEvents', testCases: [
      {testNbr: "1", urlParm: "?gamePhase=1&eventCategory=job&jobName=accountant"},
      {testNbr: "2", urlParm: "?gamePhase=1&eventCategory=home"},
      {testNbr: "3", urlParm: "?gamePhase=4&eventCategory=XXX"}], method: "GET"},
    {funcName: 'loadMoneyMgmtTest', testCases: [
      {testNbr: "1", urlParm: ""},
      {testNbr: "2", urlParm: "?user=studentX"}], method: "GET"},
    {funcName: 'loadGameFeedback', testCases: [
      {testNbr: "1", urlParm: "?userList=Sandburg1,Sandburg2,Sandburg8,Sandburg6"},
      {testNbr: "2", urlParm: ""},
      {testNbr: "3", urlParm: "?userList=studentX"}],     
      method: "GET"},
    {funcName: 'loadPlayerFeedback', testCases: [
      {testNbr: "1", urlParm: "?userList=Sandburg2,Sandburg8,Sandburg9,Sandburg10"},
      {testNbr: "2", urlParm: ""},
      {testNbr: "3", urlParm: "?userList=studentX"}],     
      method: "GET"},  
    {funcName: 'loadGamesForAccount', testCases: [
        {testNbr: "1", urlParm: "?userNameArr=Sandburg2,Sandburg8,Sandburg9,Sandburg10"},
        {testNbr: "2", urlParm: ""},
        {testNbr: "3", urlParm: "?userNameArr=studentX"}],     
        method: "GET"},  
    {funcName: 'loadGameChoices', testCases: [
        {testNbr: "1", urlParm: "?jobID=71311b4b2afc7f761668ac1b&carID=7132c54897d929c9a8cf8729&homeID=6132427d97d929c9a8cf8710"},
        {testNbr: "2", urlParm: ""},
        {testNbr: "3", urlParm: "?jobID=???&carID=???&homeID=???"}],     
        method: "GET"},  
    {funcName: 'loadGameChoicesMultiple', testCases: [
        {testNbr: "1", urlParm: "?jobID=71311b4b2afc7f761668ac1b,612bc849600d610c45b6e569&carID=7132c54897d929c9a8cf8729,6132c0cb97d929c9a8cf871f&homeID=6132427d97d929c9a8cf8710,61323ec697d929c9a8cf8706"},
        {testNbr: "2", urlParm: ""},
        {testNbr: "3", urlParm: "?jobID=81311b4b2afc7f761668ac1b,612bc849600d610c45b6e569&carID=9132c54897d929c9a8cf8729,8132c0cb97d929c9a8cf871f&homeID=7132427d97d929c9a8cf8710,61323ec697d929c9a8cf8706"}],     
        method: "GET"},  
    {funcName: 'loadUserListById', testCases: [
        {testNbr: "1", urlParm: "?userNameArr=Sandburg2,Sandburg8,Sandburg9,Sandburg10"},
        {testNbr: "2", urlParm: "?userNameArr=studentX"}],     
        method: "GET"},
    {funcName: 'dealNewHand', testCases: [
        {testNbr: "1", urlParm: "?conditionsTable=home-type-apartment,own-a-dog&currentGameMonth=10&career=electrical-engineer"},
        {testNbr: "2", urlParm: ""}],    
        method: "GET"},  
    {funcName: 'getNextSpinnerCard', testCases: [
        {testNbr: "1", urlParm: "?conditionsTable=home-type-apartment,own-a-dog&" +
        "currentGameMonth=10&career=electrical-engineer&eventCategory=job&cardList=1,4,5,6,2"},
        {testNbr: "2", urlParm: "?conditionsTable=home-type-apartment,own-a-dog&" +
        "currentGameMonth=10&career=&eventCategory=home&cardList=1,4,5,6,2"},
        {testNbr: "3", urlParm: "?conditionsTable=home-type-apartment,own-a-dog&" +
        "currentGameMonth=10&career=&eventCategory=job&cardList=1,4,5,6,2"},
        {testNbr: "4", urlParm: ""}], 
        method: "GET"},  
    {funcName: 'getNextSpinnerCardList', testCases: [
        {testNbr: "1", urlParm: "?conditionsTable=home-type-apartment,own-a-dog&" +
        "currentGameMonth=10&career=electrical-engineer&eventCategoryList=job,other,home,car&cardList=1,4,5,6,2"},
        {testNbr: "2", urlParm: "?conditionsTable=home-type-apartment,own-a-dog&" +
        "currentGameMonth=10&career=&eventCategoryList=home&cardList=1,4,5,6,2"},
        {testNbr: "3", urlParm: "?conditionsTable=home-type-apartment,own-a-dog&" +
        "currentGameMonth=10&career=&eventCategoryList=job&cardList=1,4,5,6,2"},
        {testNbr: "4", urlParm: ""}], 
        method: "GET"}, 
    {fundName: "loadGamePurchases", testCases: [
      {testNbr: "1", urlParm: "?gameID=77777777"},
      {testNbr: "2", urlParm: "?returnType=Multiple&gameID=77777777,88888888"},
      {testNbr: "3", urlParm: "?returnType=Single&gameID=77777777"},
      {testNbr: "4", urlParm: "?gameID='XXXXXXXX'"}], 
      method: "GET"},
  
    
    // tests for post functions follow - test case 1-->success, 2-->duplicate, 3-->missing parm    
    {funcName: 'saveTest', testCases: [
        {testNbr: "1", urlParm: "", body: {rcdNbr: '8', string: 'testing!!!'}, missingParm: false}],
        method: "POST"
    },
    {funcName: 'saveGameSelections', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newGameObj", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newGameObj", missingParm: false},
        {testNbr: '3', urlParm: "", bodyFile: "newGameObj", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveLeaderBoard', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newLeaderBoard", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newLeaderBoard", missingParm: false},
        {testNbr: '3', urlParm: "", bodyFile: "newLeaderBoard", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveMoneyMgmtTest', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newMoneyMgmtTest", missingParm: false},
        {testNbr: "2", urlParm: "", body: "newMoneyMgmtTest", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveEventsCollection', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newEventsCollection", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newEventsCollection", missingParm: true},
       ],
        method: "POST"
    },
    {funcName: 'saveFeedbackInfo', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newGameFeedback", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newGameFeedback", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveArchivedGameData', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newArchiveGameData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newArchiveGameData", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveGameActivityTracker', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newActivityTracker", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newActivityTracker", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveArchivedSavingsAmounts', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newArchiveSavings", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newArchiveSavings", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveArchivedCreditCardInfo', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newArchiveCreditCard", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newArchiveCreditCard", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveArchivedActivityTracker', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newArchiveActivityTracker", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newArchiveActivityTracker", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveEventTracker', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newEventTracker", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newEventTracker", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveExtraCredit', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newExtraCredit", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newExtraCredit", missingParm: false},
        {testNbr: '3', urlParm: "", bodyFile: "newExtraCredit", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveLoginUser', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newLoginUser", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newLoginUser", missingParm: false},
        {testNbr: '3', urlParm: "", bodyFile: "newLoginUser", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveUserVerify', testCases: [
        {testNbr: "1", urlParm: "", body: {username: "studentX", verifyCode: "12345678"}, missingParm: false},
        {testNbr: "2", urlParm: "", body: {}}],
        method: "POST"
    },
    {funcName: 'saveAccountCollection', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newAccount", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newAccount", missingParm: false},
        {testNbr: '3', urlParm: "", bodyFile: "newAccount", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveReportDataset', testCases: [
        {testNbr: "1", urlParm: "", body: {rptTitle: "Test Report", rptLines: ["line 1", "line 2"]}, missingParm: false},
        {testNbr: "2", urlParm: "", body: {}}],
        method: "POST"
    },
    {funcName: 'saveContactUsEmail', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newContactUs", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newContactUs", missingParm: true}],
        method: "POST"
    },
    {funcName: 'savePlayerFeedback', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newPlayerFeedback", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newPlayerFeedback", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveArchivedFunStuff', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newArchivedFunStuff", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newArchivedFunStuff", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveLogFile', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newLogFile", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newLogFile", missingParm: true}],
        method: "POST"
    },
    {funcName: 'saveArchivedGamePurchases', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "newArchivedGamePurchases", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "newArchivedGamePurchases", missingParm: true}],
        method: "POST"
    },
  

//  tests for "PUT" requests follow
    {funcName: 'setDate', testCases: [
        {testNbr: "1", urlParm: "?date=2024-08", missingParm: false},
        {testNbr: "2", urlParm: "" }],
        method: "PUT"
    },
    {funcName: 'setCash', testCases: [
        {testNbr: "1", urlParm: "?cash=99999.99", missingParm: false},
        {testNbr: "2", urlParm: "" }],
        method: "PUT"
    },
    {funcName: 'updateHomeInfo', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateHomeInfo", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateHomeInfo", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateCarInfo', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateCarInfo", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateCarInfo", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateAssets', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateAssetInfo", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateAssetInfo", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateExpenses', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateExpenseInfo", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateExpenseInfo", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateLoans', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateLoanInfo", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateLoanInfo", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateConditions', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateConditions", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateConditions", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateAll', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateAllGameData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateAllGameData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateCashAssetExpense', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateCashAssetExpenseData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateCashAssetExpenseData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateSkillSurvey', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateSkillSurveyData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateSkillSurveyData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateCareerInfo', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateCareerInfoData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateCareerInfoData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateUnlockedFeatures', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateUnlockedFeaturesInfo", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateUnlockedFeaturesInfo", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateSavingsAmts', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateSavingsInfo", missingParm: false},
        {testNbr: "2", urlParm: "", bodyFile: "updateSavingsInfo", missingParm: false, upsert: true},
        {testNbr: '3', urlParm: "", bodyFile: "updateSavingsInfo", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateIconTable', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateIconTableData", missingParm: false},
       
        {testNbr: '3', urlParm: "", bodyFile: "updateIconTableData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateCreditCardInfo', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateCreditCardData", missingParm: false},
        {testNbr: "2", urlParm: "", bodyFile: "updateCreditCardData", missingParm: false, upsert: true},
        {testNbr: '3', urlParm: "", bodyFile: "updateCreditCardData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updatePlayerFriendList', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updatePlayerFriendInfo", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updatePlayerFriendInfo", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateGameActivityTracker', testCases: [
        {testNbr: "1", urlParm: "?gameID=888888888&category=Vacation", 
            bodyFile: "updateActivityTrackerData", missingParm: false},
        {testNbr: "2", urlParm: "?gameID=88888888&category=Vacation", 
            bodyFile: "updateActivityTrackerData", missingParm: false},
        {testNbr: '3', urlParm: "", 
            bodyFile: "updateActivityTrackerData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateCashMoodFriendList', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateCashMoodFriendData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateCashMoodFriendData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateSpendingHistory', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateSpendingHistoryData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateSpendingHistoryData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateMoodPointInfo', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateMoodPointData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateMoodPointData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateExtraCredit', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateExtraCreditInfo", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateExtraCreditInfo", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateAccount', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateAccountData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateAccountData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateLogin', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateLoginData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateLoginData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateEvent', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateEventData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateEventData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateEventResponse', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateEventResponseData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateEventResponseData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateFunStuff', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateFunStuffData", missingParm: false},
        {testNbr: "2", urlParm: "", bodyFile: "updateFunStuffData", missingParm: false, upsert: true},
        {testNbr: '3', urlParm: "", bodyFile: "updateFunStuffData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateGameInfoGeneral', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateGameInfoGeneralData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateGameInfoGeneralData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'updateBadgesEarned', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateBadgesEarnedData", missingParm: false},
        {testNbr: '2', urlParm: "", bodyFile: "updateBadgesEarnedData", missingParm: true}],
        method: "PUT"
    },
    {funcName: 'removeCardFromHand', testCases: [
        {testNbr: "1", urlParm: "", missingParm: false} ],
        method: "PUT"
    },
    {funcName: 'updateGamePurchases', testCases: [
        {testNbr: "1", urlParm: "", bodyFile: "updateGamePurchases", missingParm: false, upsert: true},
        {testNbr: "2", urlParm: "", bodyFile: "updateGamePurchases2", missingParm: false, upsert: true},
        {testNbr: '3', urlParm: "", bodyFile: "updateGamePurchases", missingParm: true}],
        method: "PUT"
    },
   
     
    //  delete router test info follows
    {funcName: 'deleteActivityTracker', testCases: [
        {testNbr: "1", urlParm: "?gameID=888888888&category=Test",  missingParm: false},
        {testNbr: "2", urlParm: "?gameID=888888888",  missingParm: false},
        {testNbr: '3', urlParm: "",  missingParm: true}],
        method: "DELETE"
    },
    {funcName: 'endGame', testCases: [
        {testNbr: "1", urlParm: "",  missingParm: false}],
          method: "DELETE"
    },
    {funcName: 'deleteCreditCardInfo', testCases: [
        {testNbr: "1", urlParm: "?gameID=77777777",  missingParm: false},
        {testNbr: '2', urlParm: "",  missingParm: true}],
        method: "DELETE"
    },
    {funcName: 'deleteSavingsInfo', testCases: [
        {testNbr: "1", urlParm: "?gameID=77777777",  missingParm: false},
        {testNbr: '2', urlParm: "",  missingParm: true}],
        method: "DELETE"
    },
    {funcName: 'deleteUserVerify', testCases: [
        {testNbr: "1", urlParm: "",  missingParm: false}],
          method: "DELETE"
    },
    {funcName: 'deleteEventTracker', testCases: [
        {testNbr: "1", urlParm: "?playerName=studentX",  missingParm: false}],
        method: "DELETE"
    },
    {funcName: 'deleteFunStuffInfo', testCases: [
        {testNbr: "1", urlParm: "?gameID=77777777",  missingParm: false},
        {testNbr: '2', urlParm: "",  missingParm: true}],
        method: "DELETE"
    },
    {funcName: 'deleteGamePurchases', testCases: [
        {testNbr: "1", urlParm: "?gameID=77777777",  missingParm: false},
        {testNbr: '2', urlParm: "",  missingParm: true}],
        method: "DELETE"
    },
   
]

export default FunctionListDataset;