const updateSkillSurveyInfo = {
    skillSurvey:  [
        "9",
        "9",
        "9",
        "9",
        "9",
        "9",
        "9",
        "9",
        "9",
        "9",
        "9",
        "9"
      ],
    jobInfo:  {
        "careerID": "9999SkillSurvey9999",
        "healthInsInd": "true",
        "jobRank": "8",
        "nbrYearsCollege": "4",
        "nbrSkillSurvey": "1",
        "jobStartDate": "2022-10"
      }
}

export default updateSkillSurveyInfo;