import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CalculatorLineDisplayCard from './calculator_line_display_card';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';
import ConfigFile from '../../datasets/config_file';
import CalculatorText from '../for-parents/text/calculator_text';
import CreditCardExerciseDataset from './Datasets/credit_card_exercise_dataset';
import {Tooltip} from "@mui/material";

class CreditCardCalculatorModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         entryTab: [],
         calcComplete: false,
         displayFullInstructions: true,
         exercise1: true,
         exerciseDisplay: false
    };    
    this.initializeAmounts();
  }

  initializeAmounts(){
    this.infoMessage = "";
    this.exerciseText = "";
    this.initialAmt = 0;
    this.creditCardLineArr = [];
    this.creditCardIntRate = +ConfigFile.unlockedFeatures.creditCardInterest;
    this.minPaymentPercent = +ConfigFile.unlockedFeatures.creditCardMinimumPayPct;
    this.creditCardIntRateDisplay = (+this.creditCardIntRate * 100).toFixed(0);
    this.minPaymentPercentDisplay = (+this.minPaymentPercent * 100).toFixed(0);
    const creditCardTextObj = CalculatorText.find(elem => elem.type === "CreditCard");
    this.creditCardText = creditCardTextObj.reportText;
    this.creditCardParmArr = [];
    this.resetExerciseButtons();
  }
  
  calculateCreditCardAmounts(entryName){
      this.initialAmtError = "";
      this.exerciseText = "";
      this.errorFound = false;
      this.errorMsg = "";
      if (entryName === "Entered"){
            this.conclusionText = "";
            this.getInitialAmount();
            if (this.errorFound === false){
                const inputParms = this.getInputParms();
                 this.calcValuesEntered(inputParms.paymentOption, inputParms.monthlyChargeAmt, inputParms.nbrYears);
            }
      }else{
            this.initialAmt = 10000;
            document.getElementById("initialAmt").value = 10000;
            this.calcExerciseValues(entryName);
      }
  }

  getInitialAmount(){
      this.initialAmt = document.getElementById("initialAmt").value;
      if (isNaN(+this.initialAmt) || this.initialAmt === ""){
          this.errorFound = true;
          this.initialAmtError = "Initial Amount must be numeric. Correct entry and then " +
                "click the 'Calculate' button again."
      }
  }
  
  getInputParms(){
    let monthlyPaymentOption = "";
    let nbrYears = 0;
    let monthlyChargeAmt = 0;
    monthlyPaymentOption = this.paymentOption.value;
    nbrYears = +this.nbrYears.value;
    monthlyChargeAmt = +this.monthlyChargeAmt.value;
    const parmObj = {paymentOption: monthlyPaymentOption, nbrYears: nbrYears, monthlyChargeAmt: monthlyChargeAmt};
    return parmObj;
}

  calcExerciseValues(exerciseName){
      switch (exerciseName){
          case "PaymentOption":
            this.paymentOptionDisabled = true;
            break;
          case "PayBalance":
             this.payBalanceButtonDisabled = true;
             break;
          default:
             break;
      }
      this.processExerciseValues(exerciseName);
  }

  processExerciseValues(exerciseName){
      this.creditCardParmArr = [];
      this.resetExerciseButtons();
      const exerciseObj = 
        CreditCardExerciseDataset.find(elem => elem.exerciseName === exerciseName);
      this.exerciseText = exerciseObj.text;
      this.conclusionText = exerciseObj.conclusion;
      const creditCardParmArr = exerciseObj.parametersArr;
      for (let i=0; i<creditCardParmArr.length; i++){
           const paymentOption = creditCardParmArr[i].paymentOption;
           const monthlyChargeAmt = creditCardParmArr[i].monthlyChargeAmt;
           const nbrYears = creditCardParmArr[i].nbrYears;
           this.calcValuesEntered(paymentOption, monthlyChargeAmt, nbrYears);
      }
  }
  
  calcValuesEntered(paymentOption, monthlyChargeAmt, nbrYears){   
    if (this.errorFound === false){
        this.calculateLineAmts(paymentOption, monthlyChargeAmt, nbrYears);
    }
    if (this.errorFound === false){
        this.creditCardLineArr.push(this.creditCardObj);
        if (this.creditCardLineArr.length > 5){
            this.creditCardLineArr.splice(0,1);
            this.infoMessage = 
              "Max of 5 calculations, the first entry has rolled off.  Click on the 'Clear/Reset' " +
                " button if you wish to clear all entries and start again."
        }else{
          this.infoMessage = "";
        }
    }
    this.setState({calcComplete: true});
  }

  calculateLineAmts(paymentOption, monthlyChargeAmt, nbrYears){
      let nbrIterations = 0;
      let outOfPocket = 0;
      let totalInterest = 0;
      let currentBalance = +this.initialAmt;
      let monthPayAmt = 0;
      const totalMonths = nbrYears * 12;
      if (paymentOption === "full"){
        currentBalance = 0;
        outOfPocket = (+this.initialAmt + (+monthlyChargeAmt * totalMonths)).toFixed(2);
      }else{
          const monthlyIntRate = (this.creditCardIntRate / 12).toFixed(6);
          while (nbrIterations < totalMonths){
              currentBalance = +currentBalance + +monthlyChargeAmt; 
              let minPayAmt = (+currentBalance * +this.minPaymentPercent).toFixed(2);
              if (paymentOption === "min"){
                  monthPayAmt = minPayAmt;
              }else{
                  monthPayAmt = +paymentOption;
              }
              if (minPayAmt > monthPayAmt){
                  this.errorFound = true;
                  this.errorMsg = "Increase the Monthly Pay Amount.  It must be greater than minimum pay amount ($" +
                      minPayAmt + ")";
                  break;
              }
              if (monthPayAmt > currentBalance){
                  this.errorFound = true;
                  this.errorMsg = "Reduce the Monthly Pay Amount.  It is greater than the credit card balance for one or more months.";
                  break;
              }
              currentBalance = +currentBalance - +monthPayAmt;
              outOfPocket += +monthPayAmt;
              let monthlyIntAmt = (currentBalance * monthlyIntRate).toFixed(2);
              totalInterest = +totalInterest + +monthlyIntAmt;
              currentBalance = +currentBalance + +monthlyIntAmt;
              nbrIterations += 1;
          }
      }
      const endingBalance = currentBalance;
      let monthlyPayAmt = null;
      if (paymentOption === "min" || paymentOption === "full"){
          monthlyPayAmt = (outOfPocket / totalMonths).toFixed(2);
      }else{
          monthlyPayAmt = +paymentOption;
      }
      const nbrYearsLit = nbrYears + " Yrs";
      const totalAmtNewCharges = (+monthlyChargeAmt * +totalMonths) + +this.initialAmt;
      this.creditCardObj={beginCreditCardBalance: this.initialAmt, monthlyPayAmt: monthlyPayAmt, 
        payOption: paymentOption, monthlyChargeAmt: monthlyChargeAmt, nbrYears: nbrYearsLit, 
        outOfPocket: outOfPocket, totalNewChargeAmt: totalAmtNewCharges, totalInterest: totalInterest, 
        endCreditCardBalance: endingBalance}       
  }

  resetExerciseButtons(){
    this.paymentOptionDisabled = false;
    this.payBalanceButtonDisabled = false;
  }
  
  onClear(){
    this.creditCardLineArr = [];
    this.infoMessage = "";
    this.initialAmt = 0;
    this.exerciseText = "";
    document.getElementById("initialAmt").value = "";
  }
 
 
  render() { 
    this.gutters = this.props.gutters;
    if (this.props.screenSize === "narrow" || this.props.screenSize === "medium"){
       this.gutters = "modal-95";
    }
    this.calculatorLines = this.creditCardLineArr.map((creditCardInfo, index) =>
      <CalculatorLineDisplayCard calcLineInfo={creditCardInfo}  key={index} index={index}
         />
          );
      
  return <>
    <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="xl" scrollable="true"
         dialogClassName={this.gutters} animation={false}>
      <Modal.Header className="backgroundTopBottom" closeButton>
        <Modal.Title>Credit Card Calculator</Modal.Title> 
      </Modal.Header>
      <Modal.Body>
          
          <div className="mx-2 mt-1">
            <p>{this.creditCardText}</p>
            <p>Here are some other factors that impact the calculations.  These factors are based on amounts
               that are typically applied by banks that administer credit cards. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <b>Minimum Payment Percent:</b>&nbsp;&nbsp; {this.minPaymentPercentDisplay}%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <b>Credit Card Interest:</b>&nbsp;&nbsp; {this.creditCardIntRateDisplay}%
            </p>
        </div>  
        {this.state.exercise1  ?
            <div className="mx-2 mb-2">{this.creditCardExercise1Text}</div>
        :
            null
        }
       
        <div >
            <Tooltip title="The amount of money that is currently outstanding on the credit card. 
                   Note:  This initial balance can be $0." arrow>
                        <label className="font-weight-bold text-dark" htmlFor="initialAmt">
                        Initial Credit Card Balance:&nbsp;&nbsp;</label>
            </Tooltip>
                <input type="text" className="col-med" id="initialAmt" name="initialAmt"></input> 
                {this.initialAmt > 0  ?
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;
                      <b className="text-dark">
                          Initial Credit Card Amount: &nbsp;</b>
                        <span className="text-dark">{doubleToStringMonthly(this.initialAmt)}</span>
                    </span>
                :
                    null
                }
                <span className="text-danger">
                   &nbsp;{this.initialAmtError}</span>                   
        </div>
   
        <span className="row w-100 mt-1 ml-0 mr-0">
          <Tooltip title="How do you wish to pay off the credit card balance?" arrow>
                  <div className="p-0 text-dark">
                      <div><b>Payment Option:&nbsp;</b> 
                        <select id = "dropdown" ref = {(input)=> this.paymentOption = input}>
                            <option value="full">Pay Full Balance Each Month</option>
                            <option value="min">Pay Minimum Amt Each Month</option>
                            <option value="200">Pay $200 Each Month</option>
                            <option value="500">Pay $500 Each Month</option>
                            <option value="750">Pay $750 Each Month</option>
                            <option value="1000">Pay $1000 Each Month</option>
                            <option value="1250">Pay $1250 Each Month</option>
                            <option value="1500">Pay $1500 Each Month</option>
                            <option value="2000">Pay $2000 Each Month</option>
                            <option value="2500">Pay $2500 Each Month</option>
                            <option value="3000">Pay $3000 Each Month</option>
                            <option value="3500">Pay $3500 Each Month</option>
                            <option value="4000">Pay $4000 Each Month</option>   
                            <option value="4500">Pay $4500 Each Month</option>
                            <option value="5000">Pay $5000 Each Month</option>
                        </select> 
                      </div>
                    </div>
            </Tooltip>

            <Tooltip title="How much money will be added to the credit card each month?" arrow >
                  <div className="ml-5 p-0 text-dark">  
                      <p><b>&nbsp;&nbsp;Avg Amt Charged Each Month:&nbsp;</b>
                      <select id = "dropdown" ref = {(input)=> this.monthlyChargeAmt = input}>
                          <option value="0">$0</option>
                          <option value="200">$200</option>
                          <option value="500">$500</option>
                          <option value="750">$750</option>
                          <option value="1000">$1000</option>
                          <option value="1250">$1250</option>
                          <option value="1500">$1500</option>
                          <option value="1750">$1750</option>
                          <option value="2000">$2000</option>
                          <option value="2500">$2500</option>
                          <option value="3000">$3000</option>
                          <option value="4000">$4000</option>
                          <option value="5000">$5000</option>
                      </select></p>
                    </div>
            </Tooltip>

            <Tooltip title="How many years should these factors be applied?" arrow >
                    <div className="ml-5 p-0 text-dark">
                      <p><b>Nbr of Years:&nbsp;</b>
                      <select id = "dropdown" ref = {(input)=> this.nbrYears = input}>
                          <option value="1">1 Year</option>
                          <option value="2">2 Years</option>
                          <option value="3">3 Years</option>
                          <option value="5">5 Years</option>
                          <option value="10">10 Years</option>
                          <option value="15">15 Years</option>
                          <option value="20">20 Years</option>
                      </select></p>
                    </div>  
            </Tooltip>
          </span> 
          <table className="table table-hover">
            <thead>
              <tr className="thead-light">
                <th></th>
                <th className="py-0 text-center" scope="col">
                    <Tooltip title="Amt of money initially outstanding on the credit card." arrow >
                        <span className="text-dark"> Beginning Balance</span>
                    </Tooltip>
                </th>
                <th className="py-0 text-center" scope="col">
                    <Tooltip title="This is determined based on the 'Payment Option' field." arrow >
                        <span className="text-dark">Amt Paid Each Month</span>
                    </Tooltip>      
                </th>
                <th className="py-0 text-center" scope="col">
                    <Tooltip title="This is equal to the 'Avg Amt Charged Each Month' factor." arrow >
                        <span className="text-dark">Monthly Charge Amt</span>
                    </Tooltip>
                </th>
                <th className="py-0 text-center" scope="col">
                    <Tooltip title="This is equal to the 'Number of Years' factor." arrow >
                        <span className="text-dark">Amt of Time</span>
                    </Tooltip>
                </th>
                <th className="py-0 text-center" scope="col">
                  <Tooltip title="The total amount added to the card balance 
                      for the specified number of years plus the initial credit card balance amount." arrow>
                          <span className="text-dark">Total Amt Charged</span>
                  </Tooltip>
                </th>
                <th className="py-0 text-center" scope="col">
                  <Tooltip title="The total amount of interest that is added due to any 
                      unpaid amounts in a given month." arrow>
                      <span className="text-dark">Total Interest Charged</span>
                  </Tooltip>
                </th>
                <th className="py-0 text-center" scope="col">
                  <Tooltip title="The total that you have actually paid 'out-of-pocket'." arrow >
                      <span className="text-dark">Total Amount Paid</span>
                  </Tooltip>
                </th>
                <th className="py-0 text-center" scope="col">
                  <Tooltip title="The total amount that is owed on the credit card 
                      after the factors are applied." arrow >
                        <span className="text-dark">Ending Balance</span>
                  </Tooltip>
                </th>
              </tr>
            </thead>
            {this.calculatorLines}
           
        </table>
        <div className="mt-2">
              Learn more about the credit card factors:  Enter your own values (above) and click the Calculate button 
                ...OR... click on one of the buttons below.  When you are done, click the Close button.
              <div>
                <Button className="mt-2"
                    disabled={this.paymentOptionButtonDisabled} variant="info"  
                    onClick={() => this.calculateCreditCardAmounts("PaymentOption")}>
                        Payment Option
                </Button>
                <Button className="mt-2 ml-2"
                    disabled={this.payBalanceButtonDisabled} variant="info"  
                    onClick={() => this.calculateCreditCardAmounts("PayBalance")}>
                        Pay Off Existing Balance
                </Button>
              </div>
          </div>
          <div className="text-left">
              {this.exerciseText}
         </div>
         {this.exerciseText !== "" ?
            <div><b>Conclusion:</b>&nbsp;{this.conclusionText}</div>
          :
            null
         }

        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
          <p className="text-right">{this.infoMessage}</p>
          <p className="text-danger">{this.errorMsg}</p>
          <div>
              <Button className="ml-2" variant="primary"  onClick={() => this.props.onClose()}>
                  Close
              </Button>
              <Button className="ml-2" variant="primary"   onClick={() => this.onClear()}>
                  Clear/Reset
              </Button>
              <Button className="ml-2" variant="primary"  onClick={() => this.calculateCreditCardAmounts("Entered")}>
                  Calculate
              </Button>
          </div>
         
        </Modal.Footer>
      </Modal>

  

      
    </>
  }

 
}
export default CreditCardCalculatorModal;