import "./GameBoardActivities/dragNDrop.css";
import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button';
import DragNDropColumn from './drag_n_drop_column_grocery'
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { createNewGroceryDataset } from "./objects/createNewGroceryDataset";
import { calculateDNDScore } from "./objects/calculateDNDScore";
import { findDataObject } from "../../objects/CommonUse/find_data_object";

const Container = styled.div`display : flex;`

const DragNDropModalGrocery = (props) => {
  const [data, setData] = useState(createNewGroceryDataset(props.DNDParameters));
  const [button, setButton] = useState("check");
  const [message, setMessage] = useState("");
  const [correctMsg]=useState(props.correctMsg);
  const [msgClass, setMsgClass]=useState("");
  const [score, setScore]=useState(props.score.points);
  const [nbrGames, setNbrGames]=useState(props.nbrGames - props.score.gamesRemaining);
  const [maxNbrGames] = useState(props.nbrGames);
  const [nbrWrong, setNbrWrong] = useState(0)
  const [totalNbrWrong, setTotalNbrWrong] = useState(0);
  const [scoreObj, setScoreObj] = useState(findDataObject("activityScore").score);
  const [maxScore, setMaxScore] = useState(props.score.maxScore);

  const newData = () =>{
        let data = createNewGroceryDataset(props.DNDParameters);
        setData(data);  
        setButton("done");
        setMessage("Keep up the Good Work!!")
        setMsgClass("text-green font-weight-bold");
   //     props.startTimer();
  }

  const calcScore = () => {
       let messageObj = calculateDNDScore(data, props.cookieNamePfx, "columnOnly", props.modalType);
       let totNbrGames = 0;
       let allGamesNbrWrong = 0;
       let totalScore = 0;
      
       if (messageObj.correct === true){
           setMessage(messageObj.message + " " + correctMsg);
           setMsgClass("text-green font-weight-bold");
           setButton("start");
           let newScore = messageObj.score - nbrWrong;
           setScore(score + newScore);
           totalScore = maxScore + messageObj.score;
           setMaxScore(totalScore);
           totNbrGames = nbrGames + 1;
           setNbrGames(nbrGames + 1);
           allGamesNbrWrong = totalNbrWrong + nbrWrong;
           setTotalNbrWrong(allGamesNbrWrong);
           setNbrWrong(0);
        }else{
           setMessage(messageObj.message);
           setMsgClass("text-danger font-weight-bold");
           setButton("check");
           setNbrWrong(nbrWrong + 1);
       }
       if (totNbrGames >= maxNbrGames){
            if (allGamesNbrWrong === 0){
               scoreObj.newLevelEarned = true;
            }else{
              scoreObj.newLevelEarned = false;
            }  
            let outScore = score + messageObj.score;
            scoreObj.type = "finish";
            scoreObj.points = outScore;
            scoreObj.maxScore = totalScore;
            scoreObj.nbrIncorrect = totalNbrWrong;
            scoreObj.gamesRemaining = maxNbrGames - totNbrGames;
            setScoreObj(scoreObj);
            props.onGameOver(scoreObj);
        }
  }

  function endGame(){
      scoreObj.type = "done";
      scoreObj.points = score;
      scoreObj.maxScore = maxScore;
      scoreObj.nbrIncorrect = totalNbrWrong;
      scoreObj.gamesRemaining = maxNbrGames - nbrGames;
      scoreObj.nbrAttempted = nbrGames;
      setScoreObj(scoreObj);
      props.onGameOver(scoreObj);
  }
    
  const onDragEnd = result => {
    //if freeze flag is true, then no moves are allowed
    if (props.freezeFlag !== undefined && props.freezeFlag === true){
          return
    }
   
    const { destination, source, draggableId, type } = result;
    //If there is no destination
    if (!destination) { return }

    //If source and destination is the same
    if (destination.droppableId === source.droppableId && destination.index === source.index) { return }

    //Anything below this happens if you're dragging loan description
    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    //If the column freeze flag is true, then do not allow drag or drop
    //if (start.freeze === true ||  finish.freeze === true){
    //  return;
    //}

    //reset if dragging a column
    if (type === 'column') {
      return;
    }
    
    //If dropped inside the same column
    if (start === finish) {
      const newTaskIds = Array.from(start.itemIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...start,
        itemIds: newTaskIds
      }
      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn
        }
      }
      setData(newState)
      return;
    }

    //If dropped in a different column
    const startTaskIds = Array.from(start.itemIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      itemIds: startTaskIds
    }

    const finishTaskIds = Array.from(finish.itemIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      itemIds: finishTaskIds
    }

    //if more than 'maxNbr' items dragged to a column then return (except column 1)
    let maxNbr = parseFloat(props.maxNbr);
    if (!isNaN(maxNbr) &&
        finishTaskIds !== undefined && finish.id !== "column-1" && finishTaskIds.length > maxNbr) {
          return; 
    } 
 
    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    setData(newState)
  }

 
  return (
        <div>
                <h3 className="w-100">{props.modalTitle} - Level &nbsp;{props.levelNbr}</h3>
                <span>
                   <div className="row"> 
                        <div className="col-8">
                              <div>{props.introText}</div>
                              <div className={msgClass}>{message}</div>
                        </div>
                        <div className="col-4"> 
                          <div className = "row">
                                <div className="col-6 font-weight-bold">Total Score:</div>
                                <div className="col-4">{score}</div>
                          </div>
                          <div className = "row">
                                <div className="col-6 font-weight-bold">Orders Filled:</div>
                                <div className="col-4">{nbrGames}&nbsp;of&nbsp;{maxNbrGames}</div>
                          </div>   
                          {button === "start" ?
                               <Button className="text-right" variant="primary"  onClick={() => newData()}>
                                    Start
                                </Button>
                          :
                                <Button className="text-right" variant="primary"  onClick={() => calcScore()}>
                                    Check
                                </Button>
                          }
                          <Button className="text-right ml-4" variant="primary"  onClick={() => endGame()}>
                              Done
                          </Button>
                          
                         
                        </div>
                    </div> 
                </span>
               
          
            <div className="drag_n_drop_grocery_background mt-2 dnd_picture_size">
                                                          
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='all-columns' direction='horizontal' type='column'>
                      {(provided) => (
                        <Container {...provided.droppableProps} ref={provided.innerRef}>
                          {data.columnOrder.map((id, index) => {
                            const column = data.columns[id]
                            const items = column.itemIds.map(itemId => data.items[itemId])

                            return <DragNDropColumn key={column.id} column={column} items={items} 
                              index={index} cookieNamePfx={props.cookieNamePfx} 
                              />
                            })}
                          {provided.placeholder}
                        </Container>
                   )}
                  </Droppable>
                </DragDropContext>
            </div>
                      
        </div>      
  );
    }
  

export default DragNDropModalGrocery