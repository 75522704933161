const GroceryStoreShopperDataset = { 
         
       items: 
              {className: "drag_n_drop_item_cannedFood", directory: "./assets/Activities/Grocery/", type: "image",
               itemList: [
                     { category: "a-produce", name: "green pepper", pluralRule: 1, picture: "greenPepper.png"},
                     { category: "a-produce", name: "red pepper", pluralRule: 1,  picture: "redPepper.png"},
                     { category: "a-produce", name: "red apple", pluralRule: 1, picture: "apple.png"},
                     { category: "a-produce", name: "tomato", pluralRule: 1, picture: "tomato.png"},
                     { category: "a-produce", name: "orange", pluralRule: 1, picture: "orange.png"},
                     { category: "v-cannedVeg",  name: "can of peas", pluralRule: 2, picture: "canOfPeas.png"},
                     { category: "v-cannedVeg",  name: "can of beans", pluralRule: 2, picture: "beans.png"},
                     { category: "v-cannedVeg",  name: "can of corn", pluralRule: 2, picture: "corn.png"},
                     { category: "v-cannedVeg", name: "can of olives", pluralRule: 2, picture: "olives.png"},
                     { category: "s-soup",  name: "can of chicken noodle soup", pluralRule: 2, picture: "ChickenNoodleSoup.png"},
                     { category: "s-soup", name: "can of tomato soup", pluralRule: 2, picture: "tomatoSoup.png"},
                     { category: "s-soup", name: "can of beef barley soup", pluralRule: 2, picture: "beefBarleySoup.png"},
                     { category: "f-cannedFruit", name: "can of mixed fruit", pluralRule: 2, picture: "mixedFruit.png"},
                     { category: "f-cannedFruit", name: "can of peaches", pluralRule: 2, picture: "canOfPeaches.png"},
                     { category: "c-condiments", name: "jar of peanut butter", pluralRule: 2, picture: "peanutButter.png"},
                     { category: "c-condiments", name: "jar of jam", pluralRule: 2,  picture: "jarJelly.png"},
                     { category: "c-condiments", name: "jar of bbq sauce", pluralRule: 2, picture: "bbqSauce.png"},
                     { category: "c-condiments", name: "bottle of ketchup", pluralRule: 2, picture: "ketchup.png"},
                     { category: "c-condiments", name: "bottle of mustard", pluralRule: 2, picture: "mustard.png"},
                     { category: "p-pasta", name: "box of pasta", pluralRule: 3, picture: "spaghetti.png"},
                     { category: "p-pasta", name: "box of macaroni", pluralRule: 3, picture: "macaroni.png"},
                     { category: "d-dairy", name: "carton of milk", pluralRule: 2, picture: "milk.png"},
                     { category: "d-dairy", name: "tub of butter", pluralRule: 2, picture: "butter.png"},
                     { category: "d-dairy", name: "cup of yoghurt", pluralRule: 2, picture: "yoghurt.png"},
                     { category: "b-baking", name: "bag of flour", pluralRule: 2, picture: "flour.png"},
                     { category: "b-baking", name: "bag of sugar", pluralRule: 2, picture: "sugar.png"},
                     { category: "b-baking", name: "box of salt", pluralRule: 3, picture: "salt.png"},
                     { category: "b-baking", name: "box of baking soda", pluralRule: 3, picture: "bakingSoda.png"},
                     { category: "b-baking", name: "box of baking powder", pluralRule: 3, picture: "bakingPowder.png"},
                     { category: "n-snacks", name: "bag of chips", pluralRule: 2, picture: "chips.png"},
                     { category: "n-snacks", name: "bag of crisps", pluralRule: 2, picture: "crisps.png"},
                     { category: "n-snacks", name: "box of cookies", pluralRule: 3, picture: "cookies.png"},
                     { category: "n-snacks", name: "bag of candy", pluralRule: 2, picture: "candy.png"},
                     { category: "n-snacks", name: "box of crackers", pluralRule: 3, picture: "crackers.png"}
               ]},
     
        columns:  
              {
              columnList:[
                     { title: "Grocery Store Food", className: "drag_n_drop_basic_column drag_n_drop_shelves_column",
                            multColumns: "multiple-columns", columnSize: "col-med"},
                     { title: "Shopper Cart",  className: "drag_n_drop_basic_column drag_n_drop_grocery_cart",
                            multColumns: "multiple-columns", columnSize: "col-med" },
                     { title: "Shopping List 1", className: "drag_n_drop_regular_column", 
                            multColumns: "", columnSize: "" },
                     { title: "Shopper Cart2",  className: "drag_n_drop_basic_column drag_n_drop_grocery_cart",
                            freeze: false, multColumns: "multiple-columns", columnSize: "col-med" },
                     { title: "Shopping List 2", className: "drag_n_drop_regular_column", 
                            multColumns: "", columnSize: "" },
              ]}
        

    } 

export default GroceryStoreShopperDataset