import React, { Component } from 'react'
import './GameBoardActivities/puzzleimg.css';
import Button from 'react-bootstrap/Button';
import UnderCoverPuzzleCard from './undercover_puzzle_card';
import UnderCoverRadioButtons from './undercover_puzzle_radio_buttons';
import calcRandomNumberWithMax from '../../objects/CommonUse/calc_random_number_with_max';
import { determineFriendComment } from './objects/determine_friend_comment';
import { displayFriendComment } from './objects/display_friend_comment';

class UnderCoverPuzzleModal extends Component {
  constructor(props){
    super(props);
    this.state={
        seconds:0,
        pictureDir: "./assets/Activities/Vacation/",
        finished: false,
        callRender: false,
    }
    this.invisibleArr = [];
    this.nbrColumns = 6;
    this.nbrRows = 5;
    this.totalWidth = 0;
    this.totalHeight = 0;
    this.indexArr = [];
    this.blankOutAllArr = [];
    this.imageArr = this.props.imageList;
    this.nbrPictures = 0;
    this.score = this.props.score;
    this.commentNbr = 0;
    this.arrayCreated = false;
    this.displayRadioButtons = false;
    this.startButton = true;
    this.displayFriend = false;
    this.correctAnswer = "";
  }
  
  preProcess(){
     this.createArray();
     this.selectPicture();
     this.startButton = true;
     this.displayFriend = false;
  }
  
 createArray(){
    this.indexArr = [];
    this.invisibleArr = [];
    this.displayRadioButtons = false;
    this.nbrInArray = this.nbrColumns * this.nbrRows;
    for (let i=0; i<this.nbrInArray; i++){
          this.indexArr.push(i);
    }
    this.blankOutAllArr = JSON.parse(JSON.stringify(this.indexArr));
    this.arrayCreated = true; 
    this.message = "Click Start to Uncover the answer."
    this.message2 = "";
    this.pictureFound = "";
    this.message3 = "";
  }

  selectPicture(){
      this.newPictureArr = [];
      this.correctAnswer = "";
      this.nbrPictures = this.props.score.nbrAttempted;
      for (let i=0; i<this.imageArr.length; i++){
           if (this.imageArr[i].selected === false){
                this.newPictureArr.push(this.imageArr[i]);
           }else{
                this.nbrPictures += 1;
           }
      }
      if (this.newPictureArr.length === 0){
          this.onClose();
      }else{
          let maxIndex = this.newPictureArr.length - 1;
          this.pictureIndx = calcRandomNumberWithMax(maxIndex);
          this.answer = this.newPictureArr[this.pictureIndx].answer;
          this.label = this.newPictureArr[this.pictureIndx].label;
          if (this.newPictureArr[this.pictureIndx].pictureName === undefined){
             this.pictureName = this.label
          }else{
             this.pictureName = this.newPictureArr[this.pictureIndx].pictureName;
          } 
          this.backgroundURL = this.state.pictureDir + this.newPictureArr[this.pictureIndx].imageSrc;
      }
   }

  setPictureSize(){
    this.speechClass = "speech-vacation-activity right";
    switch (this.props.screenSize){
        case "narrow":
            this.backgroundClass = "under-cover-puzzle-background-narrow";
            this.imgClass = "under-cover-img-narrow";
            this.textPosition = "under-cover-text-position-narrow col-sm-9";
            this.textClass = "mt-2 small-text";
            // totalWidth and totalHeight must equal what is in the css!
            this.totalWidth = 360;
            this.totalHeight = 250;
            this.friendPosition ="under-cover-friend-position-narrow"; 
            this.speechClass = this.speechClass + " speech-vacation-position-narrow";
            break;
        case "medium":
            this.backgroundClass = "under-cover-puzzle-background-medium";
            this.imgClass = "under-cover-img-medium";
            this.textPosition = "under-cover-text-position-medium col-sm-8";
            this.textClass = "mt-2 small-text";
            this.totalWidth = 480;
            this.totalHeight = 340;
            this.friendPosition ="under-cover-friend-position-medium"; 
            this.speechClass = this.speechClass + " speech-vacation-position-medium";
            break;
        default:
            this.backgroundClass = "under-cover-puzzle-background";
            this.imgClass = "under-cover-img";
            this.textPosition = "under-cover-text-position col-sm-8";
            this.textClass = "mt-2";
            this.totalWidth = 660;
            this.totalHeight = 480;
            this.friendPosition ="under-cover-friend-position"; 
            this.speechClass = this.speechClass + " speech-vacation-position";
            console.log("speechClass is: ", this.speechClass);
            break;
    }
  }

  onStart=()=>{
    this.displayRadioButtons = true;
    this.displayFriend = false;
    if (this._isMounted === true){
        this.setState({seconds:this.state.seconds+1});
    }
    this.displayRadioButtons = true;
    this.createInvisibleEntry();
    
 }

 createInvisibleEntry=()=>{
     if (this.indexArr.length > 0){
        let maxIndex = this.indexArr.length - 1;
        let indx = calcRandomNumberWithMax(maxIndex);
        let invisibleIndx = this.indexArr[indx];
        this.invisibleArr.push(invisibleIndx);
        this.indexArr.splice(indx,1);
    }else{
        this.onChangeValue("timeout");
    }
 }

 timer=()=>{
    this.f=setInterval(this.onStart,500);
    document.getElementById('btn').disabled=true;
 }

 onPause=()=>{
     clearInterval(this.f);
     this.startButton = false;
     document.getElementById('btn').disabled=false;
 }

  onChangeValue(slctAnswer) {
      if (slctAnswer === this.answer  || slctAnswer === "timeout"){
          this.displayRadioButtons = false;
          if (slctAnswer === this.answer){
            this.message = "Congratulations! You figured it out. "
          }else{
            this.message = "Oops!  The time has expired.   " 
          }
          this.message2 = "The picture is: ";
          this.message3 = " Click the 'New Picture' button to visit another place. "
          this.pictureFound = this.pictureName;
          this.onPause();
          this.invisibleArr = this.blankOutAllArr;
          this.setSelectedForImageArr();  
          this.displayFriend = displayFriendComment(this.props.activityCategory, this.props.friendInfo);
          if (this.displayFriend === true){
                 this.getComment();
          }
    //      this.forceUpdate();
          this.setState({callRender: true});
      }else{
        this.message = "Not quite right, please try again."
      } 
  } 

  setSelectedForImageArr(){
      this.nbrPictures += 1;
      for (let i=0; i<this.imageArr.length; i++){
          if (this.imageArr[i].answer === this.answer){
               this.imageArr[i].selected = true;
               break;
          }   
      }
  }

 onClose(){
        if (this.nbrPictures === this.imageArr.length){
            this.displayRadioButtons = false;
            this.message = "Congratulations! You figured it out.  You've had a great time on your vacation! " +
                "You will arrive back at your home in just a little bit."
            this.score.type="finish";
        }else{
            this.score.type="done";
        }
        this.message2 = "The picture is: ";
        this.pictureFound = this.pictureName;
        this.message3 = " ";
        this.score.gamesRemaining = this.imageArr.length - this.nbrPictures;
        this.score.points = 100;
        this.score.maxScore = 100;
        this.score.nbrAttempted = this.nbrPictures;
        this.setState({finished: true});
        if (this.score.type === "finish"){
            // wait 3 seconds before returning to activity finish
            if(this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.props.onGameOver(this.score);
            }, 5000);
        }else{
            this.props.onGameOver(this.score);
        }
      
  }
  
 getComment(){
      let commentTable = this.props.friendInfo.commentTable;
      let commentObj = determineFriendComment(commentTable, this.commentNbr);
      this.comment = commentObj.comment;
      this.commentNbr = commentObj.commentNbr;
}

getNewPicture(){
  this.arrayCreated = false;
}

 render(){
    
    if (this.state.finished === false){
        if (this.arrayCreated === false){
            this.preProcess();
        }
        this.setPictureSize();
        this.coverUpCards = this.blankOutAllArr.map((key, index) =>
            <UnderCoverPuzzleCard key={index} index={index} 
                nbrColumns={this.nbrColumns} nbrRows={this.nbrRows}
                totalWidthPx={this.totalWidth} totalHeightPx={this.totalHeight}
                screenSize={this.props.screenSize}
                invisibleArr={this.invisibleArr}/>
            );
        
        if (this.displayRadioButtons === true){
            this.radioButtons = this.imageArr.map((imageList, index) =>
                <UnderCoverRadioButtons key={index} vacationSlct={imageList} 
                correctAnswer={this.correctAnswer}
                onSelect={(slctAnswer) => this.onChangeValue(slctAnswer)}            
                />
            );
        }
    }
    
  return(

    <span className="row-100">
        <div className={this.backgroundClass}>
                <div  className={this.imgClass}
                    style={{backgroundImage: `url(${process.env.PUBLIC_URL + this.backgroundURL})`}}>
                {!this.state.finished ?    
                    <div>
                        {this.coverUpCards}
                    </div>
                :
                    null
                }
              
              <div className={this.textPosition}>
                <div>
                  {this.startButton ?
                      <Button id='btn' size="sm" variant="primary" onClick={this.timer} 
                        disabled={this.state.finished}>
                          Start
                      </Button>
                  :
                      <Button id='btn' size="sm" variant="primary" onClick={this.getNewPicture()} 
                        disabled={this.state.finished}>
                          New Picture
                      </Button>
                  }
                
                  <Button className="ml-4" size="sm" variant="primary"  onClick={() => this.onClose()}>
                      Done
                  </Button>
               
              </div>
              <div className={this.textClass}>
                    <div className="font-weight-bold">Number of Places Visited so Far:  {this.nbrPictures}
                        &nbsp;of&nbsp;{this.imageArr.length}</div>
                    Can you guess what is in the picture?
                    {this.displayRadioButtons ?
                        <span>
                            {this.radioButtons}
                        </span>
                    :
                       <span> 
                            <div className="mt-2 font-weight-bold">
                                {this.message}
                            </div>
                            <div>
                                {this.message2}&nbsp;&nbsp; <b>{this.pictureFound}</b>
                            </div> 
                            <div>
                                {this.message3} 
                            </div>
                        </span>
                    }
                   
              </div>
              {this.displayFriend   ?
                  <div>
                      <div>
                          <img src={this.props.friendInfo.friendImgURL}  
                            className={this.friendPosition} 
                              alt="friendPicture"/>
                      </div>
                      <div className="speech-vacation-activity right">
                             {this.comment}
                      </div>
                  </div>
                :
                    null
                }
          </div>
          </div>
      </div>
    
  </span>              
  )
 }

 componentDidMount(){
    this._isMounted = true;
 }

 componentWillUnmount(){
    this._isMounted = false;
 }
 
}
export default UnderCoverPuzzleModal;

