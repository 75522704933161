export const beforeAfterTableCompare = (beforeTable, afterTable, dataType) => {
  
    const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
    let nonMatchArr = [];
    if (beforeTable.length > afterTable.length){
        const nonMatchObj = {indx: 999, beforeEntry: "length of before " + beforeTable.length,
            afterEntry: "length of after " + afterTable.length};
        nonMatchArr.push(nonMatchObj);
    }else{
        let nbrEntries = beforeTable.length;
        for (let i=0; i<nbrEntries; i++){
            let notEqual = false;
            if (dataType === "object"){
                const obj1 = beforeTable[i];
                const obj2 = afterTable[i];
                notEqual = (!isEqual(obj1, obj2));
            }else{
                if (beforeTable[i] !== afterTable[i]){
                notEqual = true;
                }
            }
            if (notEqual === true){
                const nonMatchObj = {indx: i, beforeEntry: beforeTable[i], afterEntry: afterTable[i]};
                nonMatchArr.push(nonMatchObj);
            }
        }
    }
  
    return nonMatchArr;
}
export default beforeAfterTableCompare;