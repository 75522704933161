import {Component} from 'react';
import "./ForParents/forParents.css";
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import MoneyTrain_Video_Discussion_Topics from './ForParents/Video_Presentations_Discussion_Guide.pdf'
import VideoViewerModal from './video_viewer_modal';


class DiscussionTopics extends Component {
    constructor(props) {
        super(props);
        this.state={
           modalOpen: false,
             
        };  
        this.participationVideo = false;
        this.videoTypeText = "Regular Videos"
        this.determineIfParticipationVideo();
    }

determineIfParticipationVideo(){
    if (this.props.playerRole === "teacher"){
        this.switchToText = "Regular Videos";
        this.videoTypeText = "Participation Videos";
        this.participationVideo = true;        
    }
}

toggleToAlternateVideos(){
    this.participationVideo = !this.participationVideo;
    if (this.participationVideo === true){
        this.switchToText = "Regular Videos"
    }else{
        this.switchToText = "Participation Videos"
    }
}
    
    render() {
        this.tableElementClass = "col text-center";
        let screenParms = setScreenSizeParameters(this.props.screenSize, "forParents");
        this.className = screenParms.columnClass;
        console.log("participation video? ", this.participationVideo);                           
    return <>
           <div className={this.className}>
                <div className="card mt-3 mr-5 rounded-corners-lg height-max">
                    <button className="pages-back-button ml-4 mt-4" 
                        onClick ={()=> this.props.goBack()}>
                                Back
                    </button>
                    <h3 className="mt-0 p-0 text-center">Video Presentations</h3>
                    <h5 className="text-center">{this.videoTypeText}</h5>
                 
                    <div className="mx-4 mt-2">
                          This is a list of videos that cover important Money Management concepts.
                          These are intended to be viewed by players (students). They
                          reinforce content and supplement the MoneyTrain game.  These videos include 
                          sound so make sure your microphone is not muted.
                    </div>
                    {this.participationVideo ?
                        <span className="mt-2">
                            <div className="row">
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("participationVideo")}>
                                            What is a Participation Video? 
                                            <div className="small-text">(1/2 minute)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("introduction")}>
                                            Before You Play 
                                            <div className="small-text">(5 minutes)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("jobChoiceParticipation")}>
                                            Job Choice
                                        <div className="small-text">(6 minutes)</div>
                                    </button>
                                </div>
                            </div>
                        </span>
                    :
                        <span>
                            <div className="row">
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("introduction")}>
                                            Before You Play 
                                            <div className="small-text">(5 minutes)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("theBasics")}>
                                                The Basics 
                                                <div className="small-text">(Not Yet Available)</div>
                                        </button>
                                    </div>
                                    <div className={this.tableElementClass}>
                                        <button className="btn-group-medium mt-2" 
                                            onClick ={()=> this.topicSelected("needsVsWants")}>
                                                 Needs vs. Wants 
                                                <div className="small-text">(7 minutes)</div>
                                        </button>
                                    </div>
                                </div>  
                                <div className="row mt-2">
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-3" 
                                        onClick ={()=> this.topicSelected("jobChoice")}>
                                            Job Choice 
                                            <div className="small-text">(6 minutes)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-3" 
                                        onClick ={()=> this.topicSelected("homeChoice")}>
                                            Home Choice
                                        <div className="small-text"> (6 minutes)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-3" 
                                        onClick ={()=> this.topicSelected("carChoice")}>
                                            Car Choice
                                            <div className="small-text"> (6 minutes)</div>
                                    </button>
                                </div>
                            </div>
                        </span>
                    }
           
                    {this.props.playerRole === "parent"  || this.props.playerRole === "teacher" ?
                        <span>
                            {this.participationVideo ?
                                <span>
                                    <div className="mt-4 text-white">
                                        blank
                                    </div>
                                 
                                </span>
                            :
                                null
                            }
                            <div className="mt-4">
                                <div className="mt-4 text-white">
                                            blank
                                        </div>
                                <div className="mt-4 text-white">
                                            blank
                                </div>
                                <div className="text-center mt-4">
                                    <a className="decorative light-blue-color text-pointer" 
                                        href={MoneyTrain_Video_Discussion_Topics} target="_blank" 
                                        rel="noreferrer">
                                            View Video Presentations Discussion Guide (PDF)
                                    </a>
                                </div>
                            </div>
                        </span>
                    :
                        null
                    }        
                                         
                    {this.props.playerRole === "teacher"  ?
                        <div className="text-center mt-2">
                            <button className="btn-group-small text-center"
                                onClick = {(() => this.toggleToAlternateVideos())}>
                                   Switch To {this.switchToText}
                            </button>
                        </div>
                    :
                        null
                    }       
                                                 
                    {this.state.modalOpen ?
                        <div className="video-display-size" >
                            <VideoViewerModal
                                topicName={this.topicName}
                                show={this.state.modalOpen}
                                onClose={() => this.turnVideoOff()} 
                            />
                        </div>
                    :
                        null
                    }

                                      
                </div>        
            </div>
        </>
    }
    
    topicSelected(topicName){
        this.topicName = topicName;
        this.setState({modalOpen: true});
    }

    turnVideoOff(){
        this.powerPointVideo = "";
        this.setState({modalOpen: false});
    }


}

export default DiscussionTopics;