import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './css/index.css';
import './css/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './bootstrap/css/bootstrap.min.css';
import './config';
import { Provider } from "react-redux";
import appStore from "./redux/store";

const root = 
      createRoot(document.getElementById('root'));
root.render(
      <Provider store={appStore}>
            <App />
      </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();

