const LoanChallenge2Dataset = {
        /* the answer property is used to indicate the correct column for this item.  */
    items: {
        "item-1": { id: "item-1", 
                content: "Car Loan: The individual currently does not own a car and has a net income of " +
                        "$1500 (after expenses).  They do not have a lot of other debt.  The loan would " +
                        "be for $20,000 with a term of 10 years and a 6% interest rate.",
                answer: "column-2" }, 
        "item-2": { id: "item-2", 
                content: "Small Business Loan: Individual is seeking a 10-year loan of $50,000 for their small business.  " +
                        "The business is currently making $500 a month (after expenses).  The father " +
                        "of the business owner will cosign for the loan.  The father’s net worth is " +
                        "over $500,000.  The interest rate for the loan is 8%.  ", 
                answer: "column-2" },
        "item-3": { id: "item-3", 
                content: "Home Improvement Loan: The loan amount requested is $100,000.  The term of the " +
                        "loan will be 10 years with an interest rate of 8%.  The individual has a net " +
                        "income of $1500 per month but they have maxed out their credit card with an " +
                        "outstanding balance of $50,000 and have missed several credit card payments " +
                        "over the past year. ",
                answer: "column-3" },
        "item-4": { id: "item-4", 
                content: "College Loan:  Individual is requesting $15,000 loan for their college tuition. " +
                        "The term of this loan will be 10 years with an interest rate of 4%.  The " +
                        "individual requesting the loan does not have a job but the loan has been " + 
                        "guaranteed by the federal government. ",
                answer: "column-2" },
        "item-5": { id: "item-5", 
                content: "Boat Loan:  An individual wants a loan of $50,000 in order to buy a boat.  The loan " + 
                         "would be for 10 years and have a 7% interest.  The individual has a job and " +
                         "a monthly net income of $1000.00.  However, the individual has a really bad " +  
                         "credit rating because they often miss making credit card payments on and have " +
                         "also occasionally missed making payments on their home. ", 
                answer: "column-3" },
        "item-6": { id: "item-6", 
                content: "Home Improvement Loan:  Individual needs a loan for $85,000 to install solar " +
                         "panels on their home.  The loan will have a term of 5 years and an interest rate " + 
                         "of 6%.  The individual has a monthly net income of $2000.", 
                answer:  "column-2" },
        "item-7": { id: "item-7", 
                content: "Individual requesting a 10-year loan of $75,000 for their small business. " +
                         "The business is currently making $700 a month (after expenses).  The interest " + 
                         "rate for the loan is 7%.", 
                answer:  "column-3" },
      },
    columns: {
        "column-1": { id: "column-1", title: "Loan Request Information", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7"] },
        "column-2": { id: "column-2", title: "Loan Approved", itemIds: [] },
        "column-3": { id: "column-3", title: "Loan Denied", itemIds: [] },
                     
    },
    columnOrder: ["column-1", "column-2", "column-3",]
}

export default LoanChallenge2Dataset