import React from "react";
import { withRouter } from "react-router";
import GameBoxDisplay from "./game_box_display";
import GameCardFaceUp from "../game-views/game_card_face_up";
import EventCategoryList from "../game-views/datasets/event_category_list";
import EnvelopeDisplay from "./envelope_display";
import EnvelopeLegend from "./envelope_legend";


class TestSpinnerOption extends React.Component {
    gameInfo = null;
    _isMounted = false;
    constructor(props) {
        super(props)
        this.gameInfo = this.props.gameInfo();
        this.state={
            eventArr: [],
            gamePhase: 3,
            nextTurn: false,
            eventCategories: EventCategoryList,
            cardArray: [{eventNumber: 1}, {eventNumber: 2}, {eventNumber: 3},
                {eventNumber: 4}, {eventNumber: 5}, {eventNumber: 6}, {eventNumber: 7},
                {eventNumber: 8} ],
            currentCard: {title: "Test Event", description: "test card text",
                    responses: [{btnText: "test1"}, {btnText: "test2"}, {btnText: "test3"}]}
          
        }
        
    }

    
// Lifecycle events
render() {     
        this.envelopeList = this.state.cardArray.map((card, index) =>
            <EnvelopeDisplay key={index} cardDetails={card} index={index} 
                cardDisabled={this.state.cardDisabled}
                screenSize={this.props.screenSize}
                eventCategories={this.state.eventCategories}
                onClick={(_id) =>this.processNextCard(_id)}/>
        );
        this.nbrCards = 8;
        this.jobName="Electrical Engineer";
                
return <>
        <div className="mt-5 col-md-6">
        <div className="card mt-3 mr-3 rounded-corners-lg text-center game-board"
            style={{backgroundImage: `url(${process.env.PUBLIC_URL + this.backgroundURL})`}}></div>
            
                <GameCardFaceUp currentCard={this.state.currentCard}
                            screenSize="large"
                            onLastCard={(selectedType, activity) => this.lastCardDisplayed(selectedType, activity)}
                            onComplete={(selectedInd) => this.resolveDisplayedCard(selectedInd)}
                            calcResponseValue={(response) => this.calculateResponseValue(response)}
                            onResultDisplay = {() => this.onResultDisplay()}/>
                        
            
            
                    {this.envelopeList}

                    <EnvelopeLegend
                            eventCategories = {this.state.eventCategories}
                            jobName={this.jobName}
                        />
                
            
        </div>
</>
}

}


export default withRouter(TestSpinnerOption);