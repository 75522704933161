import {Component} from 'react';
import {Tooltip} from '@mui/material';
import "./GameViews/gameViews.css";
import "../special/Special/special.css";
import { cashStrMask } from './objects/game_view_mask_functions';
import { dateStrToPlainText } from './objects/game_view_mask_functions';


class CashMoodStatusBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
           };
    }

    render() { 
       
    return <>
        {dateStrToPlainText(this.props.gameDate)}
            <br />
            <Tooltip title="This is your Current Cash Amount." placement="bottom" arrow>
                <span>{cashStrMask(+this.props.currentCash)}</span>
            </Tooltip>
            <br />
        <div>
            <img src={this.props.moodEmoji}
                    className="smiley-face-position smiley-game-img" alt="smileyFaceEmoji" />
            <Tooltip title="This is your Mood Score." placement="bottom" arrow>
                <span>{this.props.currentMoodPoints}</span>
            </Tooltip>
        </div>
    </>
    }
  
}
 
export default CashMoodStatusBox;