const lastCardDataset =[
    {lastCardType:  "ActivityCard", 
        lastCardValues: {
            title: "What's Next?",
            description: "",
            lastCard: true,
            backgroundType: "home",
            color: "green",
            cardType: "Activity",
            eventCategory: "endTurn",
            lastCardButtons: [{ type: "Activity", btnText: "Side Hustle (Earn $)", activity: "SideHustle" },
            { type: "Activity", btnText: "Volunteer", activity: "Volunteer" },
            { type: "Activity", btnText: "Do Something Fun", activity: "HaveFun" },
            { type: "Activity", btnText: "Take a Vacation", activity: "Vacation" },
            { type: "Regular", btnText: "Continue the Game", activity: "" }]
        }  
    },
    {lastCardType: "NextTurn",
        lastCardValues: {
            title: "Next Turn",
            description: "Click the button below to move to the next month",
            lastCard: true,
            cardType: "card",
            eventCategory: "endTurn",
            backgroundType: "home",
            color: "blue",
            lastCardButtons: [{ type: "Regular", btnText: "OK", activity: "" }]
        }
    },

]

export default lastCardDataset;