import LinkTab from '../components/shared-components/link_tab';
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import React from 'react';
import {Link} from "react-router-dom";
import SpecialProcessMain from '../components/special/special_process_main';
import BackgroundPictureTest from '../components/special/background_picture_tester';
import TestActivities from '../components/special/test_activities';
import TestDialog from '../components/special/test_dialog';
import DisplayEvents from '../components/special/display_events';
import TestSpinnerOption from '../components/special/test_spinner_option';
import GameChoicePictures from '../pages/PageComponents/game_choice_pictures';
import SpinningBadge from '../components/game-views/spinning_badge';
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import {buildUrl } from '../objects/CommonUse/build_URL';
import Config from '../config';

class SpecialProcesses extends React.Component {
    constructor(props) {
        super(props);
          
    this.inputCompleted = (inputID) => {
        this.state.inputArr[inputID].completed = true;
        this.setState({inputArr: this.state.inputArr}); 
    };

    this.inputSelected = (inputID) => {
        for (const input of this.state.inputArr) {
            input.selected = false;
        }
        this.state.inputArr[inputID].selected = true;
        this.setState({inputArr: this.state.inputArr});  
       
    };

     this.state = {
        inputArr: [
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            
        ],
        inputCompleted: this.inputCompleted,
        inputSelected: this.inputSelected,
        gameData: null,
        gameDataSw: false,
        doneLoading: false,
        loading: true,
        data: null,
        fancyDragNDrop: true,
        openMapAnimation: true,
        screenSize: sessionStorage.getItem("GameScreenSize"),
        environment: Config.dataURL.currentEnv,
        gameInfo: {
            loading: true,
            gameData: {},
            cardList: [],
            currentCard: {},
            eventConditions: [],
            pictureInfo: "",
            savingsInfo: "",
            creditCardInfo: {},
            friendInfo: {},
            displayFriendInfo: {},
            activityTracker: {},
            creditCardLoaded: false,
            nextEventNbr: "",
            displayType: "none",   
        },
        specialInfo: {
            loading: true,
            loadingConditions: true,
            gameData: {},
            conditionsData: [],
            currentCard: "",
            cardList: [],
            cardIdx: 0,
            cardResponses: []
        }
    };

    switch(props.selected) {
        case "specialProcessMain":
            this.inputSelected(0);
            break;
        case "backgroundPictureTest":
            this.inputSelected(1);
            break;
        case "testActivities":
            this.inputSelected(2);
            break;
             
        case "testDialog":
            this.inputSelected(3);
            break;
        case "displayEvents":
            this.inputSelected(4);
            break;
        case "testSpinner":
            this.inputSelected(5);
            break;
     }
}

    render(){
       
    return <>
        <span className="container m-0 p-0">
                <span className="row w-100 mx-0">
        
                   {this.props.selected === "specialProcessMain" ? 
                        <SpecialProcessMain 
                            screenSize={this.state.screenSize}
                            specialInfo = {() => this.getSpecialInfo()} 
                            setSpecialInfo = {(value, propertyName) => this.setSpecialInfo(value, propertyName)}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            /> : <span/>}
                     {this.props.selected === "backgroundPictureTest" ?
                        <SpinningBadge
                            screenSize={this.state.screenSize}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            /> : <span/>}   
                     {/*<BackgroundPictureTest
                            screenSize={this.state.screenSize} */}                    
                     {this.props.selected === "testActivities" ? 
                        <TestActivities
                            screenSize={this.state.screenSize}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            /> : <span/>}
                    {this.props.selected === "testDialog" ? 
                        <TestDialog
                            screenSize={this.state.screenSize}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            /> : <span/>}
                    {this.props.selected === "displayEvents" ? 
                        <DisplayEvents
                            screenSize={this.state.screenSize}
                            specialInfo = {() => this.getSpecialInfo()} 
                            setSpecialInfo = {(value, propertyName) => this.setSpecialInfo(value, propertyName)}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            /> : <span/>}
                    {this.props.selected === "testSpinner" ? 
                        <TestSpinnerOption   
                        screenSize={this.state.screenSize}

                        onOpen ={()=> this.inputSelected(0)} onEnd={() => this.endGame()}
                        gameInfo = {() => this.getGameInfo()} 
                        setGameInfo = {(value, propertyName) => this.setGameInfo(value, propertyName)}
                        /> : <span/>}
                                                                                                                                                                     
                    <div className="col-sm-2 ml-5"> 
                        <Link to="special-process-main" onClick={() => this.handleClick(0)}>
                            <LinkTab inputArr={this.state.inputArr[0]} name="Special Main Page" validToComplete="true"
                               screenSize={this.state.screenSize}/>
                        </Link>

                        <Link to="background-picture-test" onClick={() => this.handleClick(1)}>
                                <LinkTab inputArr={this.state.inputArr[1]} name="Background Picture Test" validToComplete="true"
                                   screenSize={this.state.screenSize}/>
                        </Link>
                        <Link to="test-activities" onClick={() => this.handleClick(2)}>
                                <LinkTab inputArr={this.state.inputArr[2]} name="Test Activities" validToComplete="true"
                                   screenSize={this.state.screenSize}/>
                        </Link>
                        <Link to="test-dialog" onClick={() => this.handleClick(3)}>
                                <LinkTab inputArr={this.state.inputArr[3]} name="Test Dialog" validToComplete="true"
                                   screenSize={this.state.screenSize}/>
                        </Link>
                        <Link to="display-events" onClick={() => this.handleClick(4)}>
                                <LinkTab inputArr={this.state.inputArr[4]} name="Display Selected Events" validToComplete="true"
                                   screenSize={this.state.screenSize}/>
                        </Link>
                        <Link to="test-spinner" onClick={() => this.handleClick(5)}>
                                <LinkTab  inputArr={this.state.inputArr[5]} name="Test Spinner" validToComplete="true"
                                   screenSize={this.state.screenSize}/>
                        </Link>
                                         
                    </div>
                                   
                </span>
        </span>   
                  
    </>
}

handleClick = index => {
    this.state.inputSelected(index);
    this.index = index;
}


getGameInfo()
{
    return this.state.gameInfo;
}

setGameInfo(value, propertyName = null)
{
    if (propertyName)
    {
    this.state.gameInfo[propertyName] = value;
    this.setState({gameInfo: this.state.gameInfo});
    }
    else{ this.setState({gameInfo: value});
    }
         
}

getSpecialInfo()
{
    return this.state.specialInfo;
}

setSpecialInfo(value, propertyName = null)
{
    if (propertyName)
    {
    this.state.specialInfo[propertyName] = value;
    this.setState({specialInfo: this.state.specialInfo});
    }
    else{ this.setState({specialInfo: value});
    }
         
}

processErrorReturn(errorObj){
    this.addRecordToLogfile(errorObj);
    if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
        alert ("Uh oh!  Something unexpected has occurred " +
            "error has been logged and will be addressed. " +
            "For now click OK, then return to the process " +
            "where you were and try again.");
        this.props.history.push('/');
    }
}

addRecordToLogfile(errorObj){
    const headers=setHeadersForFetch();
    if (errorObj.status == 250){
        errorObj.message = "No record found";
    }
    let subProcess = "";  
    if (errorObj.subProcess == undefined){
        subProcess = "unknown"
    }else{
        subProcess = errorObj.subProcess;
    }
    if (errorObj.errorObject == undefined){
        errorObj.errorObject = {};
    }
    const logFileBody =  {
        mainProcess: "GameView",
        subProcess: subProcess,
        function: errorObj.function,
        status: errorObj.status,
        message: errorObj.message,
        errorObj: errorObj.errorObject
    }
    console.log("logFileBody is: ", logFileBody)
    const urlParm = "";
    const uri = buildUrl(this.state.environment, "saveLogFile", urlParm);
    fetch (uri,  
        {method:'POST',
            headers: headers,
            body: JSON.stringify(logFileBody)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        console.log("logfile create was successful");
                    }else{
                        console.log("Response from save log file not valid!" +
                         " status is: ", output.statusCode,
                         " message is: ", output.body);
                        alert ("Response from save log file not valid!");
                           
                    }
            });
        }
    });
  
}




}

export default SpecialProcesses;