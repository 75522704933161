import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DragNDropModal from './drag_n_drop_modal';
import LivingBelowChallenge1InitialText from '../../dragNDropText/living_below_challenge1_initial_text';
import LivingBelowChallenge1HintsText from './text/living_below_challenge1_hints_text';
import LivingBelowChallenge1Dataset from './Datasets/living_below_challenge1_dataset';
import LivingBelowChallenge2InitialText from '../../dragNDropText/living_below_challenge2_initial_text';
import LivingBelowChallenge2HintsText from './text/living_below_challenge2_hints_text';
import LivingBelowChallenge2Dataset from './Datasets/living_below_challenge2_dataset';
import { createColumnArray } from '../../objects/DragNDrop/createColumnArray_function';
import { calcColumnScore } from '../../objects/DragNDrop/calcColumnScore_function';
import { dragNDropBefore } from '../../objects/DragNDrop/drag_n_drop_before_function';
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';

class LivingBelowYourMeans extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            callRender: false,
            openSw: false,
        }
        this.currentStatus = "DisplayChallenge1";
        this.dragNDrop = {};
        this.modalMessage = "";
        this.hintTable = [];
        this.hintNbr = 0;
        this.displayAlert = false;
        this.tableObject = [];
        this.tableNbrRows = 0;
        this.freezeFlag = false;
        this.challenge1Score = 0;
        this.challenge2Score = 0;
        this.avergeScore = 0;
        this.col1Text = "";
    }

    // getting error: "findDOMNode is deprecated and will be removed 
    // in the next major release. Instead, add a ref directly to the element you want to reference"

    openLivingBelowChallenge1() {
        this.currentStatus = "OpenChallenge1";
        this.dragNDrop = dragNDropBefore("What are the Advantages of Living Below Your Means?",
            LivingBelowChallenge1InitialText, "", "", "",
            LivingBelowChallenge1Dataset, "LivingBelowChallenge1", 0);
      
        this.hintTable = LivingBelowChallenge1HintsText;     
        this.setState({openSw: true});
    }

    closeLivingBelowChallenge1() {
        this.currentStatus = "DisplayChallenge1"
        this.setState({openSw: false});
    }

    saveLivingBelowChallenge1() {
        var columnArray=createColumnArray(this.dragNDrop.cookieNamePfx, 2);
        if (columnArray[1].items.length === 0) {
            this.modalMessage = "Drag items from the Considerations column to the Advantages column."
        }else{
            this.challenge1Score = calcColumnScore(columnArray);
            if (this.challenge1Score > 90) {
                this.modalMessage = "";
                sessionStorage.setItem("livingBelowChallenge1Score", this.challenge1Score);
                this.currentStatus = "DisplayChallenge2";
                this.setState({openSw: false});
            } else {
                this.modalMessage= "Not quite right... Try again.";
                this.displayAlert = true;
                if (this.hintNbr < this.hintTable.length){
                    this.hintNbr += 1;
                }
                this.setState({callRender: true});
            }    
            
        }
    }
     
    openlivingBelowChallenge2() {
        this.currentStatus = "OpenChallenge2";
        this.modalMessage = "";
        this.dragNDrop = dragNDropBefore("Identify Ways to Live Below Your Means",
            LivingBelowChallenge2InitialText, "", "", "",
            LivingBelowChallenge2Dataset, "LivingBelowChallenge2", 99) 
        this.tableNbrRows = 0;
        this.hintTable = LivingBelowChallenge2HintsText; 
        this.setState({openSw: true});
    }

    closelivingBelowChallenge2() {
       this.currentStatus = "DisplayChallenge2";
       this.freezeFlag = false;
       this.setState({openSw: false});
    }

    savelivingBelowChallenge2() {
        var columnArray=createColumnArray(this.dragNDrop.cookieNamePfx, 3);
        this.challenge2Score = calcColumnScore(columnArray);
        if (this.challenge2Score > 85) {
            this.modalMessage = "";
            this.currentStatus = "DisplayChallenge2";
            this.finishLivingBelowYourMeans();
            this.setState({openSw: false});
        } else {
            this.modalMessage= "Not quite right... Try again.";
            this.displayAlert = true
            if (this.hintNbr < this.hintTable.length){
                this.hintNbr+=1;
            }
            this.setState({callRender: true});
        }   
    }

    getLivingBelowChallenge1Score() {
        var challenge1Score = parseFloat(sessionStorage.getItem("livingBelowChallenge1Score"));
        if (!isNaN(challenge1Score) && this.currentStatus === "DisplayChallenge1") {
           this.currentStatus = "DisplayChallenge2";
           this.challenge1Score = challenge1Score;
        }
    }
    
    closeAlert(){
        this.displayAlert = false;
        this.setState({callRender: true});
    }
    
    render() {
        this.getLivingBelowChallenge1Score();
        this.gutters = "";
        if (this.dragNDrop.modalSize === "xl" && 
            (this.props.screenSize === "narrow" || this.props.screenSize === "medium")){
             this.gutters = "modal-95";
        }
             
    return <>

        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true">
            <Modal.Header closeButton>
                <Modal.Title>Extra Credit - Living Below Your Means</Modal.Title> 
            </Modal.Header>
            <Modal.Body>
                {this.currentStatus === "DisplayChallenge1" ?
                     <span>
                        <div className="m-4">
                            <p>Living below your means is a phrase that means that you are making
                               more money than you spend.  For most individuals, it is best to practice
                               this strategy each month rather than waiting for the end of the year.
                               It is fairly easy to determine if you are living below your means for a
                               given month.  Just subtract your average monthly expenses from your
                               take-home pay. </p>
                               <p></p> 
                               <p>Click on the "Challenge1" button to learn more and check
                               your understanding.</p>
                        </div>
                                                    
                        <div className="text-center">
                            <button className="btn btn-primary w-75 mt-4" 
                            onClick ={()=> this.openLivingBelowChallenge1()}>
                                Living Below Your Means Challenge #1
                            </button>
                        </div>
                    </span>
                :
                    <div></div>
                }
                {this.currentStatus === "OpenChallenge1" ?
                    <DragNDropModal show={this.state.openSw} 
                        gutters={this.gutters}
                        onClose={() => this.closeLivingBelowChallenge1()} 
                        onSave={() => this.saveLivingBelowChallenge1()}
                        closeAlert={() => this.closeAlert()}
                        inputDataset={this.dragNDrop.inputData}
                        modalSize={this.dragNDrop.modalSize}
                        modalTitle={this.dragNDrop.modalTitle}
                        introText={this.dragNDrop.introText}
                        cookieNamePfx={this.dragNDrop.cookieNamePfx}
                        buttonType={this.dragNDrop.buttonType}
                        message={this.modalMessage}
                        hintTable={this.hintTable}
                        hintNbr={this.hintNbr}
                        displayAlert={this.displayAlert}
                    />
                :
                    <div></div>
                }
           
                {this.currentStatus === "DisplayChallenge2" ?
                    <span>
                         <div className="m-4" >
                             <p>Living below your means can be pretty challenging.  Click on 
                                the "Challenge2" button to identify things that you should do 
                                and things that you should not do if you wish to spend less 
                                than you make each month.
                                </p>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary w-75 mt-4" 
                            onClick ={()=> this.openlivingBelowChallenge2()}>
                                Living Below Your Means Challenge #2
                            </button>
                        </div>
                    </span>
                :
                    <div></div>
                }
                {this.currentStatus === "OpenChallenge2" ?
                    <DragNDropModal show={this.state.openSw} 
                        gutters={this.gutters}
                        onClose={() => this.closelivingBelowChallenge2()} 
                        onSave={() => this.savelivingBelowChallenge2()}
                        onContinue={() => this.finishChallenge2()}
                        closeAlert={() => this.closeAlert()}
                        inputDataset={this.dragNDrop.inputData}
                        modalSize={this.dragNDrop.modalSize}
                        modalTitle={this.dragNDrop.modalTitle}
                        introText={this.dragNDrop.introText}
                        cookieNamePfx={this.dragNDrop.cookieNamePfx}
                        maxNbr={this.dragNDrop.maxNbrPerColumn}
                        buttonType={this.dragNDrop.buttonType}
                        message={this.modalMessage}
                        tableNbrRows={this.tableNbrRows}
                        tableCol1Text={this.state.col1Text}
                        tableObjectRow={this.tableObject}
                        freezeFlag={this.freezeFlag}
                        hintTable={this.hintTable}
                        hintNbr={this.hintNbr}
                        displayAlert={this.displayAlert}
                        />
                :
                    <div></div>
                }
            </Modal.Body>
            <Modal.Footer className="text-right">
                <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"
                    alt="train"/>
                <Button variant="secondary"   onClick={() => this.props.onClose()}>
                    Close
                </Button>
          </Modal.Footer>
          </Modal>             
            
        </>
    }

    finishLivingBelowYourMeans() {
        this.averageScore = (+this.challenge1Score +  +this.challenge2Score) / 2; 
        this.calculateLivingBelowPoints();
        sessionStorage.setItem("previousExtraCreditProcess", "Living Below Means");
        this.props.onCompleted(); 
    }

    calculateLivingBelowPoints(){
        var earnedAmt = 0;
        let processName = "LivingBelowYourMeans";
        let earningsObj = ExtraCreditConfigFile.exercises.find(elem => elem.category === processName);
        earnedAmt = ((this.averageScore * +earningsObj.amt)/100).toFixed(2);
       
        const livingBelowMeansObj = {processName: processName, score: this.averageScore,
            assetType: earningsObj.type, amount: earnedAmt, level: earningsObj.level}
          
        sessionStorage.setItem("extraCreditCompleted", JSON.stringify(livingBelowMeansObj));
    }

}


export default LivingBelowYourMeans;