import { doubleToStringMonthly } from "../../../objects/CommonUse/double_functions";
import { applyToCreditCard } from "./apply_to_credit_card";
import { checkIfCreditCard } from "./check_if_credit_card";

export const updateRecurringGameDataObjects = (objectType, objectName, updateType, updateValue, duration,
    objectDesc, replaceAmtSw, objectTable, currentCashAmt, currentGameDate, 
    creditCardInfo, intRate, loanTerm, totalLoanAmt, downPayment) => {

    var objectFound = false;
    var itemType = "";
    var itemDesc = "";  
    var itemAmt = 0;
    var itemNbrMonths = 0;
    var itemStartDate = 0;
    var newSalaryAmt = 0;
    var newCashAmt = currentCashAmt; 
    var cashOrCredit = "";
    var spendAmt = 0;
    var objIndx;
   
    switch (objectType) {
        case "Asset": {
            objIndx = objectTable.findIndex(elem => elem.assetItem === objectName);
            if (objIndx !== -1){
                itemType = objectTable[objIndx].assetItem;
                itemDesc = objectTable[objIndx].assetDesc;
                itemAmt = objectTable[objIndx].assetAmt;
                itemNbrMonths = objectTable[objIndx].assetNbrMonths;
                itemStartDate = objectTable[objIndx].assetStartDate;
                objectFound = true;
            }
            break;
        }
        case "Expense": {
            if (objectName === "Misc Expenses"){
                objIndx = objectTable.findIndex(elem => elem.expItem === objectName &&
                        elem.expDesc ===  objectDesc );
            }else{
                objIndx = objectTable.findIndex(elem => elem.expItem === objectName);
            }
            if (objIndx !== -1){
                itemType = objectTable[objIndx].expItem;
                itemDesc = objectTable[objIndx].expDesc;
                itemAmt = objectTable[objIndx].expAmount;
                itemNbrMonths = objectTable[objIndx].expNbrMonths;
                itemStartDate = objectTable[objIndx].expStartDate;
                objectFound = true;
            }
            break;
        }
        case "Loan": {
            objIndx = objectTable.findIndex(elem => elem.loanItem === objectName );
            if (objIndx !== -1){
                itemType = objectTable[objIndx].loanItem;
                itemDesc = objectTable[objIndx].loanDesc;
                itemAmt = objectTable[objIndx].loanAmt;
                itemNbrMonths = objectTable[objIndx].loanNbrMonths;
                objectFound = true;
            }
            break;
        }
        default:{
            break;
        }
    }

    if (updateType === "percent")
    {
        updateValue = (+itemAmt * +updateValue / 100).toFixed(2);
    }
    let chargeItemToCreditCard = checkIfCreditCard(updateValue, creditCardInfo, objectName, objectType);
       
    if (objectFound === true && itemNbrMonths != 0 && itemStartDate <= currentGameDate){
        // Determine exact update value if not flat
      
        if (duration === "-1"){
            // If duration is -1 (permanent), update the object directly
            if (replaceAmtSw == true){
                itemAmt = +updateValue;
                itemDesc = objectDesc;    
                if (+itemAmt === 0){
                    itemNbrMonths = "1";
                }                
            }else{
                itemAmt = (+itemAmt + +updateValue).toFixed(2);
                if (itemType === "Job Salary") {
                    newSalaryAmt = +itemAmt;
                    let salaryDescString =  doubleToStringMonthly((+itemAmt * 12).toFixed(0));
                    let initialStringLength = +itemDesc.search("Annual Salary") + 15;
                    let newItemDesc = itemDesc.substr(0, initialStringLength) + salaryDescString; 
                    itemDesc = newItemDesc;  
                }
            } 
            if (objectType === "Asset"){
                objectTable[objIndx].assetDesc = itemDesc;
                objectTable[objIndx].assetAmt = itemAmt;
                objectTable[objIndx].assetNbrMonths = itemNbrMonths;
            }
            if (objectType === "Expense"){
                objectTable[objIndx].expDesc = itemDesc;
                objectTable[objIndx].expAmount = itemAmt;
                objectTable[objIndx].expNbrMonths = itemNbrMonths;
            }
            if (objectType === "Loan"){
                objectTable[objIndx].loanDesc = itemDesc;
                objectTable[objIndx].loanAmt = itemAmt;
                objectTable[objIndx].loanNbrMonths = itemNbrMonths;
            }
        }else{ 
            if (duration === "1"){
                //  this is done because, by the time it applies credit card amounts, these items
                //  will be deleted
                spendAmt = +updateValue * -1;
                if (chargeItemToCreditCard == true && +updateValue < 0){
                    creditCardInfo = 
                        applyToCreditCard(creditCardInfo, updateValue, objectDesc, objectType, "planned");
                    cashOrCredit = "credit";
                }else{
                    newCashAmt = +currentCashAmt + +updateValue;
                    cashOrCredit = "cash";
                }
            }else{
                //  object found s/b false if not a permanent item...  must add a new object!!
                objectFound = false;
            }
        }
    }
           
    var newMoneyObj;
    if (objectFound === false){
        switch (objectType){
            case "Asset":{
                newMoneyObj = {
                    assetItem: objectName,
                    assetDesc: objectDesc,
                    assetAmt: updateValue,
                    assetStartDate: currentGameDate,
                    assetNbrMonths: duration
                }
                break;
            }
            case "Expense":{
                newMoneyObj = {
                    expItem: objectName,
                    expDesc: objectDesc,
                    expAmount: updateValue,
                    expStartDate: currentGameDate,
                    expNbrMonths: duration,
                    chargeCreditCard: chargeItemToCreditCard
                }
                break;
            }
            case "Loan": {
                newMoneyObj = {
                    loanType: objectName,
                    loanDesc: objectDesc,
                    loanAmt: updateValue,
                    loanStartDate: currentGameDate,
                    loanNbrMonths: +duration,
                    loanTotAmt: totalLoanAmt,
                    loanIntRate: intRate,
                    loanTerm: +loanTerm,
                }
                break;
            }
            default: {
                newCashAmt = +currentCashAmt + +downPayment;
                cashOrCredit = "cash";
                break;
            }
        }
        objectTable.push(newMoneyObj);
    }

    let returnObj = {objectTable: objectTable, newCashAmt: newCashAmt, newSalaryAmt: newSalaryAmt,
        creditCardInfo: creditCardInfo, applyType: cashOrCredit, spendAmt: spendAmt};
            
    return returnObj;
   
}





