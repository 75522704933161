const friendHaveFunActivityDetailDialogDataset = [

        {activityPreference: "2", activityName: "default",
                comments: ["This place is OK, but I've definitely seen better.",
                           "I hate to say it, but I'm kind of getting bored.",
                           "Ok, well this is sort of lame don't you think?",
                           "I can't believe that other people think this is fun.",
                           "Do you think we could leave soon?", 
                ]},
        {activityPreference: "3", activityName: "default",
                comments: ["Wow!  This is really fun don't you think?", 
                           "I can't believe the time is going by so quickly.", 
                           "This is really great and fun to share it with a friend!",
                           "This might be a little expensive, but it's worth every penny!!",
                           "Thank you so much for inviting me.  This is really fun!",
                ]},
        {activityPreference: "4", activityName: "default",
                comments: ["This is a lot of fun!",
                           "I can't remember when I've had so much fun!!",
                           "I wish this would go on forever!",
                           "I hope you are enjoying this as much as I am!!",
                           "It would be really fun to do this again some time."
                ]},
              
        {activityPreference: "2", activityName: "Skiing",
                comments: ["It is really cold out here.  I know it's early, but can we just call it a day?",
                           "My ski boots are really hurting my feet.",
                           "You ski way too fast, I really can't keep up.  Would you slow down please?",
                           "I am really not having fun.  I keep falling down.",
                           "Would you please just stick to the green slopes?  I like to stay on the easy ones.", 
                ]},
        {activityPreference: "3", activityName: "Skiing",
                comments: ["When we get to the top, just look around, it is so beautiful up here.",
                           "Boy, it's a bit chilly today, but I really don't want this day to end.",
                           "Wow!  I can't believe the snow today, 6 inches of fresh powder... WOOHOO!",
                           "There are a lot of people out here today.  Let's go find a lift with shorter lines.",
                           "I really like skiing the steep trails here, don't you?", 
                ]},
        {activityPreference: "4", activityName: "Skiing",
                comments: ["I really love skiing, don't you?",
                           "This is such a wonderful day for skiing.  Blue skies and great snow!",
                           "Do you want to race me down the hill on the next run?",
                           "I wish I could ski everyday, don't you?",
                           "Well, the wind is a bit chilly today, but I am having so much fun, I hardly notice.", 
                ]},

        {activityPreference: "2", activityName: "GoToZoo",
                comments: ["Ugh!  Can you smell that?  These animals really stink.",
                           "This is no fun, it is rainy today so all the animals are sleeping or inside.",
                           "I used to really like snakes and reptiles, but now they are sort of boring.",
                           "Can we leave soon?  I know it's early, but I think we've seen enough. Don't you?",
                           "I tried to take a selfie with a gorilla, but he just turned his back on me... drat!", 
                ]},
        {activityPreference: "3", activityName: "GoToZoo",
                comments: ["I love going to the 'primate house' and watching the monkeys and gorillas.",
                           "What a great day to visit the zoo.  It is a little rainy so nobody is here and we can see all the animals!",
                           "I love the elephants, the baby elephants are so cute.  What is your favorite animal?",
                           "I'm really excited to take a selfie with a tiger... wouldn't that be cool?",
                           "Did you see that gorilla run down and pound the glass?  It really shook the glass.  I was afraid it might break.", 
                ]},
        {activityPreference: "4", activityName: "GoToZoo",
                comments: ["I love the zoo.  Everytime I visit, it is a different experience.",
                           "I noticed that they have a wild bird demonstration today.  That should be really cool!",
                           "I think that Lions are really cool.  In fact, they are the 'mane event' right?",
                           "I've never seen a snow leopard before, they are really neat!",
                           "This is so great!  You know zoos are working really hard to keep animals from going extinct.  So Cool!", 
                ]},

        {activityPreference: "2", activityName: "ComedyClub",
                comments: ["OK.  I thought this was supposed to be funny.  I'm really not laughing.",
                           "Do you want to leave now?  I really don't like this comedian.  They are not funny.",
                           "I can't seem to stay awake.  Can we leave soon?",
                           "Boy, this was expensive.  I thought the jokes would be better, didn't you?",
                           "I knew this was a bad idea.  I went to one of these clubs before and it was bad, " +
                               "but I thought this might be better.", 
                ]},
        {activityPreference: "3", activityName: "ComedyClub",
                comments: ["This is so much fun!  I can't stop laughing.",
                           "I wish I could record this.  Then I could watch it over and over again and laugh all the time.",
                           "This is so much fun!  I would love to come with you again sometime, I love having a good reason to laugh.",
                           "I thought this sounded a little expensive, but I'm really glad that I came along.  Worth every penny.",
                           "Oh that was so funny.  I can totally relate to the story!", 
                ]},
        {activityPreference: "4", activityName: "ComedyClub",
                comments: ["Thanks for inviting me!  I love this place, the comedians are really funny.",
                           "I don't remember ever laughing so much.  This is a ton of fun!",
                           "Have you ever thought of being a comedian?  I think it would be fun to be able to make people laugh.",
                           "I've never been to a comedy club before, but I am definitely coming back!",
                           "Hey this is really fun!  I'm so glad I came along, thank you.", 
                ]},

        
        {activityPreference: "2", activityName: "Circus",
                comments: ["This is pretty lame.  My parents took me to a circus performance when I was younger. " +
                                "It was way better than this.",
                           "I thought the lion and tiger show would be exciting, but it was pretty boring.",                                                      
                           "Man, I just dropped my popcorn and it was almost full.  That's a bummer!!",
                ]},
        {activityPreference: "3", activityName: "Circus",
                comments: ["I really like the circus, especially the flying trapeeze!",
                           
                ]},
        {activityPreference: "4", activityName: "Circus",
                comments: ["This circus is so cool!  I can't wait to see the clown acts, they are so funny."

                ]},
        
        {activityPreference: "2", activityName: "Rafting",
                comments: ["I sure hope I don't fall out of the raft.  I don't really swim very well.",
                           "I am really cold.  It is cloudy and I keep getting splashed by the water.",                          
                           "Oh dear.... did you see that, we almost crashed into a rock.  I hope the person " +
                                "steering this thing knows what they are doing.",
                ]},
        {activityPreference: "3", activityName: "Rafting",
                comments: ["This is so much fun!  Those rapids were really a cool!",
                           "The river is really full now.  That means there will be a lot of rapids. WOOHOO!"
                           
                ]},
        {activityPreference: "4", activityName: "Rafting",
                comments: ["I am so glad that you asked me to go rafting with you.  I've always wanted to try it."

                ]},


]
 
export default friendHaveFunActivityDetailDialogDataset;