//import { Email } from '@material-ui/icons';
import { withRouter } from "react-router";
import {Component, useState} from 'react';
import RegisterForm from './register_form';
import ForgotPassword from './forgot_password';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import buildUrl from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import reduxSetGameInfo from "../../pages/Objects/redux_set_game_info";
 

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processType: "",
            loginLoaded: false,
            userVerifyLoaded: false,
            accountLoaded: false,
            environment: Config.dataURL.currentEnv,
            signonType: "",
        };
        this.processRequested = "LoginForm";
        this.subProcess = "LoginForm";
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
    }

    render() { 
        if (this.state.loginLoaded === true && this.processRequested === "LoginForm"){
            this.processSubmit();  
        }
         
        return <>
            {this.processRequested == "LoginForm" ?
            <form className="login-form px-2 pb-2" id="loginForm" onKeyPress={this.handleKeyPress}>
                <div className="font-weight-bold mb-2">Must be logged in to access this selection:</div>
                <div className="form-group text-left">
                    <label className="font-weight-bold">Username</label>
                    <input type="username" className="form-control" id="usernameInput" size="50"
                        placeholder="Enter username"/>
                </div>
                <div className="form-group">
                    <label className="font-weight-bold">Password</label>
                    <input type="password" className="form-control" id="passwordInput" size="50"
                        placeholder="Enter password"/>
                </div>
                <div className="row">
                    <button type="button" className="btn btn-primary ml-4" 
                        onClick={()=>this.handleSubmit()}>Log in</button>
                    <span className="text-pointer ml-4">
                        <span onClick={()=>this.handleForgotPassword()}>Forgot Password</span>
                    </span>
                </div>
                <div className="text-danger font-weight-bold">{this.errorMessage}</div>
                <div>
                    <div className="text-center lighter-blue font-weight-bold">
                        -------------  OR  -----------------
                    </div>
                    <div className="font-weight-bold ml-2">
                        If you do not currently have a SignOn for the game...
                    </div>
                    <div className="mt-2">
                        <button type="button" className="ml-2 btn btn-info" 
                            onClick={()=>this.handleRegister("individual")}>
                               &nbsp; Create Individual SignOn &nbsp;</button>
                    </div>
                    <div className="mt-2">
                        <button type="button" className="ml-2 btn btn-info" 
                            onClick={()=>this.handleRegister("group")}>
                                Create Class/Family SignOn</button>
                    </div>
                </div>
            
            </form>
            :
                null
            }
            {this.processRequested == "RegisterForm" ?
                <RegisterForm
                    signonType={this.state.signonType}
                    registerComplete={() => this.confirmLogin()}
                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                />    
           
            :
                null
            }
        
           {this.processRequested == "ForgotPassword" ?
            <span>
                 {this.state.userVerifyLoaded  ?
                    <ForgotPassword
                        loginLoaded={this.state.loginLoaded}
                        emailAddress={this.emailAddress}
                        username={this.username}
                        userVerifyInfo={this.userVerifyInfo}
                        passwordResetComplete={() => this.reopenLoginForm()}
                    />
                : 
                    <div className="forgot-password-form px-2 pb-2"> 
                        <h4 className="text-center font-weight-bold">Reset User Password</h4>
                            <WaitTimeSpinner/>
                    </div>
                }
            </span>
            :
                null
            }

            </>
    }
    
    componentWillUnmount(){
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    handleSubmit()
    {
        this.errorMessage = "";
        this.username = document.getElementById("usernameInput").value;
        if (this.username === "") document.getElementById("usernameInput").classList.add("is-invalid");

        this.password = document.getElementById("passwordInput").value;
        if (this.password === "") document.getElementById("passwordInput").classList.add("is-invalid");

        if (this.password === "" || this.username === "") return;
        this.rqstType = "VerifyUserPassword";
        this.getLogin();
        this.state.processType = "submit";
    }    

    clearErrorMsg(){
        this.errorMessage = "";
    }
    
    processSubmit(){
        if (this.loginInfo.message != undefined && this.loginInfo.message === "Authentication failed")
        {
            this.errorMessage = "Invalid Username or Password";
        }else{
            this.setSessionStorage();
          //  the following call causes an error - trying to modify state within render
            this.props.onSuccessfulLogin(this.loginInfo.username, this.loginInfo.role);
            this.props.history.push('/');
        }
    }

    setSessionStorage(){
        sessionStorage.setItem('username', this.loginInfo.username);
        sessionStorage.setItem('userRole', this.loginInfo.role);
        sessionStorage.setItem('userAccountNbr', this.loginInfo.accountNbr);
        reduxSetGameInfo("SETINFO", this.loginInfo.role, "playerRole");
    }

    handleKeyPress(e)
    {
        if(e.key === "Enter")
        {
            this.handleSubmit();
        }
    }
    
    handleRegister(signonType){
        this.setState({true: this.state.test});
        this.state.signonType = signonType;
        this.processRequested = "RegisterForm";
    }

    handleForgotPassword(){
        
        this.username = document.getElementById("usernameInput").value;
        if (this.username === ""){ 
            document.getElementById("usernameInput").classList.add("is-invalid");
        }else{
            this.setState({true: this.state.test});
            this.rqstType = "UserEmail";
            this.getLogin();
            this.processRequested = "ForgotPassword";
        }
    }

    confirmLogin(){
        const credentials = JSON.parse(sessionStorage.getItem("credentials"));
        if (credentials !== null){
              this.rqstType = "VerifyUserPassword";
              this.processRequested = "LoginForm";
              this.username = credentials.username;
              this.password = credentials.password;
              this.getLogin();
        }else{
            alert ("oops!  The username and password were not saved successfully. " +
               "Please try again.")
            this.processRequested = "LoginForm";
            this.state.loginLoaded = false;
            this.setState({"LoginForm": this.processRequested});
        }
    }
     
    getLogin(){
        this.state.loginLoaded = false;
        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + Buffer.from(this.username + ":" + this.password).toString('base64'));
        const funcName = "loginChk";
        const urlParm = "?rqstType=" + this.rqstType;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET',
             headers: headers
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            this.loginInfo = output.body;
                            if (this.loginInfo.userToken != undefined){
                                const token = this.loginInfo.userToken;
                                sessionStorage.setItem('sessionToken', JSON.stringify(token));
                            }
                            if (this.loginInfo.accountNbr != "Individual" &&
                                this.loginInfo.message != "Authentication failed")
                            {
                                this.getAccount();
                            }else{
                                this.accountType = "player";
                                sessionStorage.setItem("accountType", this.accountType);
                            }
                            
                            if (this.rqstType == "UserEmail"){
                                this.emailAddress = this.loginInfo.emailAddress;
                                this.getVerifyCode();
                            }
                            this.state.loginLoaded = true;
                            this.setState({loginLoaded: true});
                        }else{
                            const parmObj={rqstType: this.rqstType, username: this.username};
                            const errorObj={function: funcName, subProcess: this.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: parmObj};
                            this.processErrorReturn(errorObj);
                        }
                });
            }
        });
    }
    
    getAccount(){
        this.state.nbrReadsRqstd += 1;
        this.state.accountLoaded = false;
        this.state.getAccount = true;
        let rqstType = "Validate";
        var headers = "";
        headers = new Headers();
        headers.set('Authorization', 'Basic ' + Buffer.from(this.username + ":" + this.password).toString('base64'));
        this.state.loginLoaded = false;
        const funcName = "loadAccount";
        let urlParm = "";
        urlParm = "?accountNbr=" + this.loginInfo.accountNbr + "&rqstType=" + rqstType + "&tokenType=TempToken";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET',
             headers: headers
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            this.state.accountLoaded = true;
                            this.accountInfo = output.body;
                            this.determineAccountType();
                            this.setState({accountFound: true});
                        }else{
                            if (statusCode == 250){
                                this.state.accountFound = false;
                                this.accountType = "NotFound";
                                sessionStorage.setItem("accountType", this.accountType);
                            }else{
                                const errorObj={function: funcName, subProcess: this.subProcess, 
                                    status: statusCode, message: output.body.message};
                                this.processErrorReturn(errorObj);
                            }
                        }
                        this.setState({accountLoaded: true});
                
                });
            };
        });
    }
    
determineAccountType(){
    for (let i=0; i<this.accountInfo.rqstdUsersArr.length; i++){
         let userObj = this.accountInfo.rqstdUsersArr[i];
         if (userObj.role == "teacher" || userObj.role == "parent"){
              this.accountType = userObj.role;
              sessionStorage.setItem("accountType", this.accountType);
              break;
         }
    }
}
    
getVerifyCode(){
    let headers = setHeadersForFetch();
    const funcName = "loadUserVerify";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'GET',
         headers: headers
        })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.userVerifyInfo = output.body;
                    this.setState({userVerifyLoaded: true});
                }else{
                    if (statusCode == 250){
                        this.userVerifyInfo = "";
                        this.setState({userVerifyLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                }
                this.setState({true: this.state.userVerifyLoaded});
            });
        }
    });
}
 
processErrorReturn(errorObj){
    this.addRecordToLogfile(errorObj);
    if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
        alert ("Uh oh!  Something unexpected has occurred " +
            "error has been logged and will be addressed. " +
            "For now click OK, then return to the process " +
            "where you were and try again.");
        this.props.history.push('/');
    }
}

addRecordToLogfile(errorObj){
    console.log("errorObj is: ", errorObj);
    if (errorObj.status == 250){
        errorObj.message = "No record found";
    }
    let urlParm = "";
    let headers = "";
    const authToken = JSON.parse(sessionStorage.getItem('sessionToken'));
    if (authToken == null){
        headers = new Headers();
        headers.set('Authorization', 'Basic ' + Buffer.from(this.username + ":" + this.password).toString('base64'));
        urlParm = "?tokenType=TempToken";
    }else{
        headers = setHeadersForFetch();
    }  
    if (errorObj.errorObject == undefined){
        errorObj.errorObject = {info: "no error object provided"};
    }
    const logFileBody =  {
        mainProcess: "login",
        subProcess: errorObj.subProcess,
        function: errorObj.function,
        status: errorObj.status,
        message: errorObj.message,
        errorObj: errorObj.errorObject
    }
    console.log("logFileBody is: ", logFileBody);
    let funcName = "saveLogFile"; 
   
    const uri = buildUrl(this.state.environment, funcName, urlParm)
    fetch (uri,  
        {method: 'POST', 
         headers: headers, 
         body: JSON.stringify(logFileBody)}
        )
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        console.log("logfile create was successful");
                    }else{
                        alert ("Response from save log file not valid!" +
                            " status is: " + statusCode +
                            " message is: " + output.body.message)
                       
                    }
            });
        }
    });
  
}

   
}

export default withRouter(LoginForm);