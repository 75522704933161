import {Tooltip} from "@mui/material";

function LinkTab(props) {
    var cardClass = "card mt-3";
    var textClass = "text-center primary-blue ";
    var bodyClass = "card-body rounded-corners-md";
    let tooltip = false;
    if (props.tooltip !== undefined){
       tooltip = props.tooltip;
    }

    switch (props.screenSize){
        case "medium":
            textClass = textClass + " small-text-2 px-0";
            cardClass = cardClass + " rounded-corners-md";
            break;
        case "narrow":
            bodyClass = bodyClass + " px-0";
            textClass = textClass + " very-small-text";
            cardClass = cardClass + " rounded-corners-sm";
            break;
        default:  //  the following is intended for large screens
            cardClass = cardClass + " rounded-corners-md";
            break;
    }
    if (props.validToComplete) {
     
        if (props.inputArr.completed == true){
            cardClass = cardClass + " bg-accent";
        }
        if (props.inputArr.selected == true){
            cardClass = cardClass + " link-tab-selected";
        }else{
            cardClass = cardClass + " link-tab";
        }
      
    }else{
        cardClass= cardClass + " bg-secondary rounded-corners-md link-tab"
    }
 
    return <>
        {tooltip ?
            <Tooltip title={props.tooltipText} arrow>
            <div className={cardClass}>
                <div className = "card-body rounded-corners-md px-0">
                    <h5 className={textClass}>{props.name}</h5>
                </div>
            </div>
            </Tooltip>
        :
            <div className={cardClass}>
                <div className = "card-body rounded-corners-md px-0">
                <h5 className={textClass}>{props.name}</h5>
             </div>
            </div>
        }
               
    </>
  

}

export default LinkTab;