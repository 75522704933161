import {Component, useState} from 'react';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import editPassword from '../../objects/CommonUse/edit_password';
import PasswordChecklist from "react-password-checklist"
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import reduxSetGameInfo from '../../pages/Objects/redux_set_game_info';

class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountLoaded: false,
            loginLoaded: false,
            userCountLoaded: false,
            username: "",
            password: "",
            confirmPassword: "",
            test: false,
            openModal: false,
            getLogonList: false,
            headers: setHeadersForFetch(),
            subProcess: "RegisterForm",
            environment: Config.dataURL.currentEnv,
        };
        this.errorTable = [];
        this.userEdited = false;
        this.accountEdited = false;
        this.waiting = false;
        this.errorFound = false;
        this.firstTime = true;
        this.saveLogin = false;
        this.editingComplete = false;
        this.resetErrorTable();
    }

   //   this is the full set of options for the password checker - have turned off several in this component
   // <PasswordChecklist
   //       rules={["minLength","specialChar",
   //             "number","capital","match"]}
   //               minLength={5}
   //               value={password}
   //               valueAgain={passwordAgain}
   //               />

    checkCurrentStatus(){
        if (this.editingComplete === false){
            this.waiting = true;
            if (this.props.signonType === "group"){
                if (this.state.userCountLoaded === true){
                    this.editAccountInfo();
                }
            }else{
                this.accountEdited = true;
            }
            if (this.state.loginLoaded === true){
                this.editUsername();
            }
            if (this.userEdited === true && this.accountEdited === true){
                this.waiting = false;
                if (this.errorFound === true){
                    this.errorMessage = "Please correct errors and resubmit."
                }
            }
        }else{
            this.waiting = false;
        }
        if (this.accountEdited === true && this.userEdited === true &&
            this.errorFound === false){
            this.editingComplete = true;
            if (this.saveLogin === false){
                this.waiting = true;
                this.saveLoginUser();
                this.saveLogin = true;
            }
        }
    }

    render() { 
    //    if (this.errorTable.length === 0){
    //        this.resetErrorTable(); 
    //    }
        if (this.firstTime === false){
            this.checkCurrentStatus();
        }
        
        return <>
            <form className="register-form px-2 pb-2" id="registerForm"  >
                <h4 className="text-center font-weight-bold">Create New Signon (Free)
                    <img className="x-picture-size ml-5" src={'./assets/x-close.png'} alt="X"
                        onClick={()=>this.closeReleaseForm()}></img>
                </h4>
                {this.props.signonType == "group" ?
                    <span>
                        <div className="font-weight-bold m-2">
                             Use this to create a signon that is associated with
                             a teacher (class) or parent (family).
                        </div>
                        <div className="form-group text-left">
                            <label className={this.errorTable[0].class}>Account Number</label>
                            <input type="text" className="form-control" id="accountNbr" 
                                placeholder="AccountNbr"/>
                            <div className="text-danger">{this.errorTable[0].message}</div>
                        </div>
                    </span>
                :
                   <div className="font-weight-bold m-2">
                        Use this to create a signon that is <u>not</u> associated 
                        with a teacher (class) or parent (family).
                    </div>
                }
                <div className="form-group text-left">
                    <label className={this.errorTable[1].class}>New Username</label>
                    <input type="text" className="form-control" id="newUsername" 
                        placeholder="Preferred username"/>
                    <div className="text-danger">{this.errorTable[1].message}</div>
                </div>
                <div className="form-group text-left mb-0">
                    <label className={this.errorTable[2].class}>New Password</label>
                    <input type="password" className="form-control" id="newPassword" 
                        placeholder="Password"
                        onChange={(val) => this.setPassword(val.target.value)}/>
                    <div className="text-danger">{this.errorTable[2].message}</div>
                </div>
                <div className="form-group text-left mb-0">
                    <label className={this.errorTable[3].class}>Confirm Password</label>
                    <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm password"
                        onChange={(val) => this.setConfirmPassword(val.target.value)}/>
                    <div className="text-danger">{this.errorTable[3].message}</div>
                </div> 
                <PasswordChecklist
                        rules={["minLength","specialChar",
                            "match"]}
                        minLength={8}
                        value={this.state.password}
                        valueAgain={this.state.confirmPassword}
                    />
                {this.props.signonType == "group" ?
                    <span>
                    <div className="mt-3">
                        <label className={this.errorTable[4].class}>Role in Game:&nbsp;&nbsp;</label>
                        <select 
                            className="btn btn-sm btn-outline-secondary dropdown-toggle" id="gameRole">
                            <option value="none">No Role Selected</option>
                            <option value="player">Player</option>
                            <option value="parent">Parent</option>
                            <option value="teacher">Teacher</option>
                        </select>
                        <div className="text-danger">{this.errorTable[4].message}</div>
                    </div>
                    <div className="form-group text-left mb-0">
                        <label className={this.errorTable[5].class}>Auth Code</label>
                        <input type="text" className="form-control" id="authCode" placeholder="Auth Code"/>
                        <div className="text-danger">{this.errorTable[5].message}</div>
                    </div>
                    </span>
                :
                    null
                }
                <div className="form-group text-left mb-0">
                    <label className={this.errorTable[6].class}>
                        Email Address (Used For Password Reset)</label>
                    <input type="text" className="form-control" id="emailAddress" placeholder="emailAddress"/>
                    <div className="text-danger">{this.errorTable[6].message}</div>
                </div>
                <div className="form-group my-2 text-right">
                    <button type="button" className="btn btn-primary" onClick={()=>this.submitNewId()}>Submit</button>
                    <button type="button" className="ml-2 btn btn-primary" onClick={()=>this.closeReleaseForm()}>Close</button>
                </div>
                {this.errorFound ?
                    <div className="text-danger">{this.errorMessage}</div>
                :
                    null
                }
                {this.waiting ?
                    <WaitTimeSpinner/>
                :
                    null
                }
            </form>
        </>
    }

    componentWillUnmount(){
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    setSessionStorage(){
    //    sessionStorage.setItem('sessionToken', JSON.stringify(this.loginInfo.userToken));
        sessionStorage.setItem('username', this.loginInfo.username);
        sessionStorage.setItem('userRole', this.loginInfo.role);
        sessionStorage.setItem('userAccountNbr', this.loginInfo.accountNbr);
        reduxSetGameInfo("SETINFO", this.loginInfo.role, "playerRole")
    }

    submitNewId(){
        this.firstTime = false;
        if (this.props.signonType == "group"){
            let accountNbr = document.getElementById("accountNbr").value;
            this.accountNbr = accountNbr.replace(/\s/g, "");
        }else{
            this.accountNbr = "Individual";             
        }
        const username = document.getElementById("newUsername").value;
        const password = document.getElementById("newPassword").value;
        const confirmPassword = document.getElementById("confirmPassword").value;
        if (this.props.signonType == "group"){
            this.gameRole = document.getElementById("gameRole").value;
            let authCode = document.getElementById("authCode").value;
            this.authCode = authCode.replace(/\s/g, "");
        }else{
            this.gameRole = "player";
            this.authCode = "N/A";
        }
        this.emailAddress = document.getElementById("emailAddress").value;
        this.basicFieldEdits(username, password, confirmPassword);
        this.setState({username: username, password: password, confirmPassword: confirmPassword});
    }

    basicFieldEdits(username, password, confirmPassword){
        this.resetErrorTable();
        this.state.openModal = true;
        if (this.gameRole == "none"){
            this.message = "Must select a role.";
            this.buildErrorTable(4);
        }
        if (this.props.signonType == "group"){
            if (this.accountNbr == ""){
                this.message = "Account number cannot be blank.";
                this.buildErrorTable(0);
            }else{
                this.getAccount();
            }
        }
        if (username == ""){
            this.message = "Username cannot be blank.";
            this.buildErrorTable(1);
        }else{
            if (username.includes('#')){
                this.message = "Username cannot contain the # sign."
                this.buildErrorTable(1);
            }else{
                this.getLogin(username, password);
            }
        }
        if (password == ""){
            this.message = "Password cannot be blank.";
            this.buildErrorTable(2);
        }else{
            let passwordErrors = editPassword(password, confirmPassword);
            if (passwordErrors[0].error == true){
                this.message = passwordErrors[0].message;
                this.buildErrorTable(2);
            }
            if (passwordErrors[1].error == true){
                this.message = passwordErrors[1].message;
                this.buildErrorTable(3);
            }
        }
        if (this.props.signonType == "group"){
            if (this.gameRole == "none"){
                this.message = "Game Role Must be Selected";
                this.buildErrorTable(4);
            }
            if (this.authCode == ""){
                this.message = "AuthCode is required - contact account owner.";
                this.buildErrorTable(5);
            }
        }
        if (this.emailAddress == ""){
                this.message = "Email Address is required."
                this.buildErrorTable(6);
            }else{
                const atSign = /@/;
                let containsAtSign = atSign.test(this.emailAddress);
            if (!containsAtSign){
                 this.message = "Email Address invalid";
                 this.buildErrorTable(6);
            }
        }
        if (this.errorFound === false){}
        this.setState({true: this.state.test});
    }

    resetErrorTable(){
        this.errorTable = [
        {field: "account", error: false, message: "", class: "font-weight-bold"},
        {field: "username", error: false, message: "", class: "font-weight-bold"},
        {field: "password", error: false, message: "", class: "font-weight-bold"},
        {field: "confirmPswd", error: false, message: "", class: "font-weight-bold"},
        {field: "gameRole", error: false, message: "", class: "font-weight-bold"},
        {field: "authCode", error: false, message: "", class: "font-weight-bold"},
        {field: "emailAddress", error: false, message: "", class: "font-weight-bold"}
       ]
       this.errorFound = false;
       this.setState({accountLoaded: false, loginLoaded: false});
    }

    buildErrorTable(indx){
        this.errorTable[indx].error = true;
        this.errorTable[indx].message = this.message;
        this.errorTable[indx].class = this.errorTable[indx].class + " text-danger";
        this.errorFound = true;
        this.waiting = false;
    }
    
    setPassword(password){
        this.setState({password: password});
    }

    setConfirmPassword(confirmPassword){
        this.setState({confirmPassword: confirmPassword});
    }

    editAccountInfo(){
        if (this.accountFound == false){
            this.message = "Invalid account number.";
            this.buildErrorTable(0);
        }else{
            if(this.gameRole != "none"){
                this.checkPlayersArr();
                this.checkAuthCodeArr();
            }
        }
        this.accountEdited = true;
    }

    checkPlayersArr(){
        let totalNbrRqstd = 0;
        for (let i=0; i<this.accountInfo.rqstdUsersArr.length; i++){
             let rqstdUsersArr = this.accountInfo.rqstdUsersArr[i];
             if (this.gameRole == rqstdUsersArr.role){
                 totalNbrRqstd += +rqstdUsersArr.nbrUsersRqstd;
             }
        }
        if (totalNbrRqstd > this.nbrPlayersAlreadySignedUp){
            //  there is still room for another signon
        }else{
            this.message = "Maximum users already signed up for this account and role.";
            this.buildErrorTable(0);
        }
    }

    checkAuthCodeArr(){
        this.authCodeFound = false;
        for (let i=0; i<this.accountInfo.authCodeArr.length; i++){
             let authCodeArr = this.accountInfo.authCodeArr[i];
             if (this.gameRole == authCodeArr.role){
                 if (this.authCode == authCodeArr.authCode){
                      // the authCode entered is valid
                    this.authCodeFound = true;
                    this.authCodeIdx = i;
                    break;
                 }
           }
        }
        if (this.authCodeFound == false){
            this.message = "AuthCode is not valid - contact account owner for valid code";
            this.buildErrorTable(5);
        }
    }

    editUsername(){
        if (this.userFound == true)
        {
            this.message = "Username already exists, change username and resubmit";
            this.buildErrorTable(1);
        }
        this.userEdited = true;
      
    }
   
    closeReleaseForm(){
       this.props.registerComplete();
    }
  
    getLogin(username, password){
        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + 
            Buffer.from(username + ":" + password).toString('base64'));
        const funcName = "loginChk";
        const rqstType = "UserOnly";
        const urlParm = "?rqstType=" + rqstType;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
            {method:'GET',
                headers: headers
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            this.loginInfo = output.body;
                            if (this.loginInfo.result === "Username Found"){
                                this.userFound = true;
                            }else{
                                this.userFound = false;  
                            }
                            this.setState({loginLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    });
                };
            })
    }

    getAccount(){
        this.state.accountLoaded = false;
        this.state.getAccount = true;
        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + 
            Buffer.from(this.state.username + ":" + this.state.password).toString('base64'));
        const funcName = "loadAccount";
        const urlParm = "?accountNbr=" + this.accountNbr + "&tokenType=TempToken";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
            {method:'GET',
             headers: headers,
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            this.state.accountLoaded = true;
                            this.accountInfo = output.body;
                            this.accountFound = true;
                            this.getUserLogonList();
                            this.setState({accountLoaded: true});
                        }else{
                            if (statusCode == 250){
                                this.accountFound = false;
                                this.setState({accountLoaded: true});
                            }else{
                                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                    status: statusCode, message: output.body.message};
                                this.props.processError(errorObj);
                            }
                        }
                    });
                };
            })
    }

    getUserLogonList(){
        this.state.getLogonList = true;
        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + 
            Buffer.from(this.state.username + ":" + this.state.password).toString('base64'));
        const funcName = "userCollectionList";
        const urlParm = "?accountNbr=" + this.accountNbr + 
            "&rqstType=CountOnly&gameRole=" + this.gameRole + "&tokenType=TempToken";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
            {method:'GET',
            headers: headers})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            this.nbrPlayersAlreadySignedUp = +output.body.result;
                            this.setState({userCountLoaded: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    });
                };
            })
    }

    saveLoginUser(){
        const currDateTime = getCurrentDateTime();
        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + 
            Buffer.from(this.state.username + ":" + this.state.password).toString('base64'));
        let dataObject = {
            role: this.gameRole,
            accountNbr: this.accountNbr,
            emailAddress: this.emailAddress,
            dateAdded: currDateTime.date,
            dateUpdated: currDateTime.date
        };
        const funcName = "saveLoginUser";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
        {
            method:'POST',
            headers: headers,
            body: JSON.stringify(dataObject)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        alert ("Good job!  Your login information has been saved.  Please record " +
                            "your username and password so that; you can staart where you left off " +
                            "when you play the game in the future.");
                        const credentials = {username: this.state.username, password: this.state.password};
                        sessionStorage.setItem("credentials", JSON.stringify(credentials));
                        if (this.props.signonType == "group"){
                            this.confirmAccountUpdate();
                        }else{
                            this.waiting = false;
                            this.props.registerComplete();
                        }
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: dataObject};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
    }
    
    confirmAccountUpdate(){
        this.authCodeArr = this.accountInfo.authCodeArr;
        this.checkAuthCodeArr();
        if (this.authCodeFound == true){
            this.authCodeArr.splice(this.authCodeIdx, 1);
            this.updateAccount();
        }
    }
    
    updateAccount(){
        const currDateTime = getCurrentDateTime();
        let tokenType = 'Standard';
        this.state.headers = setHeadersForFetch();
        let headers = "";
        if (this.state.headers.Authorization === "null"){ 
            headers = new Headers();
            headers.set('Authorization', 'Basic ' + 
                Buffer.from(this.state.username + ":" + this.state.password).toString('base64'));
            tokenType = "TempToken";
        }else{
            headers = this.state.headers;
        } 
        // Update account credit table in Mongo
        const bodyObj = {
            accountNbr: this.accountNbr,
            emailAddress: this.accountInfo.emailAddress,
            rqstdUsersArr: this.accountInfo.rqstdUsersArr,
            authCodeArr:JSON.parse(JSON.stringify(this.authCodeArr)),
            dateUpdated: currDateTime.date};
        const funcName = "updateAccount";
        const urlParm = "?tokenType=" + tokenType;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
              {method:'PUT', headers: this.state.headers,
              body: JSON.stringify(bodyObj)})
              .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            this.waiting = false;
                            this.props.registerComplete();
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: bodyObj};
                            this.props.processError(errorObj);
                        }
                    });
                };
            })
    }
    
}

export default RegisterForm;