import {Component} from 'react';

class GameChoicePictures extends Component {
        constructor(props) {
        super(props); 
        this.state={
            
        };  
        this.setScreenSizeClasses();
    }

    setScreenSizeClasses(){
         switch (this.props.screenSize){
            case "narrow":
              this.imgClass = "left-side-img-small text-center mx-0";
              this.carImgClass ="left-side-img-car-small text-center mx-0";
              this.textClass = "very-small-text2 mx-0 px-0 font-weight-bold";
              this.blankClass = "mt-5";
              break;
            case "medium":
              this.imgClass = "left-side-img-small text-center mx-0";
              this.carImgClass ="left-side-img-car-small text-center mx-0";
              this.textClass = "very-small-text2 mx-0 px-0 font-weight-bold";
              this.blankClass = "mt-5";
              break;
            default:
              this.carImgClass = "left-side-img-car";
              this.imgClass = "left-side-img";
              this.textClass = "text-for-left-side-img ml-5";
              this.blankClass = "";
              break;
         }
    }
 
    render() { 
                                               
    return <>
        <div className="text-center">
            <div className={this.blankClass}></div>
            <div>
                <img className={this.imgClass} src={this.props.pictureInfo.jobImg} alt={"jobImg"}/>
                <p className={this.textClass}>{this.props.pictureInfo.jobName}</p>
            </div>
            <div className="mt-5">
                <img className={this.imgClass} src={this.props.pictureInfo.homeImg} alt={"homeImg"}/>
                <p className={this.textClass}>{this.props.pictureInfo.homeName}</p>
            </div>
            <div className="mt-5">
                <img className={this.carImgClass} src={this.props.pictureInfo.carImg} alt={"carImg"}/>
                <p className={this.textClass}>{this.props.pictureInfo.carName}</p>
            </div>
          </div>
    </>
  }


}
 
export default GameChoicePictures;