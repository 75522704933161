import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import buildUrl from '../../objects/CommonUse/build_URL';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import Config from '../../config';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';


class OneTimeUpdate extends Component {
  constructor(props) {
    super(props);
    this.state={
      reDisplay: false,
      headers: setHeadersForFetch(),
      environment: Config.dataURL.currentEnv,
    }   
    this.processGameData();
}

processGameData(){
    this.nbrGamesUpdated = 0;
    this.totalNbrGamesFound = this.props.gameData.length;
    this.totalNbrNotYetUpdated = 0;
    for (let i=0; i<this.props.gameData.length; i++){
        if (this.props.gameData[i].jobInfo.jobStartDate !== undefined){
            this.totalNbrNotYetUpdated += 1;
        }
        if (this.nbrGamesUpdated <= 20){
          if (this.props.gameData[i].jobInfo.jobStartDate !== undefined){
                this.gameData = this.props.gameData[i];
                const oldJobInfoObj = this.gameData.jobInfo;
                this.newJobInfoObj =  {careerID: oldJobInfoObj.careerID,
                    healthInsInd: oldJobInfoObj.healthInsInd,
                    jobRank: oldJobInfoObj.jobRank,
                    nbrYearsCollege: oldJobInfoObj.nbrYearsCollege,
                    nbrSkillSurvey: oldJobInfoObj.nbrSkillSurvey,
                    jobStartDate: this.gameData.jobInfo.jobStartDate }
                this.setBodyObj();
                this.updateJobInfo();
                this.nbrGamesUpdated += 1;
          }
        }
    }
 
}

render() {
   
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="med" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Update Mood Points in Events</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                {this.state.reDisplay ?
                    <span>
                        <h4 className="text-center mt-4">Processing Complete</h4>
                        <div className="mt-4">
                        <div className="mt-4">
                            <b>Number of Records Processed: </b> {this.totalNbrGamesFound}
                        </div>
                            <b>Number of Records Not Yet Updated: </b> {this.totalNbrNotYetUpdated}
                        </div>
                        <div className = "mt-2">
                            <b>Number of Records Updated: </b> {this.nbrGamesUpdated}
                        </div>
                    </span>
                :
                    <WaitTimeSpinner/>
                }
             
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
             
        </Modal.Footer>
    </Modal>
    
  </>
}

setBodyObj(){
    this.bodyObj = {
                "skillSurvey": JSON.parse(JSON.stringify(this.gameData.skillSurvey)), 
                "jobInfo": JSON.parse(JSON.stringify(this.newJobInfoObj))};
}
                 
updateJobInfo(){   
    const funcName = "updateSkillSurvey";
    const urlParm = "?updateUser=" + this.gameData.user;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'PUT', headers: this.state.headers,
            body: JSON.stringify(this.bodyObj)})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.setState({reDisplay: true})
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                    this.props.processError(errorObj);
                }
            });
        }
    });
}


  }


export default OneTimeUpdate;