import {Component} from 'react';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';

class DisplayPurchasesCard extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false};
    }

    render() {
        this.imageSrc = "./assets/Events/" + this.props.purchaseList.imageURL;
        this.imgClassName = "purchase-image-size";
        this.itemCost = this.props.purchaseList.itemCost;
        this.nbrTimes = "";
        if (+this.props.purchaseList.nbrTimesPurchased > 1){
            this.nbrTimes = " - (number: " + this.props.purchaseList.nbrTimesPurchased + ")";
        }
    return <>
        <tr className="mt-2">
            <td className="col-sm-4">
                <img className={this.imgClassName} src={this.imageSrc} alt="icon"
                    />
            </td>
            <td className="col-sm-6 text-left font-weight-bold">
                 {this.props.purchaseList.itemName} {this.nbrTimes}
            </td>
            <td className="col-sm-4 text-right font-weight-bold">
                 {doubleToStringMonthly(this.itemCost)}
            </td>
        </tr>
           
    </>
    }
}

export default DisplayPurchasesCard;