import buildFunStuffPictureObj from './build_fun_stuff_picture_obj';
import { getHomePicture} from '../../components/game-views/objects/get_home_picture';


export const getGameChoicePictures = (dataType, dataObj, selGameInfo, pictureInfoObj) => {

        switch (dataType){
            case "job":
                const jobPictureImg = './assets/Jobs/' + dataObj.pictureImg;
                const jobBackgroundImg = "./assets/jobBackground/" + dataObj.backgroundImg;
                const jobName = dataObj.jobName;
                const jobLevel2Title = dataObj.level2Title;
                const jobLevel3Title = dataObj.level3Title;
                const jobMaxSalary = dataObj.maxSalary;
                pictureInfoObj={...pictureInfoObj, jobID: selGameInfo.careerID, jobImg: jobPictureImg, 
                    jobName: jobName, jobBackground: jobBackgroundImg,  
                    jobLevel2: jobLevel2Title, jobLevel3: jobLevel3Title, jobMaxSalary: jobMaxSalary,
                };
                break;
            case "home":
                const homePictureImg = './assets/' + dataObj.directory + 
                "/default.jpg";
                let roommates = selGameInfo.roommates;
                let homeName = "";
                if (roommates === 0){  
                    homeName = dataObj.title + " - no roommate";
                }else{
                    if (roommates === 1){
                        homeName = dataObj.title + " - " + roommates + " roommate";
                    }else{
                        homeName = dataObj.title + " - " + roommates + " roommates";
                    }
                }
                const livingRoomImg = getHomePicture(dataObj.directory, 
                    dataObj.additionalPhotosArr, "Living Room");
                pictureInfoObj={...pictureInfoObj, homeID: selGameInfo.homeID, homeImg: homePictureImg, 
                        homeName: homeName, livingRoomImg: livingRoomImg};
                break;
            case "car":
                const carPictureImg = "./assets/" + dataObj.directory;
                const carName = dataObj.sizeName + " - " + selGameInfo.carYear;
                pictureInfoObj={...pictureInfoObj, carID: selGameInfo.carID, carImg: carPictureImg, 
                    carName: carName };
                break;
            case "funStuff":
                let funStuffPictureObject = {};
                if (dataObj.itemArr !== undefined){  
                    funStuffPictureObject = buildFunStuffPictureObj(dataObj);
                    funStuffPictureObject = {...funStuffPictureObject, funStuffExists: true};
                }else{
                    funStuffPictureObject = {funStuffExists: false};
                }
                pictureInfoObj={...pictureInfoObj, funStuffInfo: funStuffPictureObject};
                break;
            default:
                break;
        }
        return pictureInfoObj;
    }

 
export default getGameChoicePictures;