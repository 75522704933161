import React from "react";
import { connect } from "react-redux";
import store from "../../redux/store";

export const reduxSetGameInfo = (actionType, value, dataName) => {

      if (actionType !== undefined && actionType !== ""){
        const setGameInfo = () => {
          return {
            type: actionType,
            payload: value,
            dataName: dataName
          }
        }
            
        store.dispatch(setGameInfo());
      }
      return store.getState();
      
}
             

export default reduxSetGameInfo;