import {Component} from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';
import DialogParmInputModal from './dialog_parm_input_modal';
import FriendDialogDisplay from '../game-views/friend_dialog_display';
import buildCardFromDialog from '../game-views/objects/build_card_from_dialog';
import { setResponseResultText } from '../game-views/objects/set_response_result_text';
import ConfigFile from '../../datasets/config_file';
import friend1GameDialogDataset from '../game-views/dialogDatasets/friend1_game_dialog_dataset';
import friend2GameDialogDataset from '../game-views/dialogDatasets/friend2_game_dialog_dataset';
import friend3GameDialogDataset from '../game-views/dialogDatasets/friend3_game_dialog_dataset';
import friend4GameDialogDataset from '../game-views/dialogDatasets/friend4_game_dialog_dataset';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import reduxSetGameInfo from '../../pages/Objects/redux_set_game_info';

class TestDialog extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            gameData: null,
            loading: true,
            displayParmModal:  true,
            openDialogParameterModal: false,
            openEditTextModal: false,
            allCompleteText: "",
            processingCompleteSw: false,
            friendDisplayType: "",
            friendName: "",
            dialogIdx: 0,
            friendSelected: false,
            replySelected: false,
         
        }
    }

    componentDidMount(){
        this.loadGame();
    }
    
    loadGame()
    {
        const gameInfo = reduxSetGameInfo("");
        const gameData = gameInfo.gameData;
        this.setState({gameData, gameData});
        this.setState({loading: false});
        this.setState({openDialogParameterModal: true});
    }

    openParameterModal(){
        this.state.openDialogParameterModal = true;
        this.state.friendSelected = false;
        this.state.friendDisplayType = "openParameterModal";
    }

    closeParameterModal(){
        this.state.openDialogParameterModal = false;
    }

    getDialogForSlctdFriend(dialogParameters){
        this.state.friendName = dialogParameters.friendName;
        switch (this.state.friendName){
            case "Jennifer":
                this.dialogTable = friend1GameDialogDataset.dialogTable;
                break;
            case "Charlie":
                this.dialogTable = friend2GameDialogDataset.dialogTable;
                break;
            case "Cindy":
                this.dialogTable = friend3GameDialogDataset.dialogTable;
                break;
            case "Cameron":
                this.dialogTable = friend4GameDialogDataset.dialogTable;
                break;
        }
        this.state.dialogIdx = dialogParameters.dialogIdx;
        this.displayDialogItem();
    }

    displayDialogItem(){
        this.state.friendDisplayType = "initial";
        this.state.openDialogParameterModal = false;
        let friendList = this.state.gameData.friendList;
        this.friendInfo = friendList.find(elem => elem.name === this.state.friendName);
        let dialog = this.dialogTable[this.state.dialogIdx];
        let selectDialogObj = {title: dialog.title, text: dialog.text, responses: dialog.responses, 
            dialogIndx: 0, nbrDialogItems: this.dialogTable.length};
        this.currentCard = buildCardFromDialog(this.state.friendName, selectDialogObj, "all");
        this.friendURL = "./assets/friends/" + (this.state.friendName).toLowerCase() + ".png";
        this.currentCard = {...this.currentCard, friendImgURL: this.friendURL};
        this.title = dialog.title;
        this.dialogLength = (dialog.text).length;
        this.responses = dialog.responses;
        this.dialogIndx = this.state.dialogIdx;
        this.nbrDialogItems = selectDialogObj.nbrDialogItems;
        this.state.friendSelected = true;
        this.state.replySelected = false;
    }
      
    resolveDisplayedCard(replyNbr){
        for (let i=0; i<this.state.gameData.friendList.length; i++){
            if(this.state.gameData.friendList[i].name == this.state.friendName){
                this.state.gameData.friendList[i].rltnshpScore = 
                    +this.state.gameData.friendList[i].rltnshpScore + 
                        +this.responses[replyNbr].rltnPoints;
                if (this.responses[replyNbr].loanedMoney == true  ||
                    (+this.state.gameData.friendList[i].totalLoanAmt > 0 &&
                    +this.loanAmt < 0)){
                        this.state.gameData.friendList[i].totalLoanAmt =
                            +this.state.gameData.friendList[i].totalLoanAmt +
                                +this.loanAmt;
                }
                break;
            }
        };
      
        this.moodPoints = this.responses[replyNbr].moodPoints;
        // update mood points as a result of dialog item

        this.state.replySelected = true;  
        this.rltnshpPoints = +this.responses[replyNbr].rltnPoints;
        this.moodPointMultiplier = ConfigFile.gameBoard.moodPointMultiplier;
        this.fullMoodPoints = +this.moodPoints * this.moodPointMultiplier;
        this.state.gameData.currentMoodPoints = +this.state.gameData.currentMoodPoints + +this.fullMoodPoints;
        this.dialogResultText = setResponseResultText(this.fullMoodPoints, this.rltnshpPoints, this.state.friendName,
            this.title, this.responses[replyNbr].cashAmt);
        this.cashAmt = 0;    
        if (this.responses[replyNbr].cashAmt != undefined){
            this.cashAmt = +this.responses[replyNbr].cashAmt; 
            this.state.gameData.currentCash = +this.state.gameData.currentCash + +this.cashAmt;
           
        }
        this.state.friendDisplayType = "finish";
    }

    getNextFriendDialog(){
        this.state.dialogIdx +=1;
        let dialogParameters = {friendName: this.state.friendName, dialogIdx: this.dialogIndx}; 
        this.displayDialogItem(dialogParameters);
        this.state.friendDisplayType = "initial";
    }

    calculateResponseValue(response)
    {
        let returnValue = this.currentCard.responses[response].value;
        return returnValue;
    }

    
    getPreviousFriendDialog(){
        this.state.dialogIdx -= 1;
        let dialogParameters = {friendName: this.state.friendName, dialogIdx: this.dialogIndx}; 
        this.displayDialogItem(dialogParameters);
        this.state.friendDisplayType = "initial";
    }

    onResultDisplay(){

    }
   
  
    render() { 
         let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard");
         this.className = screenParms.columnClass;
         this.linkSize = screenParms.linkSize;

           
    return <>
        <div className={this.className}>
        <div className="card mt-3 mr-5 rounded-corners-lg text-center game-board"
            style={{backgroundImage: `url(${process.env.PUBLIC_URL + this.state.backgroundURL})`}}>
            <h3 className="mt-2 text-center">Special Processes</h3>
            <h5 className="text-center">Test Dialog</h5>
            {this.state.loading  ?
                <WaitTimeSpinner/>
            :
                <span className="mt-2 ml-4 mr-4">
                    <div className="text-right mt-4">
                        <Button variant="primary"  onClick={() => this.openParameterModal()}>
                            Select A Friend
                        </Button>
                    </div>
                    
                </span>
            }
           
            {this.state.openDialogParameterModal ?
                    <DialogParmInputModal  
                        openModal={this.state.openDialogParameterModal} 
                        friendList={this.state.gameData.friendList}
                        onClose={() => this.closeParameterModal()} 
                        onSubmit={(dialogParameters) => this.getDialogForSlctdFriend(dialogParameters)}
                    />
            :
                    null
            }

            {this.state.friendDisplayType == "initial" ?
                <span >
                    <div >
                <FriendDialogDisplay
                    screenSize={this.props.screenSize}
                    currentCard={this.currentCard}
                    onResultDisplay={()=>this.onResultDisplay()}
                    calcResponseValue={(response) => this.calculateResponseValue(response)}
                    onComplete={(selectedInd)=>this.resolveDisplayedCard(selectedInd)}
                />
                                        
                    <div className="dialog-info-position text-left">
                            <div> 
                                Total Dialog Items: {this.nbrDialogItems}
                            </div>
                            <div>
                                Current Dialog Item: {this.dialogIndx + 1}
                            </div>
                            <div>
                                Length of Dialog Text: {this.dialogLength};
                            </div>
                            <div className="mt-2 font-weight-bold">
                                Cumulative (calculated) Totals:
                            </div>
                            <div>
                                Mood Points: {this.state.gameData.currentMoodPoints}
                            </div>
                            <div>
                                Relationship Score: {this.friendInfo.rltnshpScore}
                            </div>
                            <div>
                                Cash Amount: ${this.state.gameData.currentCash}
                            </div>

                            {this.state.replySelected ?
                                <span>
                                <div className="mt-2 font-weight-bold">
                                    Amounts for This Dialog Item:
                                </div>
                                <div>
                                    Mood Points: {+this.moodPoints * +this.moodPointMultiplier}
                                </div>
                                <div>
                                    Relationship Points: {this.rltnshpPoints}
                                </div>
                                <div>
                                    Cost Amount: {this.cashAmt}
                                </div>
                             
                            </span>
                            :
                               null
                                
                            }
                    </div>
                   </div>
                </span>
            :
                 null
            }
            {this.state.friendDisplayType == "finish"  ?
                <div className="row">
                    <div className="col-md text-center mt-4 mx-2">
                        <Button className="mx-2" variant="primary"  onClick={() => this.getPreviousFriendDialog()}>
                            Previous Card
                        </Button>
                        <Button className="mx-2" variant="primary"  onClick={() => this.getNextFriendDialog()}>
                            Next Card
                        </Button>
                    </div>
                    <div className="col-md"></div>
                    </div>
            :
                null
            }
            </div>        
        </div>
     </>
    }

}


export default withRouter (TestDialog);