import { removeConditionsTable } from "./remove_Conditions_Table_function";
import { resetPicturesForChanges } from "./reset_pictureInfo_for_changes";
import { createHealthInsExpense } from "../../../objects/AssetsExpenses/create_health_ins_expense";
import { createSalaryAsset } from "../../../objects/AssetsExpenses/create_salary_asset";
import { createIconTableObj } from "./create_icon_table_obj";


export const processPendingJob = (pendingJob, newJobStartDate, assetTable, expenseTable, loanTable,
        conditionsTable, pictureInfo, gameStartDate, iconTable) => {

        // remove current salary asset and add asset for new salary 
        // amount adjusted for inflation
        assetTable = createSalaryAsset(assetTable, pendingJob.jobInfo.newMonthlySalary, pendingJob.jobInfo.careerName,
            newJobStartDate, gameStartDate);
  
     
        // if health insurance indicator changes for new job, then create new health insurance exp item 
        // amount adjusted for inflation
        if (pendingJob.jobInfo.changeHealthIns == true){
            expenseTable = createHealthInsExpense(expenseTable, pendingJob.jobInfo.healthInsInd,
              newJobStartDate, gameStartDate)
        }
  
        // add new loan objects
        for (let i=0; i<pendingJob.loanTable.length; i++){
            loanTable.push(pendingJob.loanTable[i]);
        }
    
        // remove existing career conditions
        conditionsTable = removeConditionsTable(conditionsTable, pendingJob.class);

        // remove any outstanding 'college' conditions
        conditionsTable = removeConditionsTable(conditionsTable, "college");

        // add conditions for the new job
        for (let i=0; i<pendingJob.jobConditions.length; i++){
             conditionsTable.push(pendingJob.jobConditions[i]);
        }
        let additionalInfo = {careerName: pendingJob.jobInfo.careerName};
        let newIconTable = createIconTableObj("jobChange", iconTable, additionalInfo);
        // update picture info
        //   let jobInfo={jobMaxSalary: jobMaxSalary, jobLevel3: level2Title, jobLevel3: level3Title};
        let newPictureInfo = resetPicturesForChanges("career", pictureInfo, pendingJob.pictureInfo.careerID,
            pendingJob.pictureInfo.careerName, pendingJob.pictureInfo.careerPictureImg,
            pendingJob.pictureInfo.careerBackgroundImg);
    
        let newJobObj = {assetTable: assetTable, expenseTable: expenseTable, loanTable: loanTable,
            conditionsTable: conditionsTable, pictureInfo: newPictureInfo, jobInfo: pendingJob.jobInfo,
            iconTable: newIconTable};
                            
    return newJobObj;
   
}





