import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import setModalSize from '../../objects/CommonUse/set_modal_size';
import { calculateJobSalary } from '../../objects/AssetsExpenses/calculate_job_salary';
import { calcNbrMonthsBtwnDates } from '../../objects/CommonUse/nbr_months_btwn_dates_calc';
import { monthlyLoanAmt } from '../../objects/AssetsExpenses/loanAmtCalc_function';
import { rollForwardDate } from './objects/roll_forward_date_function';
import { removeConditionsTable } from './objects/remove_Conditions_Table_function';

class ReviewCancelPendingJob extends Component {
        constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state={
            headers: setHeadersForFetch(),  
            pendingJob: true,
            subProcess: "reviewCancelPendingJobModal",
            environment: Config.dataURL.currentEnv,
        }   
        this.initialDisplayFields();
    }
    
    initialDisplayFields(){
        const currentMonthlySalary = calculateJobSalary(this.gameInfo.gameData.assetTable, 
            this.gameInfo.gameData.currentGameDate, true);
        this.currentAnnualSalary = (currentMonthlySalary * 12).toFixed(2);
        this.newAnnualSalary = (this.gameInfo.gameData.pendingJob.jobInfo.newMonthlySalary * 12).toFixed(2);
        this.checkLoanTable();
    }

    checkLoanTable(){
        this.newLoanReqd = false;
        this.nextLoan = JSON.parse(JSON.stringify(this.gameInfo.gameData.pendingJob.loanTable[0]));
        this.nbrMonthsInSchool = calcNbrMonthsBtwnDates(this.gameInfo.gameData.currentGameDate,
                this.nextLoan.loanStartDate)
        if (this.nbrMonthsInSchool <= 4){
            this.newLoanReqd = true;
        }else{
            if (this.nbrMonthsInSchool <= 10){
                this.newLoanReqd = true;
                this.calcHalfLoan();
            }
        }
        if (this.newLoanReqd === true){
            this.nextLoan.loanStartDate = this.gameInfo.gameData.currentGameDate;
            this.loanEndDate = rollForwardDate(this.nextLoan.loanStartDate, this.nextLoan.loanTerm);
            this.gameInfo.gameData.loanTable.push(this.nextLoan);
        }
      }

      calcHalfLoan(){
            this.nextLoan.loanTotAmt = (this.nextLoan.loanTotAmt / 2).toFixed(2);
            this.nextLoan.loanAmt = monthlyLoanAmt(this.nextLoan.loanTotAmt, this.nextLoan.loanIntRate, 
                    this.nextLoan.loanTerm);
      }
  
    render() {
        //this.gutters = setModalSize(this.props.screenSize);
    return <>
      
      <Modal show={this.props.show}  onHide={() => this.props.onClose("none")} size="med" scrollable="true"
           animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title className="text-center">Review/Cancel Pending Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <span>
                <div className="mx-2">
                      You currently have a job that is pending.  You can use this display to 
                      review the job information and/or cancel the pending job.
                </div>
                <span>
                    <h5 className="mt-2 text-center">----- Current Job Information -----</h5>
                    <div className="mx-2 row w-90 mt-2">
                        <div className="col-sm-6 font-weight-bold">
                            Current Job:
                        </div>
                        <div className="col-sm-5 text-right">
                            {this.gameInfo.pictureInfo.jobName}
                        </div>                       
                    </div>
                    <div className="mx-2 row w-90">
                        <div className="col-sm-6 font-weight-bold">
                            Current Game Date:
                        </div>
                        <div className="col-sm-5 text-right">
                            {reformatDateToDisplay(this.gameInfo.gameData.currentGameDate)}
                        </div>                       
                    </div>
                    <div className="mx-2 row w-90">
                        <div className="col-sm-6 font-weight-bold">
                            Annual Salary:
                        </div>
                        <div className="col-sm-5 text-right">
                            {doubleToStringMonthly(this.currentAnnualSalary)}
                        </div>                       
                    </div>
                    <h5 className="mt-4 text-center">------ New Job ------</h5>
                    <div   className="mx-2 mt-2 row w-90">
                        <div className="col-sm-6 font-weight-bold">
                            New Job:
                        </div>
                        <div className="col-sm-5 text-right">
                            {this.gameInfo.gameData.pendingJob.jobInfo.careerName}
                        </div>                       
                    </div>
                    <div   className="mx-2 row w-90">
                        <div className="col-sm-6 font-weight-bold">
                            Job Start Date:
                        </div>
                        <div className="col-sm-5 text-right">
                            {reformatDateToDisplay(this.gameInfo.gameData.pendingJob.jobStartDate)}
                        </div>                       
                    </div>
                    <div className="mx-2 row w-90">
                        <div className="col-sm-6 font-weight-bold">
                            Starting Salary:
                        </div>
                        <div className="col-sm-5 text-right">
                            {doubleToStringMonthly(this.newAnnualSalary)}
                        </div>   
                    </div>
                    {this.newLoanReqd  ?
                        <span>
                            <div className="mt-2">
                                You have been going to school for {+this.nbrMonthsInSchool} months 
                                so you will need to pay tuition for this time.  A new loan has
                                been created to pay for the tuition.  The loan information is
                                shown below.
                            </div>
                        <div className="mx-2 row w-90">
                            <div className="col-sm-6 font-weight-bold">
                                Total Loan Amt:
                            </div>
                            <div className="col-sm-5 text-right">
                                {doubleToStringMonthly(this.nextLoan.loanTotAmt)}
                            </div>   
                            <div className="col-sm-6 font-weight-bold">
                                Monthly Loan Amt:
                            </div>
                            <div className="col-sm-5 text-right">
                                {doubleToStringMonthly(this.nextLoan.loanAmt)}
                            </div>   
                            <div className="col-sm-6 font-weight-bold">
                                Loan End Date:
                            </div>
                            <div className="col-sm-5 text-right">
                                {reformatDateToDisplay(this.loanEndDate)}
                            </div>   
                        </div>
                        </span>
                    :
                        null
                    }
                    <div className="text-center mt-4">
                        <img src={this.gameInfo.gameData.pendingJob.pictureInfo.careerPictureImg}
                            className="job-picture picture-border picture-container text-center" 
                            alt="jobImage" />
                    </div>
                </span>
            </span>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
            <div className={this.messageClass}>{this.message}</div>
            <Button className="mr-2" variant="secondary"  onClick={() => this.cancelPendingJob()} >
                    Cancel Pending Job
            </Button>            
            <Button variant="primary"  onClick={() => this.props.onClose("none")}>
                Close
            </Button>
           
        </Modal.Footer>
        </Modal>
        </>
    }

cancelPendingJob(){
    // remove any outstanding 'college' conditions
    this.gameInfo.gameData.conditionsTable = 
        removeConditionsTable(this.gameInfo.gameData.conditionsTable, "college");
    this.updatePendingJob();
    this.props.onClose();
}


   
updatePendingJob(){
    const funcName = "updatePendingJob";
    //  calculate the number of months that have been played using current game years played
    this.bodyObj = {pendingJob: {}, conditionsTable: this.gameInfo.gameData.conditionsTable,
        loanTable: this.gameInfo.gameData.loanTable};
    this.gameInfo.gameData.pendingJob = {};
    const beforeObj = this.gameInfo.gameData;
    this.props.setGameInfo(this.gameInfo.gameData, "gameData");
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'PUT', headers: this.state.headers, 
        body: JSON.stringify(this.bodyObj)})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                   //  successful update of gameData
                    alert("The Pending Job has been cancelled");
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: this.creditCardObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}
    
}

export default ReviewCancelPendingJob;