import React from 'react';
import { withRouter } from "react-router";
import LinkCard from '../shared-components/link_card';
import {Link} from "react-router-dom";
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';

class ChangeGameChoices extends React.Component {
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            nextProcess: "",
            openProcess: false,
            prevProcess: this.props.prevProcess,
            allowed: true,
        }    
        this.setDisabledStatus()
    }

setDisabledStatus(){
    // check to see if the pendingJob object is empty
    if (+this.gameInfo.gameData.jobInfo.nbrSkillSurvey >= 2 || 
        (this.gameInfo.gameData.pendingJob !== undefined &&
         this.gameInfo.gameData.pendingJob.jobStartDate !== undefined)){ 
            this.skillSurveyOK = false;
    }else{
            this.skillSurveyOK = true;
    }
}

changeSkillSurvey() {
    if (this.skillSurveyOK == true) {
        this.state.nextProcess = "ChangeSkillSurvey";
        this.state.openProcess = true;
        this.props.history.push('/change-skills-survey');
    }
}

changeCareerChoice() {
    this.state.nextProcess = "ChangeCareer";
    this.state.openProcess = true;
    this.props.history.push('/change-career');
}

changeHomeChoice() {
    this.state.nextProcess = "ChangeHome";
    this.state.openProcess = true;
    this.props.history.push('/change-home');
}

changeCarChoice() {
    this.state.nextProcess = "ChangeCar";
    this.state.openProcess = true;
    this.props.history.push('/change-car');
}

closeProcess() {
    this.state.openProcess = false;
    this.state.nextProcess = "";
}

  render() {
        let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard");
        this.classSize = screenParms.columnClass;
        this.linkSize = screenParms.linkSize;
  return <>
           <div className={this.classSize}>
           <div className="card mt-3 mr-2 rounded-corners-lg text-center game-board height-max">
            <h3 className="mt-2 text-center">Change Game Choices</h3>
           
            <span className="row w-100 mx-0 ml-4">  
                <span className="col-sm-6 mt-4">
                    {this.skillSurveyOK ?
                        <div  onClick={() => this.changeSkillSurvey()}>
                            <LinkCard name="Retake Skills Survey" imgSrc='./assets/GameChoices/change_skills_survey.png'
                                inSize={this.linkSize} alt=""   allowed={this.skillSurveyOK} destination="" />
                        </div>
                    
                    :
                        <div  className="buttonDisabledImage" onClick={() => this.changeSkillSurvey()}>
                            <LinkCard name="Retake Skills Survey" imgSrc='./assets/GameChoices/change_skills_survey.png'
                                inSize={this.linkSize} alt=""  allowed={this.skillSurveyOK} destination="" />
                        </div>  
                    }
                    <div onClick={() => this.changeHomeChoice()}>
                        <LinkCard name="Choose a Different Home" imgSrc='./assets/GameChoices/change_home.png' 
                            inSize={this.linkSize} alt=""  allowed={this.state.allowed} destination=""/>
                                   
                    </div> 
                </span>
                <span className="col-sm-6 mt-4">
                    <div  onClick={() => this.changeCareerChoice()}>
                        <LinkCard name="Choose a Different Job" imgSrc='./assets/GameChoices/change_career.png'
                            inSize={this.linkSize} alt=""  allowed={this.state.allowed} destination="" />
                    </div>
                    <div onClick={() => this.changeCarChoice()}>
                        <LinkCard name="Choose a Different Car" imgSrc='./assets/GameChoices/change_car.png' 
                            inSize={this.linkSize} alt=""  allowed={this.state.allowed} destination=""/>
                                  
                    </div> 
                </span>
            </span>
           
        </div>
    </div>   
</>
  }
}

export default withRouter(ChangeGameChoices);