import EventImageList from "../datasets/event_image_list";

export const updateGamePurchaseArr = (itemName, imageDir, itemCost, gameDate, purchaseArr) => {

    let defaultItemType = "";
    if (itemName === "thingamajig"){
        imageDir = "item-purchase-default";
        defaultItemType = "1000";
        const itemCostCheck = +itemCost * -1;
        if (+itemCostCheck >= 4000){
            defaultItemType = "5000";
        }
    }
    let itemObj =  EventImageList.find(elem => elem.imageDir === imageDir);
    if (defaultItemType !== ""){
        itemObj.itemType = defaultItemType;
    }
    let optionObj =  itemObj.options.find(elem => elem.itemName === itemName);
    itemCost = -1 * +itemCost;
    let purchaseArrOut = [];
    if (purchaseArr !== undefined){
        purchaseArrOut = purchaseArr;
    }
    const purchaseIndx = purchaseArrOut.findIndex(elem => elem.itemName === itemName);
           
    if (purchaseIndx === -1){
        const itemCostString = (itemCost.toString());
        const gamePurchaseObj = {itemName: itemName, itemType: itemObj.itemType, itemCost: itemCostString,
            imageURL: optionObj.imageURL, nbrTimesPurchased: "1", enjoyWithFriend: itemObj.enjoyWithFriend,
            gameDateLastPurchased: gameDate};
        purchaseArrOut.push(gamePurchaseObj);
    }else{
        let nbrTimesPurchased = +purchaseArrOut[purchaseIndx].nbrTimesPurchased + 1;
        purchaseArrOut[purchaseIndx].nbrTimesPurchased = nbrTimesPurchased.toString();
        let totalCost = +purchaseArrOut[purchaseIndx].itemCost + +itemCost;
        purchaseArrOut[purchaseIndx].itemCost = totalCost.toString();
        purchaseArrOut[purchaseIndx].gameDateLastPurchased = gameDate;
    }
         
    return purchaseArrOut;
   
}
export default updateGamePurchaseArr;

