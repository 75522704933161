import buildFunStuffPictureObj from "../../../pages/Objects/build_fun_stuff_picture_obj";

export const resetPicturesForChanges = (changeReason, pictureInfo, newID, newName, newImg, 
        newSecondaryImg, funStuffInfo) => {

   
    let newPictureInfo = JSON.parse(JSON.stringify(pictureInfo));
  
    switch(changeReason) {
        case "career":
            newPictureInfo.JobID = newID;
            newPictureInfo.jobImg = newImg;
            newPictureInfo.jobName = newName;
            newPictureInfo.jobBackground = newSecondaryImg;
            newPictureInfo.jobMaxSalary = pictureInfo.maxSalary;
            newPictureInfo.jobLevel2Title = pictureInfo.level2Title;
            newPictureInfo.jobLevel3Title = pictureInfo.level3Title;
            break;
        case "home":
            newPictureInfo.homeID = newID;
            newPictureInfo.homeImg = newImg;
            newPictureInfo.homeName = newName;
            newPictureInfo.livingRoomImg = newSecondaryImg;
            break;
        case "car":
            newPictureInfo.carID = newID;
            newPictureInfo.carImg = newImg;
            newPictureInfo.carName = newName;
            break;
        case "funStuff":
            let newFunStuffPictureObj = {funstuffInfo: false};
            if (funStuffInfo.gameID !== undefined){
                const funStuffPictureDir = buildFunStuffPictureObj(funStuffInfo);
                newFunStuffPictureObj = {...funStuffPictureDir, funStuffExists: true};
            }
            newPictureInfo.funStuffInfo = newFunStuffPictureObj;
            break;
        default:
            break;
        }
 

    return newPictureInfo;
   
}





