import './Admin/admin.css';
import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { calcMonthlyIncome } from "../../objects/CommonUse/calcMonthlyIncome";
import PlayerProfileDisplayCard from "./player_profile_display_card";
import ConfigFile from "../../datasets/config_file";
import { calculateJobSalary } from "../../objects/AssetsExpenses/calculate_job_salary";
import MonthlyStatusModal from "../game-views/monthly_status_modal";
import DiscussionTextModal from "../for-parents/discussion_text_modal";
import setModalSize from '../../objects/CommonUse/set_modal_size';

class GameProfileDisplayModal extends Component {
  constructor(props) {
    super(props);
      this.state={
        gameProfilesProcessed: false,
        openStatusModal: false,
        reRender: false,
        openDiscussionModal: false,
      }    
    this.processGameProfiles();
}

processGameProfiles() {
      this.playerProfileArr = [];
      this.playerProfileArr = [];
      this.gutters = setModalSize(this.props.screenSize);
      for (let i=0; i<this.props.gameData.length; i++){
          let gameData = this.props.gameData[i];
          //  need to figure out how to use the time wizard to calculate this instead of configFile!!!
          this.findTimeWizardForGame(gameData._id, i);
        
          let incomeObj = calcMonthlyIncome(gameData.assetTable, gameData.expenseTable, gameData.loanTable);
          this.monthlyIncome = incomeObj.income;
          let monthlySalary = calculateJobSalary(gameData.assetTable, gameData.currentGameDate,
            true)
          this.salary = (monthlySalary * 12).toFixed(2);
             
          if (gameData.moodPointHistory.moodPointMonths !== 0){
              this.moodPointsPerMonth = ( gameData.moodPointHistory.totalMoodPoints / 
                  gameData.moodPointHistory.moodPointMonths).toFixed(2);
          }else{
              this.moodPointsPerMonth = 0;
          }
          this.findGameChoicesForGame(gameData._id, gameData.homeInfo.roommates, 
            gameData.carInfo.carYear);
          this.findSavingsForGame(gameData._id);
          this.findCreditCardForGame(gameData._id);
          let nbrBadgesEarned = 0;
          if (gameData.badgesEarned !== undefined && gameData.badgesEarned.length > 0){
              nbrBadgesEarned = gameData.badgesEarned.length;
              // subtracting 1 from length if first badge is currently blank 
              // this has been fixed going forward but old records may need this check 04/30/2024
              if (gameData.badgesEarned[0].badgeCode === undefined){
                  nbrBadgesEarned -= 1;
              }
          }
          let gameObj = {user: gameData.user, gameNbrMonths: this.nbrMonthsPlayed, 
            currentCash: gameData.currentCash, monthlyIncome: this.monthlyIncome,
            savingsAmt: this.savingsAmt, monthlySavingsAmt: this.monthlySavingsAmt, 
            jobName: this.jobName, homeName: this.homeName, carName: this.carName, salary: this.salary,
            creditCardBalance: this.creditCardBalance, creditCardPolicy: this.creditCardPolicy,
            moodPoints: gameData.currentMoodPoints, moodPointsPerMonth: this.moodPointsPerMonth,
            unlockedFeatures: gameData.unlockedFeatures, nbrBadges: nbrBadgesEarned};
          this.playerProfileArr.push(gameObj);
      }
      this.playerProfileArr.sort((a, b) => (a.user > b.user) ? 1 : -1)
      if (this._isMounted === true){
          this.setState({gameProfilesProcessed: true});
      }
}

findTimeWizardForGame(gameID, indx){
  const timeWizardInfo = (this.props.timeWizardData).find(elem => elem.gameID === gameID);
  let totalNbrGameMonths = +ConfigFile.gameStartItems.totalNbrGameMonths;
  if (timeWizardInfo !== undefined){
      totalNbrGameMonths = +timeWizardInfo.gameNbrYears * 12;
  }
  this.nbrMonthsPlayed = +totalNbrGameMonths - +this.props.gameData[indx].gameNbrMonths;
}

findGameChoicesForGame(gameID, roommates, carYear){
    const gameChoices = (this.props.gameChoicesData).find(elem => elem.gameID === gameID);
    this.jobName = "";
    this.homeName = "";
    this.carName = "";
    if (gameChoices !== undefined){
       this.jobName = gameChoices.jobName;
       this.homeName = gameChoices.homeName + " Nbr Bedrooms: " + gameChoices.homeBedrooms +
        " Roommates: " + roommates;
       this.carName = gameChoices.carSizeName + " - " + carYear; 
    }
}

findSavingsForGame(gameID){
    const savingsInfo = (this.props.savingsData).find(elem => elem.gameID === gameID);
    this.savingsAmt = 0;
    this.monthlySavingsAmt = 0;
    if (savingsInfo !== undefined){
       this.savingsAmt = +savingsInfo.totalSavings;
       this.monthlySavingsAmt = +savingsInfo.monthlySavingsAmt;
    }
}

findCreditCardForGame(gameID){
    const creditCardInfo = (this.props.creditCardData).find(elem => elem.gameID === gameID);
    this.creditCardBalance = 0;
    this.creditCardPolicy = "none";
    if (creditCardInfo !== undefined){
        this.creditCardBalance = creditCardInfo.totalOutstandingBalance;
        this.creditCardPolicy = creditCardInfo.paymentPolicyCode;
  }
}

sortProfileReport(){
  this.sortParm = document.getElementById("sortParm").value;
  switch (this.sortParm){
      case "default":
        this.playerProfileArr.sort((a, b) => (a.user > b.user) ? 1 : -1)
        break;
      case "cash":
        this.playerProfileArr.sort((a, b) => (+a.currentCash > +b.currentCash) ? -1 : 1)
        break;
      case "salaryA":
        this.playerProfileArr.sort((a, b) => (+a.salary > +b.salary) ? 1 : -1)
        break;
      case "salaryD":
        this.playerProfileArr.sort((a, b) => (+a.salary > +b.salary) ? -1 : 1)
        break;
      case "netIncomeA":
        this.playerProfileArr.sort((a, b) => (+a.monthlyIncome > +b.monthlyIncome) ? 1 : -1)
        break;
      case "netIncomeD":
        // descending
        this.playerProfileArr.sort((a, b) => (+a.monthlyIncome > +b.monthlyIncome) ? -1 : 1);
        break;
      case "job":
        this.playerProfileArr.sort((a, b) => (a.jobName > b.jobName) ? 1 : -1);
        break;
      case "mood":
        this.playerProfileArr.sort((a, b) => (a.moodPoints > b.moodPoints) ? -1 : 1);
        break;
      case "gameMonths":
        this.playerProfileArr.sort((a, b) => (+a.gameNbrMonths > +b.gameNbrMonths) ? -1 : 1);
        break;
      default:
        break;
  }
  this.setState({rerender: true});
}

displayStatus(playerName)
  {
    for (let i=0; i<this.props.gameData.length; i++){
      let gameData = this.props.gameData[i];
      if (gameData.user === playerName){
           this.props.setParentInfo(gameData, "gameData");
           break;
      }
    }
      this.setState({openStatusModal: true});
  }

  openDiscussionPrompt(){
     this.setState({openDiscussionModal: true});
  }

  closeDiscussionPrompt(){
      this.setState({openDiscussionModal: false});
  }

closeStatusModal(){
      this.setState({openStatusModal: false});
}

   
render() {
      this.gameProfiles = this.playerProfileArr.map((playerProfile, index) =>
          <PlayerProfileDisplayCard playerProfile={playerProfile} key={index} index={index}
              onClick={(playerName) => this.displayStatus(playerName)}/>
              );
      
                        
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="lg" dialogClassName={this.gutters} scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Game Profile Summary - Number of Games Evaluated: &nbsp;
                  {this.props.gameData.length}&nbsp;&nbsp;&nbsp;
                  <label className="ml-5 font-weight-bold" htmlFor="player">Sort by:&nbsp;&nbsp;</label>
                      <select className="btn btn-sm btn-outline-secondary dropdown-toggle" id="sortParm"
                            onChange={() => this.sortProfileReport()}>
                        <option value="default">Player Name (default)</option>
                        <option value="cash">Cash-Descending</option>
                        <option value="salaryA">Salary-Ascending</option>
                        <option value="salaryD">Salary-Descending</option>
                        <option value="netIncomeA">Monthly Income-Ascending</option>  
                        <option value="netIncomeD">Monthly Income-Descending</option>  
                        <option value="job">Job Name</option>   
                        <option value="mood">Mood Points-Descending</option> 
                        <option value="gameMonths">Game Months-Descending</option>
                      </select>
               <div className="ml-5 text-left medium-large-text">
                  (Click on the player name to display the monthly status for that player).
                </div>  
              
                  
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
            <span>
                <table className="table">
                <tbody>
                    <tr className="header-sticky">
                        <th>Player</th>
                        <th>Nbr Months Played</th>
                        <th>Current Cash</th>
                        <th>Monthly Income</th>
                        <th>Savings Amt</th>
                        <th>Monthly Savings Amt</th>
                        <th>Mood Points</th>
                    </tr>
                        {this.gameProfiles}
                  </tbody>
               </table>
            </span>
                               
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
              <Button variant="secondary"  onClick={() => this.props.onClose()}>
                   Close
              </Button>
        </Modal.Footer>
       
    </Modal>

    {this.state.openStatusModal ?
        <MonthlyStatusModal modalOpen={this.state.openStatusModal} 
            screenSize={this.props.screenSize}
            onClose={() => this.closeStatusModal()}
            gameInfo = {() => this.props.parentInfo()}
        />
      :
        null
    }

    {this.state.openDiscussionModal ?
       <div>
        <DiscussionTextModal
            modalOpen = {this.state.openDiscussionModal}
            onClose={() => this.closeDiscussionPrompt()}
            />
        </div>
     :
        null  
  }
    
  </>
  }

  componentDidMount(){
    this._isMounted = true;
  }

}

export default GameProfileDisplayModal;