const friendVolunteerActivityDetailDialogDataset = 
        [
        // this has been changed so that there can be different dialog depending on the activity.
        // If no dialog is present for a specific activity and preference combination, then the
        // game will use the "default".
        {activityPreference: "2", activity: "default",
                comments: ["OK, now that you've completed some, can we go?", 
                           "I'm really bored, can we leave?", 
                           "This is kinda lame, maybe I'll just wait for you in the car.",
                           "This place smells kind of funny to me.",
                           "I'm really tired of standing on my feet.",
                ]},
        {activityPreference: "3", activity: "default",
                comments: ["You know, this isn't so bad.",
                           "What do you want to do when we're done with this?",
                           "This is OK, but I'm hoping we'll be done pretty soon.",
                           "They have a pretty efficient operation here, don't you think?",
                ]},
        {activityPreference: "4", activity: "default",
                comments: ["This is kind of fun.  Time is going by so quickly.", 
                           "I think I have a system so I'm getting faster.", 
                           "This is really fun, thanks for asking me to come along.",
                           "I could do this all day long. How about you?",
                           "It makes me feel really good when I do volunteer work."
                ]},

        //  no additional dialog for "CoatDrive"

       // "BuildHome" 
        {activityPreference: "2", activity: "BuildHome",
                comments: ["Ouch!  I just smashed my finger with a hammer.", 
                           "Who knew that building a home was such hard work?", 
                           "It's really hot today.  Why did we end up having to be the ones to work outside?",
                           "Argh!  I don't think we'll ever get done moving all of this lumber.",
                           "Next time remind me, I really don't want to volunteer to build a home again.",
              ]},
        {activityPreference: "3", activity: "BuildHome",
                comments: ["Wow!  I can't believe all of the work that goes into building a home.  Can you?",
                         "I get to help the electrician.  She's doing all the real work, but I'm learning so much!",
                         "I've been helping to unload stuff all day... I have to say, I am getting pretty tired.",
                         "Well, I think I've hit every finger with the hammer now, but... I'm still having fun!",
              ]},
        {activityPreference: "4", activity: "BuildHome",
                comments: ["I really like helping to build something, don't you?", 
                         "This has been a lot of fun.  I can't believe how much I've learned about building things.", 
                         "I can't believe we are actually helping someone to get a home of " +
                                "their own. So Cool!!",
                         "Thanks for inviting me!  I am really enjoying the idea of helping someone.",
              ]},
            
        //  "FoodDrive"
        {activityPreference: "2", activity: "FoodDrive",
                comments: ["This is such a waste of time, there has to be a better way to sort food.", 
                           "Why does the food have to be sorted anyway?  I mean, a can is a can, right?", 
                           "It's really hot in here and I have sorted a ton of food.  Can we go now?",
              ]},
        {activityPreference: "3", activity: "FoodDrive",
                comments: ["This is sort of fun.  Did you see how many people this food will help?  It is a lot.",
                         "Looking at all this food is making me hungry.  I'm hoping we can get lunch soon.",
                        
              ]},
        {activityPreference: "4", activity: "FoodDrive",
                comments: ["I am having a great time.  I already sorted 5 boxes of food.", 
                         "We all make a pretty good team.  Just look at how much food we've processed!", 
              ]},

        //  "DogWalker"
         {activityPreference: "2", activity: "DogWalker",
                comments: ["You do realize that some people get paid for walking dogs, right?", 
                           "Some of these dogs are really strong! I'm having a hard time not falling.", 
                           "OK, well, the dogs look cute, but I am really not enjoying the walking thing.",
              ]},
        {activityPreference: "3", activity: "DogWalker",
                comments: ["Some of these dogs are really strong.  I like walking them but I really have to hang " +
                              "on tight.",
              ]},
        {activityPreference: "4", activity: "DogWalker",
                comments: ["I love walking dogs.  These dogs are so cute and they really seem to enjoy " +
                                "being outside.", 
              ]},

        //  "SoupKitchen"
        
        ]
 
export default friendVolunteerActivityDetailDialogDataset;