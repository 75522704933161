import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CarValuesEstimatorArray from '../../datasets/car_values_dataset'
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import {monthlyLoanAmt} from '../../objects/AssetsExpenses/loanAmtCalc_function';
import {transportCostCalcAmt} from '../../objects/monthlyCalc/transportCostCalc';
import ConfigFile from '../../datasets/config_file';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import FriendDisplayModal from '../shared-components/friend_display_modal';
import { calcNbrFriendsMet } from './objects/calc_nbr_friends_met';
import { calcMonthlyIncome } from '../../objects/CommonUse/calcMonthlyIncome';
import determineMoodPoints from '../../objects/CommonUse/determine_mood_points';
import createMoodPointArr from '../shared-components/objects/create_mood_point_arr';
import TwoColumnTableCard from './two_column_table_card';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import buildArrayForDisplay from '../../objects/CommonUse/build_array_for_display';
import TableDisplayCard from '../shared-components/table_display_card';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import { loanBalanceCalcFunction } from '../../objects/AssetsExpenses/loan_balance_calc_function';
import DropDownOptionCard from '../shared-components/drop_down_option_card';
import { calcNbrMonthsBtwnDates } from '../../objects/CommonUse/nbr_months_btwn_dates_calc';

class CarChangeModal extends Component {
  constructor(props) {
    super(props);
    this.gameInfo = this.props.gameInfo();
    this.handleClick = this.handleDownPaymentChange.bind(this);
    this.state={
         entryTab: [],
         loanType: "Car",
         headers: setHeadersForFetch(),
         allowConfirmBtn: false,
         carDataLoaded: false,
         loanProcessFee: ConfigFile.carItems.carLoanProcessFee,
         carIntRate: ConfigFile.carItems.carInterest,
         carLoanTerm: ConfigFile.carItems.carLoanTerm,
         openFriendModal: false,
         emptyArr: [],
         modalType: "",
         subProcess: "carChangeModal",
         environment: Config.dataURL.currentEnv,
    };
    this.downPaymentAmt = 0;    
    this.downPaymentChanged = true;
    this.cashDownOptionsArr = [];
    this.carDataCurrent = null;
    this.currentCarId = null;
    this.newCar = {carCost: 0, carDesc: "", carId: "", carMPG: 0, carYearAdj: 0, carSize: 0,
      carAgeNbr: 0,};
    this.getCurrentCarData();
  }

  determineCarChangeAmts(){
      this.errorMsg = "";
      this.getNewCarData();
      this.getGameData();
      this.determineNewCarYear();     
      if (this.currentCarId === this.newCar.carId &&
          this.nbrCarMonths <= 12)  {  
          this.setState({modalType: "SameCar"});
      }else{
          if (this.downPaymentChanged === true){
              this.determineCurrentCarLoanAmt();
              this.calculateTradeInValue();
              this.checkIfNewLoanRequired();
              this.determineCarChangeMoodPoints();
              this.setState({allowConfirmBtn: true});
              const monthlyDataObj = {currentCarLoanAmt: this.currentCarLoanAmt, monthlyIncome: this.monthlyIncome,
                  carCost: this.newCar.carCost, totalCurrentCarLoanAmt: this.totalCurrentCarLoanAmt,
                  tradeInAmt: this.tradeInAmt, downPaymentAmt: this.downPaymentAmt, loanAmt: this.newCarLoanAmt,
                  monthlyLoanAmt: this.monthlyLoanAmt}
              this.monthlyamtArr = buildArrayForDisplay("CarChange", "CarMonthlyAmts", monthlyDataObj);
              const cashAmtObj = {currentCash: this.gameInfo.gameData.currentCash, loanProcessAmt: this.loanProcessAmt,
                  downPaymentAmt: this.downPaymentAmt, refundAmt: this.refundAmt, changeCashAmt: this.changeCashAmt}
              this.cashAmtArr = buildArrayForDisplay("CarChange", "CarCashAmts", cashAmtObj);
   
          }else{
              this.setState({allowConfirmBtn: false});
          }
          this.setState({modalType: "CalculationsComplete"});
      }
  }
  
  getNewCarData() {
    this.newCar.carCost = sessionStorage.getItem("carCost");
    this.newCar.carDesc = sessionStorage.getItem("carName");
    const carDescArr = this.newCar.carDesc.split(' - ');
    this.newCarType = carDescArr[0];
    this.newCar.carId = sessionStorage.getItem("carChoice");
    this.newCar.carMPG = sessionStorage.getItem("carMPG");
    this.newCar.carYearAdj = sessionStorage.getItem("carYearAdj");
    this.newCar.carSize = sessionStorage.getItem("carSize");
    this.newCar.carAgeNbr = sessionStorage.getItem("carAgeNbr");
  }
  
  getGameData() {
      const gameData = this.gameInfo.gameData;
      this.loanTable = gameData.loanTable;
      this.expenseTable = gameData.expenseTable;
      this.currentCarYear = gameData.carInfo.carYear; 
      this.currentGameDate = gameData.currentGameDate; 
      var yearMonthVals = this.currentGameDate.split('-'); 
      this.currentGameYear = +yearMonthVals[0];
      var yearMonthVals = gameData.startGameDate.split('-');
      this.startGameYear = +yearMonthVals[0];
      this.startGameDate = gameData.startGameDate;
  }

  determineNewCarYear(){
      const currentGameYear = this.currentGameDate.substr(0,4);
      this.newCarYear = (+currentGameYear - +this.newCar.carYearAdj).toString();
      sessionStorage.setItem("changeCarYear", this.newCarYear);
      let changeCarAgeNbr = (+currentGameYear - +this.newCarYear) * 12;
      sessionStorage.setItem("changeCarAgeNbr", changeCarAgeNbr);
      if (this.gameInfo.gameData.carInfo.carGameDate === undefined){
          this.nbrCarMonths = 13;
      }else{
          const carDate = this.gameInfo.gameData.carInfo.carGameDate;
          this.nbrCarMonths = calcNbrMonthsBtwnDates(carDate, this.currentGameDate);
      }
  }

  checkIfNewLoanRequired(){
      // max downpayment is current loan balance + new car value minus the tradeInAmt
      // for the current car
      this.maxDownPayment = (+this.totalCurrentCarLoanAmt + +this.newCar.carCost -
          this.tradeInAmt).toFixed(2);
          
      if (this.newLoanRequired === false){
          this.newCarLoanAmt = 0;
          this.loanProcessAmt = 0;
          this.monthlyLoanAmt = 0;
          this.refundAmt = this.tradeInAmt;
      }else{
          this.calculateNewTotalCarLoanAmt();
          this.calculateNewMonthlyPayAmt();
          this.determineCashDownOptions();
          this.determineLoanMessage();
      }
      this.calculateChangeToCashAmt();
      const estMonthlyIncome = +this.monthlyIncome + +this.currentCarLoanAmt - +this.monthlyLoanAmt;
      if (estMonthlyIncome < 0 && estMonthlyIncome < +this.monthlyIncome ){
          this.errorMsg = "Purchasing this car would cause your monthly income to be negative.  You must choose" +
            "another car."
      }
 
  }

  determineLoanMessage(){
      this.newLoanRequired = true;
      if (this.downPaymentAmt === this.currentCarLoanAmt && this.currentCarLoanAmt > 0){
            this.loanMessage= "Amount of cash down will pay off the current car loan.";
      }else{
            if (this.downPaymentAmt === this.maxDownPayment){
              if (this.currentCarLoanAmt === 0){
                this.loanMessage= "Amount of cash down will pay for new car outright " +
                  "(no loan required)."
              }else{
                this.loanMessage= "Amount of cash down will pay off current loan and " +
                  "purchase the car outright (no new loan required)."
              }
              this.newLoanRequired = false;
            }else{
              if (this.currentCarLoanAmt === 0){
                  if (this.downPaymentAmt === 0){
                      this.loanMessage = "A car loan will be created for this purchase.";
                  }else{
                    this.loanMessage= "Amount of cash down will reduce the amount of the new car loan."
                  }
                }else{
                  this.loanMessage=  "Your current car loan will be closed out and a new loan will be created."; 
              }
            }
      }
      if (this.newLoanRequired === true){
            this.confirmMessage = 
                "Click the 'Continue/Accept' button to accept the terms of this new loan."
      }else{
            this.confirmMessage = 
                "Click the 'Continue/Accept' button to complete the purchase of this car."
      }
  }

  determineCashDownOptions(){
        this.cashDownOptionsArr = [];
        let value = 0;
        let selection = "no cash payment amount";
        this.addToCashOptionsArr(value, selection);
        value = 5000;
        let maxDownPayment = this.maxDownPayment;
        if (this.maxDownPayment > this.gameInfo.gameData.currentCash){
            maxDownPayment = this.gameInfo.gameData.currentCash;
        }
        while (value < maxDownPayment){
            selection = "Put " + doubleToStringMonthly(value) + " Down on the car";
            this.addToCashOptionsArr(value, selection);
            value += 5000;
        }
        if (this.totalCurrentCarLoanAmt > 0 &&
            +this.gameInfo.gameData.currentCash > this.totalCurrentCarLoanAmt){
              value = this.totalCurrentCarLoanAmt;
              selection = "Pay Off the Current Car Loan Balance";
              this.addToCashOptionsArr(value, selection);
              //  the following confirms there is enough cash to pay off current loan
              //  and buy the new car (no loan required)
              if (+maxDownPayment >= +this.maxDownPayment){
                  value = +this.maxDownPayment;
                  selection = "Pay Off Current Car Loan and Pay For New Car";
                  this.addToCashOptionsArr(value, selection);
              }
        }else{
            if (this.gameInfo.gameData.currentCash > this.newCarLoanAmt &&
                this.totalCurrentCarLoanAmt === 0){
                value = this.newCarLoanAmt;
                selection = "Pay Full Amount of New Car (no loan)";
                this.addToCashOptionsArr(value, selection);
          }
        }
  }

  addToCashOptionsArr(value, selection){
    const cashDownObj = {value: value, selection: selection};
    this.cashDownOptionsArr.push(cashDownObj);
  }

  calculateNewTotalCarLoanAmt(){
       this.loanProcessFee = 0;
       this.newCarLoanAmt = this.newCar.carCost - this.tradeInAmt + 
          this.totalCurrentCarLoanAmt - +this.downPaymentAmt; 
       this.loanProcessAmt = +this.newCar.carCost * +this.state.loanProcessFee;
  }

  calculateChangeToCashAmt(){
      this.changeCashAmt = -1 * (this.loanProcessAmt + this.downPaymentAmt); 
      this.refundAmt = 0;
      if (this.newCarLoanAmt < 0){
          this.refundAmt = -1 * this.newCarLoanAmt;
          this.loanProcessAmt = 0;
          this.newCarLoanAmt = 0;
      }else{
        if (this.newCarLoanAmt === 0){
            this.refundAmt = this.tradeInAmt;
        }
      }
      this.newCashAmt = (+this.gameInfo.gameData.currentCash + this.changeCashAmt).toFixed(2);
      if (this.newCashAmt < 0){
         this.errorMsg = "Change to cash exceeds current cash amount. You must select a different car"
         if (this.downPaymentAmt > 0){
            this.errorMsg = this.errorMsg + " or reduce the downpayment amount."
         }else{
            this.errorMsg = this.errorMsg + '.';
         }
      }
      sessionStorage.setItem("changeCarNewCashAmt", this.newCashAmt);
  }

  calculateTradeInValue(){
    this.tradeInArray = CarValuesEstimatorArray;
    let nbrYearsOld = parseFloat(this.currentGameDate.substr(0,4)) - +this.currentCarYear;
    if (nbrYearsOld < 0){
      nbrYearsOld = 0;
    }
    this.tradeInAmt = 0;
    var sizeFoundInTable = false;
    for (let i=0; i<this.tradeInArray.length && sizeFoundInTable == false; i++) {
       if (this.carDataCurrent.sizeNbr == this.tradeInArray[i].carSizeNbr) {
           sizeFoundInTable = true;
             if (nbrYearsOld <= this.tradeInArray[i].pctTradeInArr.length) {
               let tradeInArray = this.tradeInArray[i].pctTradeInArr;
               this.tradeInAmt = this.carDataCurrent.cost * tradeInArray[nbrYearsOld];
             }
       }
     }
     if (sizeFoundInTable == false){
         this.tradeInAmt = this.carDataCurrent.cost * .90;
     }
     this.tradeInAmt = this.tradeInAmt - 2000;
  }

  determineCurrentCarLoanAmt() {
      this.currentLoanSw = false;
      this.totalCurrentCarLoanAmt = 0;
      this.currentCarLoanAmt = 0;
      for (let i=0; i<this.loanTable.length; i++) {
           // assumes only one active car loan for any given player.
           if (this.loanTable[i].loanType == "Car Loan" && 
              this.loanTable[i].loanNbrMonths != "0") {
                 if (+this.loanTable[i].loanNbrMonths > 0) {
                    this.currentLoanSw = true;
                    this.currentCarLoanAmt = this.loanTable[i].loanAmt;
                    this.totalCurrentCarLoanAmt = +loanBalanceCalcFunction(this.loanTable[i].loanTotAmt,
                      this.loanTable[i].loanIntRate, this.loanTable[i].loanAmt, this.loanTable[i].loanTerm,
                      this.loanTable[i].loanNbrMonths);
                }
           }
      }
  }

  calculateNewMonthlyPayAmt(){
      if (this.newCarLoanAmt > 0){
          let loanAmt = this.newCarLoanAmt;
          let nbrYears = this.state.carLoanTerm;
          let intRate = this.state.carIntRate;
          let loanType = "car";
          this.monthlyLoanAmt = monthlyLoanAmt(loanAmt, intRate, nbrYears, loanType)
      }else{
          this.monthlyLoanAmt = 0;
      }
      let monthlyLoanDifferenceAmt = +this.monthlyLoanAmt - +this.currentCarLoanAmt;
      let incomeObj = calcMonthlyIncome(this.gameInfo.gameData.assetTable,
            this.gameInfo.gameData.expenseTable, this.gameInfo.gameData.loanTable);
      this.monthlyIncome = incomeObj.income;
      if (this.monthlyIncome < monthlyLoanDifferenceAmt){
            this.warningMessage = "Warning: The monthly cost of this car (together with other " +
              "monthly expenses) will exceed your monthly net income.";
      }
   }
    
  determineCarChangeMoodPoints(){
    let gameChoiceArr = [];
    this.startingMoodPoints = ConfigFile.gameChoice.carChangeMood;
    gameChoiceArr = createMoodPointArr("car");
    let moodObj = {};
    this.moodPointStartArr = determineMoodPoints(gameChoiceArr);
    moodObj = {choiceType: "baseAmt", label: "Starting Car Change Mood Points", number: this.startingMoodPoints,
      bold: true};
    this.moodPointStartArr.splice(0, 0, moodObj);
    this.totalMoodPoints = 0;
    for (let i=0; i<this.moodPointStartArr.length; i++){
         this.totalMoodPoints += +this.moodPointStartArr[i].number;
    }
    moodObj = {choiceType: "total", label: "Mood Point Change", number: this.totalMoodPoints,
      bold: true};
    this.moodPointStartArr.push(moodObj);
    if (this.totalMoodPoints > 0){
        this.moodPointText = "Your choice of a new car will increase your total mood points."
    }else{
        this.moodPointText = "You are happy to get a different car, but your choice of car " +
          "will not increase your mood points."
    }
    sessionStorage.setItem("carChangeMoodPoints", this.totalMoodPoints);
  }
   
   createNewTableObjects() {
      this.createNewLoanTableObject();
      this.createNewExpenseTableObject();
      this.props.onConfirmCarChange();
   }

   createNewLoanTableObject() {
    this.newLoanTable = this.loanTable;
    for (let i=0; i<this.newLoanTable.length; i++) {
        if (this.newLoanTable[i].loanType == "Car Loan" && 
            this.newLoanTable[i].loanNbrMonths != "0") {
               this.newLoanTable[i].loanNbrMonths = 0;          
        }
    }
    if (this.newCarLoanAmt > 0){
      let loanNbrMonths = this.state.carLoanTerm * 12;
      let newLoanObj = {loanType: "Car Loan", loanDesc: this.newCar.carDesc, 
          loanAmt: this.monthlyLoanAmt, loanStartDate: this.currentGameDate, 
          loanNbrMonths: loanNbrMonths, loanTotAmt: this.newCarLoanAmt.toFixed(2), 
          loanIntRate: this.state.carIntRate, loanTerm: this.state.carLoanTerm};
        this.newLoanTable.push(newLoanObj);
    }
    sessionStorage.setItem("changeCarLoanDataObjects", JSON.stringify(this.newLoanTable));
   }

   createNewExpenseTableObject(){
      this.newExpenseTable = this.expenseTable;
      for (let i=0; i<this.newExpenseTable.length; i++){
          if (this.newExpenseTable[i].expItem == "Transportation" && 
              this.newExpenseTable[i].expNbrMonths != "0") {
                this.newExpenseTable[i].expNbrMonths = 0;
          }
      }
      this.newTransportationExpenseObj = transportCostCalcAmt(this.newCar.carMPG, this.currentGameDate);
      this.newExpenseTable.push(this.newTransportationExpenseObj);
      sessionStorage.setItem("changeCarExpenseDataObjects", JSON.stringify(this.newExpenseTable));
  }
  
  render() { 
    if (this.state.carDataLoaded == true && this.state.modalType != "CalculationsComplete" &&
        this.state.modalType != "SameCar"){
        this.determineCarChangeAmts();
    }
    this.cashDownOptions = this.cashDownOptionsArr.map((optionsList,index) =>
          <DropDownOptionCard options={optionsList} key={index} />
        );
    if (this.state.modalType == "CalculationsComplete" && this.downPaymentChanged === true){
        this.nbrFriendsMet = calcNbrFriendsMet(this.gameInfo.gameData.friendList); 
        this.monthlyCosts = this.monthlyamtArr.map((amountArr,key) =>
          <TableDisplayCard key={key}  outInfo={amountArr}  />
        );
        this.cashAmounts = this.cashAmtArr.map((amountArr,key) =>
          <TableDisplayCard key={key}  outInfo={amountArr}  />
        );
        this.moodPointList = this.moodPointStartArr.map((moodPoints,key) =>
          <TwoColumnTableCard key={key} infoType="label-number" outInfo={moodPoints}  />
        );
        this.downPaymentChanged = false;
    }
  return <>
   
   <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="md" scrollable="true"
        animation={false}>
        <Modal.Header  className="gameChoiceModalHdrFtrBackground" closeButton>
          <Modal.Title>Cost Information for Changing Cars -
            {this.newCarType} - {this.newCarYear}</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
       
            {this.state.modalType == "TrainVideo"  ?
              <span>
                  <div className="text-white mt-4">xxxxxx</div>
                  <div className="text-white mt-4">xxxxxx</div>
                            <WaitTimeSpinner />
                  <div className="text-white mt-4">xxxxxx</div>
                  <div className="text-white mt-4">xxxxxx</div>
                  <div className="text-white mt-4">xxxxxx</div>
              </span>
          :
          <span>
          {this.state.modalType === "SameCar" ?
          <div>
              <p>The car that you have selected is the same type and the same year
                 as your current car.  Please click on the 'close' button and then select
                 another option.
              </p>
            </div>
        :
             
            <span>
            <div className="mt-2 mx-2">
                <div>You can choose to enter a cash payment amount if you want to put some cash down on this
                         car in order to reduce the loan amount or purchase the car outright. 
                </div>
                <div className="mx-2">
                      <b>Current Cash: &nbsp;</b>
                        {doubleToStringMonthly(this.gameInfo.gameData.currentCash)}
                </div>
                <label className="font-weight-bold mx-2" htmlFor="downPayment">Cash Down:&nbsp;</label>
                  <select onChange={(val) => this.handleDownPaymentChange(val.target.value)}
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="downPayment"  >
                         {this.cashDownOptions}
                  </select>
            </div>
            {this.currentLoanSw || this.newLoanRequired ?
                <div className="mx-2 mt-1">
                    {this.loanMessage}
                    {this.state.allowConfirmBtn   ?
                          <span>
                              &nbsp;&nbsp;{this.confirmMessage}
                         </span>
                    :
                          null
                    }
                </div>
            :
                null
            }
            <h5 className="text-center">Car Monthly Amounts</h5>  
            <table className="table table-hover">
              <tbody>
            
                {this.monthlyCosts}
               
              </tbody>
            </table>
            <h5 className="text-center">Changes to Cash Amounts</h5>  
            <table className="table table-hover">
              <tbody>
                {this.cashAmounts}
              </tbody>
            </table>

            <h5 className="text-center">Mood Point Calculation</h5>
              <div>{this.moodPointText}</div>
                  <table className="table table-hover">
                    <tbody className="row-100">
                      <tr>
                        <th className="text-center p-0">Description</th>
                        <th className="text-right p-0">Mood Points</th>
                      </tr>
                      {this.moodPointList}
                    
                    </tbody>
                  </table>

              <div className="text-danger my-2">
                    {this.warningMessage}
              </div>
            
              {this.state.openFriendModal ?
                  <FriendDisplayModal  show={this.state.openFriendModal} 
                      title={this.newCar.carDesc}
                      gameProcess="GameViews"
                      askAFriendInfo={this.askAFriendInfo}
                      friendData={this.state.emptyArr}
                      onClose={() => this.closeFriendModal()} 
                      gameInfo = {() => this.props.gameInfo()}
                      />
              :
                null
              }
          </span>
            }
            </span>
          }
          </Modal.Body>
          <Modal.Footer  className="gameChoiceModalHdrFtrBackground">
            {this.nbrFriendsMet > 0 ?
                <Button variant="info" className="textLeft"  onClick={() => this.displayFriendModal()}>
                    Ask a Friend
              </Button>
            :
                null
            }
           
            <Button variant="secondary"   onClick={() => this.props.onClose()}>
                Cancel/Close
            </Button>
            {this.errorMsg === ""  && this.state.modalType !== "SameCar" ?
              <Button variant="primary"  onClick={() => this.createNewTableObjects()}>
                  Continue/Accept
              </Button>
            :
              <div className="text-danger">{this.errorMsg}</div>
            
            }
          </Modal.Footer>
        </Modal>
  
    </>
  }
  
  componentDidMount(){
    this._isMounted = true;
  }
  
  componentWillUnmount(){
    this._isMounted = false;
  }
  
  displayFriendModal(){
    this.state.openFriendModal = true;  
    this.askAFriendInfo = {topic: "carChange", carAgeType: this.newCar.carAgeNbr,
      carSelectType: this.newCar.carSize}
  }

  closeFriendModal(){
    this.state.openFriendModal = false;
  }
  
  handleDownPaymentChange(downPayment){
      this.downPaymentAmt = +downPayment;
      this.downPaymentChanged = true;
      this.setState({modalType: ""});
  }
  
  getCurrentCarData() {
    this.state.modalType = "TrainVideo";
    this.currentCarId = this.gameInfo.gameData.carInfo.carID; 
    const funcName = "carInfo";
    const urlParm = "?carId=" + this.currentCarId;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'GET', headers: this.state.headers})
        .then(response => {
          if (response.ok){
              response.json().then(data => {
              const output = JSON.parse(data);
              const statusCode = output.statusCode;
              if (statusCode == 200){
                this.carDataCurrent = output.body;
                if (this._isMounted === true){
                  this.setState({carDataLoaded: true});
                }
                }else{
                  const errorObj={function: funcName, subProcess: this.state.subProcess, 
                      status: statusCode, message: output.body.message};
                  this.props.processError(errorObj);
              }
          });
      }
      });
  }
 
}
export default CarChangeModal;