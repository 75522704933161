
export const setModalSize = (screenSize) => {

      let modalSize = ""
      switch (screenSize){
        case "narrow":
            modalSize = "modal-90";
            break;
        case "medium":
            modalSize = "modal-80";
            break;
        case "smallMedium":
            modalSize = "modal-95";
            break;
        default:
            break;
      }
       
   return modalSize;
}

export default setModalSize;