import {Component} from 'react';
import SkillsInterestInput from '../getting-started/skills_interests_input';
import { withRouter } from "react-router";
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class ChangeSkillsSurvey extends Component {
  
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            openCarChangeModal: false,
            headers: setHeadersForFetch(),
            subProcess: "changeSkillsSurvey",
            environment: Config.dataURL.currentEnv,
        }
    }    

    render() {
    return <>
            <SkillsInterestInput 
               onOpen={() => this.skillSurveyOpen()}
               completed={() => this.onSkillsSurveyComplete()} 
               skillSurvey = {this.gameInfo.gameData.skillSurvey}
               fromProcess = "GameView" />
    </>
    }
   
    skillSurveyOpen(){
        // this is required in the getting started part of the game but no action is required here
    }

    onSkillsSurveyComplete() {
        this.checkSkillsSurvey();
        if (this.skillSurveyChanged == true){
            this.updateSkillSurvey();
            this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
            this.props.history.push('/change-career');
        }else{
            this.props.history.push('/game-choices')
        }
    }
    
    checkSkillsSurvey(){
        const skillSurveyString = sessionStorage.getItem('skillsInterestsInput');
        const currentSkillSurveyString = this.gameInfo.gameData.skillSurvey.join();
        if (skillSurveyString == currentSkillSurveyString){
             this.skillSurveyChanged = false;
        }else{
             this.skillSurveyChanged = true;
            const newSkillSurvey = skillSurveyString.split(',');
            this.gameInfo.gameData.jobInfo.nbrSkillSurvey = 
                (+this.gameInfo.gameData.jobInfo.nbrSkillSurvey + 1).toString();
        
            this.gameInfo.gameData.skillSurvey = newSkillSurvey;
            this.props.setGameInfo(this.gameInfo.gameData, "gameData");
            this.bodyObj = {
                "skillSurvey": newSkillSurvey, 
                "jobInfo": JSON.parse(JSON.stringify(this.gameInfo.gameData.jobInfo))};
        }
    }
                 
    updateSkillSurvey()
    {   
        const funcName = "updateSkillSurvey";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'PUT', headers: this.state.headers,
            body: JSON.stringify(this.bodyObj)})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        //  update of gameData was successful
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                        this.props.processError(errorObj);
                    }
                });
            }
        });
    }
    
}
export default withRouter(ChangeSkillsSurvey);