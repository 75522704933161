const difficultGameChoiceData = 
{
    "user": "student6",
    "jobInfo": {
      "careerID": "613105ca2afc7f761668ac03",
      "healthInsInd": "false",
      "jobRank": "8",
      "nbrYearsCollege": "0",
      "nbrSkillSurvey": "1",
      "jobStartDate": "2024-12"
    },
    "homeInfo": {
      "homeID": "61323ec697d929c9a8cf8706",
      "homeType": "apartment",
      "roommates": "0",
      "bedrooms": "1"
    },
    "furnitureID": "6132a4c097d929c9a8cf8719",
    "carInfo": {
      "carID": "6132c54897d929c9a8cf8729",
      "carAge": 0,
      "carYear": "2024",
      "carType": "200",
      "carGameDate": "2024-12"
    },
    "startingCash": "19425.00",
    "currentCash": "19425.00",
    "currentMoodPoints": "17000",
    "startGameDate": "2024-12",
    "currentGameDate": "2024-12",
    "assetTable": [
      {
        "assetItem": "Job Salary",
        "assetDesc": "Cashier --> Annual Salary: $21,999.96",
        "assetAmt": "1833.33",
        "assetStartDate": "2024-12",
        "assetNbrMonths": "-1"
      }
    ],
    "expenseTable": [
      {
        "expItem": "Payroll taxes",
        "expDesc": "Federal, FICA (Social Security)",
        "expAmount": "190.83",
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": false
      },
      {
        "expItem": "Apartment Rent",
        "expDesc": "Sunnyside-1 bedroom, 0 roommates",
        "expAmount": 885,
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": true
      },
      {
        "expItem": "Health Insurance",
        "expDesc": "Health Insurance 100% paid by employee",
        "expAmount": 370,
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": false
      },
      {
        "expItem": "Automobile Insurance",
        "expDesc": "Insurance for car repair or replacement",
        "expAmount": 75,
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": false
      },
      {
        "expItem": "Renter's Insurance",
        "expDesc": "Insurance for furniture and other personal items",
        "expAmount": 30,
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": false
      },
      {
        "expItem": "Transportation",
        "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
        "expAmount": "115.56",
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": true
      },
      {
        "expItem": "Misc Expenses",
        "expDesc": "Food, Clothes, Personal Items, etc.",
        "expAmount": 380,
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": true
      }
    ],
    "loanTable": [
      {
        "loanType": "Car Loan",
        "loanDesc": "Mid-Size Car - Brand New",
        "loanAmt": "525.10",
        "loanStartDate": "2024-12",
        "loanNbrMonths": 72,
        "loanTotAmt": 28344,
        "loanIntRate": 0.1,
        "loanTerm": 6
      },
      {
        "loanType": "College Loan",
        "loanDesc": "No college is required for the job that you selected",
        "loanAmt": "0.00",
        "loanStartDate": "2024-12",
        "loanNbrMonths": 0,
        "loanTotAmt": "0.00",
        "loanIntRate": 0.035,
        "loanTerm": 0
      }
    ],
    "skillSurvey": [
      "5",
      "5",
      "5",
      "5",
      "3",
      "3",
      "3",
      "3",
      "3",
      "3",
      "3",
      "3"
    ],
    "conditionsTable": [
      {
        "condName": "homeType-apartment",
        "conditionClass": "home"
      },
      {
        "condName": "cashier",
        "conditionClass": "career"
      },
      {
        "condName": "no-december-holiday-selected",
        "conditionClass": "game"
      },
      {
        "condName": "no-friend-purchases",
        "conditionClass": "game"
      }
    ],
    "gameNbrMonths": "360",
    "nbrMonthsNegative": "0",
    "nbrMonthsMoodNegative": "0",
    "unlockedFeatures": [],
    "friendList": [
      {
        "name": "Charlie",
        "introDate": "",
        "dialogIndx": "firstTime",
        "rltnshpScore": "0",
        "totalLoanAmt": "0"
      },
      {
        "name": "Cameron",
        "introDate": "",
        "dialogIndx": "firstTime",
        "rltnshpScore": "0",
        "totalLoanAmt": "0"
      },
      {
        "name": "Jennifer",
        "introDate": "",
        "dialogIndx": "firstTime",
        "rltnshpScore": "0",
        "totalLoanAmt": "0"
      },
      {
        "name": "Cindy",
        "introDate": "",
        "dialogIndx": "firstTime",
        "rltnshpScore": "0",
        "totalLoanAmt": "0"
      }
    ],
    "spendingHistory": {
      "currentMonthSpendAmt": 0,
      "current10YearSpendAmt": 3575,
      "current10YearMonths": 1,
      "previous10YearSpendAmt": 0,
      "previous10YearMonths": 0
    },
    "moodPointHistory": {
      "currentMonthMoodPoints": 0,
      "totalMoodPoints": 0,
      "moodPointMonths": 1
    },
    "iconTable": [
      {
        "iconName": "gameBasicHelp",
        "iconCategory": "information",
        "className": "small_sign_thumbnail_img information-sign-position",
        "imageSrc": "gameHelp.png",
        "validInd": true,
        "firstTime": true,
        "additionalInfo": ""
      }
    ],
    "badgesEarned": [],
    "prevGameMonths": "0",
    "prevNbrGames": "0",
    "gameTemplateName": "difficult",
    "hand": [],
  }

  export default difficultGameChoiceData;