import {Component} from 'react';
import HomeInput from '../shared-components/home_input';
import { withRouter } from "react-router";
import HomeChangeModal from '../game-views/home_change_modal';
import { removeConditionsTable } from './objects/remove_Conditions_Table_function';
import { setupConditionsUpdate } from './objects/set_up_conditions_update_function';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { resetPicturesForChanges } from './objects/reset_pictureInfo_for_changes';
import { getHomePicture} from './objects/get_home_picture';
import {setEventTrackerObj} from '../../objects/CommonUse/set_event_tracker_obj';
import {getCurrentDateTime} from '../../objects/CommonUse/get_current_date_time';
import { findRemoveTableItem } from '../../objects/AssetsExpenses/find_remove_table_item';
import { calcInflationAmt } from '../../objects/CommonUse/calc_inflation_amt';
import { calcMonthlyIncomeMinusHomeCosts } from './objects/calc_monthly_income_minus_home_costs';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import { loanBalanceCalcFunction } from '../../objects/AssetsExpenses/loan_balance_calc_function';

class HomeChange extends Component {
  
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            openHomeChangeModal: false,
            monthlyIncomeMinusRoommateAmts: 0,
            monthlyIncomeMinusHomeCosts: 0,
            headers: setHeadersForFetch(),
            conditionList: null,
            disableSelect: false,
            homeProfit: 0,
            subProcess: "homeChange",
            environment: Config.dataURL.currentEnv,
            previousHomeName: this.gameInfo.pictureInfo.homeName
        }
        this.loadProcess();
    }    
    
    loadProcess(){
        this.loadConditions();
        this.calcSavingsIncome();
    }
    
    loadConditions()
    {
        const funcName = "eventConditionsList";
        const urlParm = "?triggeredByList=monthly,homeChange";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
            {method:'GET', headers: this.state.headers})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.state.conditionList = output.body;
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
        }
        });
    }

    calcSavingsIncome(){
        let homeType = this.gameInfo.gameData.conditionsTable.find(elem => 
                elem.condName === "homeType-apartment" || elem.condName === "homeType-house");
        this.state.homeProfit = 0;
          
        //  calculate monthly income that does not include any of the current housing
        //  costs 
        let monthlyIncomeObj = calcMonthlyIncomeMinusHomeCosts(homeType, 
            this.gameInfo.gameData.assetTable, this.gameInfo.gameData.expenseTable,
            this.gameInfo.gameData.loanTable);
        this.state.monthlyIncomeMinusRoommateAmts = +monthlyIncomeObj.monthlyIncomeMinusRoommateAmt;
        this.state.monthlyIncomeMinusHomeCosts = +monthlyIncomeObj.monthlyIncomeMinusHomeCosts;
        if (homeType.condName == "homeType-house"){
            this.determineProfitFromCurrentHome();
        }
        this.savings = (+this.gameInfo.gameData.currentCash + +this.state.homeProfit).toFixed(2);
        if (this.gameInfo.savingsInfo.totalSavings != undefined){
            this.savings =  +this.savings + +this.gameInfo.savingsInfo.totalSavings;
        }
    }

    determineProfitFromCurrentHome(){
        //  calculate profit from sale of current home - this increases the amount
        //  available for a down payment.
        let currentMortgageObj = findRemoveTableItem(this.gameInfo.gameData.loanTable,
             "mortgage", false);
        let nbrMonths = (currentMortgageObj.loanTerm * 12) - currentMortgageObj.loanNbrMonths;  
        let nbrYears = (+nbrMonths / 12).toFixed(0);       
        this.outstandingHomeAmt = +loanBalanceCalcFunction(currentMortgageObj.loanTotAmt,
            currentMortgageObj.loanIntRate, currentMortgageObj.loanAmt, currentMortgageObj.loanTerm,
            currentMortgageObj.loanNbrMonths);
        if (nbrYears > 0){
            this.currentHomeSellingPrice = +calcInflationAmt(currentMortgageObj.loanTotAmt,
                nbrYears)
        }else{
            this.currentHomeSellingPrice = +currentMortgageObj.loanTotAmt;
        }
        this.state.homeProfit = (this.currentHomeSellingPrice - this.outstandingHomeAmt).toFixed(2);
    }

    onHomeSelected() {
        this.state.openHomeChangeModal = true;
        this.state.newRentAmt = sessionStorage.getItem("homeCost");
        this.state.newRoommates = sessionStorage.getItem("homeRoommates");
        this.state.newBedrooms =  sessionStorage.getItem("homeBedrooms");
        this.state.newHomeName= sessionStorage.getItem("homeName");
    }

    render() {
        this.gameStarted = true;
         
    return <>
            
            {this.state.openHomeChangeModal == true ?
                <HomeChangeModal show={this.state.openHomeChangeModal} 
                    homeProfit={this.state.homeProfit}
                    currentHomeSellingPrice={this.currentHomeSellingPrice}
                    currentLoanOutstandingAmt={this.outstandingHomeAmt}
                    monthlyIncomeMinusRoommateAmts={this.state.monthlyIncomeMinusRoommateAmts}
                    monthlyIncomeMinusHomeCosts={this.state.monthlyIncomeMinusHomeCosts}
                    gameInfo = {() => this.props.gameInfo()}
                    setGameInfo = {(value, propertyName) => 
                            this.props.setGameInfo(value, propertyName)}
                    onAlert={() => this.sendAlert()}
                    onClose={() => this.closeHomeChangeModal()} 
                    onCancel={() => this.cancelHomeChange()}
                    onConfirmHomeChange={() => this.onConfirmHomeChange()}
                    processError = {(errorObj) => this.props.processError(errorObj)}
                />
            :
                <div></div>
            }
            <HomeInput onSelected={() => this.onHomeSelected()}
                screenSize = {this.props.screenSize}
                gameStarted = {this.gameStarted}
                currentGameDate = {this.gameInfo.gameData.currentGameDate}
                startGameDate = {this.gameInfo.gameData.startGameDate}
                nbrBedrooms={this.gameInfo.gameData.homeInfo.bedrooms}
                nbrRoommates={this.gameInfo.gameData.homeInfo.roommates}
                currentHomeID={this.gameInfo.gameData.homeInfo.homeID}
                savings = {this.savings}
                monthlyIncome={this.state.monthlyIncomeMinusHomeCosts}
                homeName={this.gameInfo.pictureInfo.homeName}
                processError = {(errorObj) => this.props.processError(errorObj)}
            /> 
      </>
    }

    sendAlert(){
        alert("The home/roommates you selected is the same as your current home/roommates.") 
        this.state.openHomeChangeModal = false;   
    }
   
    closeHomeChangeModal() {
        this.state.openHomeChangeModal = false;
    }
    
    cancelHomeChange() {
        this.state.openHomeChangeModal = false;
        this.props.history.push("/game-board");
    }

    onConfirmHomeChange() {
        this.state.openHomeChangeModal = false;
        this.setBodyObj();
        this.updateConditionTable();
        this.updateHomeInfo();
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        this.props.setGameInfo(0, "homeChangeNbrMonths");
        this.updatePictureInfo();
        this.updateEventTracker();
        sessionStorage.removeItem("ChangeHomeDepositRefund");
        sessionStorage.removeItem("homeChangeType");
        
        this.props.onChangeComplete();
        this.props.history.push('/status-display');
    }

    setBodyObj(){
        const newCashAmt = sessionStorage.getItem("changeHomeNewCashAmt");
        this.chgCashAmt = (this.gameInfo.gameData.currentCash - newCashAmt) * -1;;
        const newHomeId = sessionStorage.getItem("homeChoice");
        const newHomeType = sessionStorage.getItem("homeType");
        const newRoommates= sessionStorage.getItem("homeRoommates");
        const newBedrooms = sessionStorage.getItem("homeBedrooms");
        const newAssetObj = JSON.parse(sessionStorage.getItem("changeHomeAssetObjects"));
        const newExpenseObj = JSON.parse(sessionStorage.getItem("changeHomeExpenseObjects"));
        const newLoanObj = JSON.parse(sessionStorage.getItem("changeHomeLoanObjects"));
        const moodPointChange = sessionStorage.getItem("homeChangeMoodPoints")
        const newHomeInfo = {homeID: newHomeId, homeType: newHomeType,
            roommates: newRoommates, bedrooms: newBedrooms};
        this.gameInfo.gameData.currentCash = newCashAmt;
        this.gameInfo.gameData.homeInfo = newHomeInfo;
        this.gameInfo.gameData.assetTable = newAssetObj;
        this.gameInfo.gameData.expenseTable = newExpenseObj;
        this.gameInfo.gameData.loanTable = newLoanObj;
        this.gameInfo.gameData.currentMoodPoints = +this.gameInfo.gameData.currentMoodPoints +
            +moodPointChange;
        let newIconTable = this.gameInfo.gameData.iconTable;
        let startIdx = newIconTable.length - 1;
        for (let i=startIdx; i>=0; i--){
            if (newIconTable[i].iconName == "purchaseAHome"  ){
               newIconTable.splice(i,1);
               break;
            }
        }
        this.props.setGameInfo(this.gameInfo.gameData, "gameData");
        this.bodyObj = {
            "cash": newCashAmt, 
            "homeInfo": newHomeInfo, 
            "assetTable": newAssetObj,
            "expenseTable": newExpenseObj,
            "loanTable": newLoanObj,
            "iconTable": newIconTable,
            "conditionsTable": "",
            "currentMoodPoints": this.gameInfo.gameData.currentMoodPoints,
        }
    }

    updateConditionTable(){
        this.conditionsTable = removeConditionsTable(this.gameInfo.gameData.conditionsTable, "home");
        let conditionClasses = ["home", "roommate"];
        let newHomeConditionsTable = 
            setupConditionsUpdate(this.gameInfo.gameData, this.state.conditionList, conditionClasses);
        for (let i=0; i<newHomeConditionsTable.length; i++){
            this.conditionsTable.push(newHomeConditionsTable[i]);
        }
        this.gameInfo.gameData.conditionsTable = this.conditionsTable;
        this.bodyObj.conditionsTable = this.conditionsTable;
    }
          
    updateHomeInfo()
    {  
        const funcName = "updateHomeInfo";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'PUT', headers: this.state.headers, 
        body: JSON.stringify(this.bodyObj)})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        //  successful update of gameData
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
    }
    
    updatePictureInfo(){
        let homeChangeType = sessionStorage.getItem("homeChangeType");
        if (homeChangeType != undefined && homeChangeType == "roommate-change"){
             let homeDesc = sessionStorage.getItem("homeDesc");
             this.gameInfo.pictureInfo.homeName = homeDesc;
        }else{
            let homeID = sessionStorage.getItem("homeChoice");
            let homeDesc = sessionStorage.getItem("homeDesc");
            let homeDirectory = sessionStorage.getItem("homeDirectory");
            let homePictureImg = './assets/' + homeDirectory + "/default.jpg";
            let additionalPhotosArr = JSON.parse(sessionStorage.getItem("homeAdditionalPhotosArr"));
            let livingRoomImg = getHomePicture(homeDirectory, additionalPhotosArr, "Living Room");
            this.gameInfo.pictureInfo = resetPicturesForChanges("home", this.gameInfo.pictureInfo,    
                homeID, homeDesc, homePictureImg, livingRoomImg);
            this.props.setGameInfo(this.gameInfo.pictureInfo, 'pictureInfo');
        }
    }

   
    updateEventTracker(){
        const homeName = sessionStorage.getItem("homeDesc");
        let response = {changeMade: "home", previous: this.state.previousHomeName, new: homeName};
        let eventObj = setEventTrackerObj(this.gameInfo.gameData, response, "Buy Different Home", 3000);
        this.eventArr=[eventObj];
        let currDateTime = getCurrentDateTime();
        let dataObject = {
            player: this.gameInfo.gameData.user,
            gameID: this.gameInfo.gameData._id,
            eventType: "ChangeGameChoices",
            date: currDateTime.date,
            time: currDateTime.time,
            process: "ChangeHome",
            events: this.eventArr,
        };
        const funcName = "saveEventTracker";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            {
            method:'post',
            headers: this.state.headers,
            body: JSON.stringify(dataObject)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                           // save of event tracker was successful in homeChange
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: dataObject};
                            this.props.processError(errorObj);
                        }
                    });
                };
        });
    }
        
}

export default withRouter(HomeChange);