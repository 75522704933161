import replaceTextInString from "../../../objects/CommonUse/replace_text_in_string";
import friend1GameDialogDataset from "../dialogDatasets/friend1_game_dialog_dataset";
import friend2GameDialogDataset from "../dialogDatasets/friend2_game_dialog_dataset";
import friend3GameDialogDataset from "../dialogDatasets/friend3_game_dialog_dataset";
import friend4GameDialogDataset from "../dialogDatasets/friend4_game_dialog_dataset";
import { checkDialogConditions } from "./check_dialog_conditions";

export const retrieveGameDialog = (friendName, lastIndx, playerConditions) => {
    let dialogTable = [];
    let selectDialog = false;
    let returnDialogObj = "";  
    let dialogPlayerConditions = JSON.parse(JSON.stringify(playerConditions));
 
    switch (friendName){
        case "Jennifer":
            dialogTable = friend1GameDialogDataset.dialogTable;
            break;
        case "Charlie":
            dialogTable = friend2GameDialogDataset.dialogTable;
            break;
        case "Cindy":
            dialogTable = friend3GameDialogDataset.dialogTable;
            break;
        case "Cameron":
            dialogTable = friend4GameDialogDataset.dialogTable;
            break;
    }
    if (lastIndx == "firstTime"){
        lastIndx = 0;
    }else{
        lastIndx = +lastIndx + 1;
    }
    let nbrDialogItems = dialogTable.length
    if (lastIndx > nbrDialogItems - 1){
         // start dialog again but start at 1 to skip over intro dialog item
          lastIndx = 1;
    }
    let dialogText = "";
    let dialogTitle = "";
    let responses = "";
    for (let i=lastIndx; i<nbrDialogItems && selectDialog == false; i++){
         let checkDialogObj = dialogTable[i];  
         dialogTitle = checkDialogObj.title;
         dialogText = checkDialogObj.text; 
         if (checkDialogObj.conditionList != undefined && checkDialogObj.conditionList.length > 0){
               selectDialog = checkDialogConditions (checkDialogObj.conditionList, dialogPlayerConditions);
         }else{
               lastIndx = i; 
               selectDialog = true;
         }
         if (selectDialog == true){
            responses = checkDialogObj.responses;
         }
    }

    if (selectDialog == true){
        returnDialogObj = {title: dialogTitle, text: dialogText, responses: responses, 
            dialogIndx: lastIndx, nbrDialogItems: nbrDialogItems};
    }else{
        returnDialogObj = {text: "", responses: [], dialogIndx: 0};
    }
  
    return returnDialogObj;
   
}
export default retrieveGameDialog;

