import beforeAfterTableCompare from "./before_after_table_compare";
import deleteObject from "./delete_object";

export const beforeAfterCompare = (dataType, inBeforeObject, inAfterObject) => {
   
    let dataSubTypeArr = [];
    let objectNameArr = [];
    let nonMatchInfo = {};
    let beforeObject = JSON.parse(JSON.stringify(inBeforeObject));
    let afterObject = JSON.parse(JSON.stringify(inAfterObject));
    if (dataType === "gameData"){
        objectNameArr = ["hand"];
    }
    objectNameArr.push("dateUpdated", "dateAdded", "__v", "_id");
    deleteObject(objectNameArr, beforeObject, afterObject);
    objectNameArr.splice(0);
   
     
    const objectSorter = GFG_Object =>
        Object.keys(GFG_Object)  
        .sort()
        .reduce((finalObject, key) => {  
        finalObject[key] = GFG_Object[key];  
        return finalObject;  
    }, {}); 

    beforeObject = objectSorter(beforeObject);
    afterObject = objectSorter(afterObject);
     
    const isEqual = (obj1, obj2) => JSON.stringify(obj1) == JSON.stringify(obj2);
   
    const dataValid = isEqual(beforeObject, afterObject);
    if (dataValid === false){
        if (dataType === "gameData"){
            if (!isEqual(beforeObject.assetTable, afterObject.assetTable)){
                nonMatchInfo = beforeAfterTableCompare(beforeObject.assetTable, afterObject.assetTable, "object");
                dataSubTypeArr.push({data: "assetTable", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.expenseTable, afterObject.expenseTable)){
                nonMatchInfo = beforeAfterTableCompare(beforeObject.expenseTable, afterObject.expenseTable, "object");
                dataSubTypeArr.push({data: "expenseTable", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.loanTable, afterObject.loanTable)){
                nonMatchInfo = beforeAfterTableCompare(beforeObject.loanTable, afterObject.loanTable, "object");
                dataSubTypeArr.push({data: "loanTable", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.skillSurvey, afterObject.skillSurvey)){
                nonMatchInfo = beforeAfterTableCompare(beforeObject.skillSurvey, afterObject.skillSurvey, "simple");
                dataSubTypeArr.push({data: "skillSurvey", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.conditionsTable, afterObject.conditionsTable)){
                nonMatchInfo = beforeAfterTableCompare(beforeObject.conditionsTable, afterObject.conditionsTable, "object");
                dataSubTypeArr.push({data: "conditionsTable", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.unlockedFeatures, afterObject.unlockedFeatures)){
                nonMatchInfo = beforeAfterTableCompare(beforeObject.unlockedFeatures, afterObject.unlockedFeatures, "object");
                dataSubTypeArr.push({data: "unlockedFeatures", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.friendList, afterObject.friendList)){
                nonMatchInfo = beforeAfterTableCompare(beforeObject.friendList, afterObject.friendList, "object");
                dataSubTypeArr.push({data: "friendList", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.jobInfo, afterObject.jobInfo)){
                nonMatchInfo = {indx: 0, beforeEntry: beforeObject.jobInfo, 
                    afterEntry: afterObject.jobInfo};
                dataSubTypeArr.push({data: "jobInfo", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.carInfo, afterObject.carInfo)){
                nonMatchInfo = {indx: 0, beforeEntry: beforeObject.carInfo, 
                    afterEntry: afterObject.carInfo};
                dataSubTypeArr.push({data: "carInfo", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.homeInfo, afterObject.homeInfo)){
                nonMatchInfo = {indx: 0, beforeEntry: beforeObject.homeInfo, 
                    afterEntry: afterObject.homeInfo};
                dataSubTypeArr.push({data: "homeInfo", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.spendingHistory, afterObject.spendingHistory)){
                nonMatchInfo = {indx: 0, beforeEntry: beforeObject.spendingHistory, 
                    afterEntry: afterObject.spendingHistory};
                dataSubTypeArr.push({data: "spendingHistory", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.moodPointHistory, afterObject.moodPointHistory)){
                nonMatchInfo = {indx: 0, beforeEntry: beforeObject.moodPointHistory, 
                    afterEntry: afterObject.moodPointHistory};
                dataSubTypeArr.push({data: "moodPointHistory", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.iconTable, afterObject.iconTable)){
                nonMatchInfo = beforeAfterTableCompare(beforeObject.iconTable, afterObject.iconTable, "object");
                dataSubTypeArr.push({data: "iconTable", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.badgesEarned, afterObject.badgesEarned)){
                nonMatchInfo = beforeAfterTableCompare(beforeObject.badgesEarned, afterObject.badgesEarned, "object");
                dataSubTypeArr.push({data: "badgesEarned", nonMatchInfo: nonMatchInfo});
            }
            if (!isEqual(beforeObject.pendingJob, afterObject.pendingJob)){
                nonMatchInfo ={indx: 0, beforeEntry: beforeObject.pendingJob, 
                    afterEntry: afterObject.pendingJob};
                dataSubTypeArr.push({data: "pendingJob", nonMatchInfo: nonMatchInfo});
            }
            objectNameArr = ["assetTable", "expenseTable", "loanTable", "skillSurvey", "conditionsTable",
                "unlockedFeatures", "friendList", "jobInfo", "carInfo", "homeInfo", "spendingHistory",
                "moodPointHistory", "iconTable", "badgesEarned", "pendingJob"];
            deleteObject(objectNameArr, beforeObject, afterObject);
          
            if (!isEqual(beforeObject, afterObject)){
                nonMatchInfo = {indx: 0, beforeEntry: beforeObject, afterEntry: afterObject};
                dataSubTypeArr.push({data: "generalGameData", nonMatchInfo: nonMatchInfo});
            }
        }
    }
    if (dataType === "creditCardInfo"){
        if (!isEqual(beforeObject.periodCreditCharges, afterObject.periodCreditCharges)){
            nonMatchInfo = beforeAfterTableCompare(beforeObject.periodCreditCharges, afterObject.periodCreditCharges, "object");
            dataSubTypeArr.push({data: "creditCard.PeriodCreditCharges", nonMatchInfo: nonMatchInfo});
        }
    }
    if (dataType === "gamePurchases"){
        if (!isEqual(beforeObject.purchaseTable, afterObject.purchaseTable)){
            nonMatchInfo = beforeAfterTableCompare(beforeObject.purchaseTable, afterObject.purchaseTable, "object");
            dataSubTypeArr.push({data: "purchaseTable", nonMatchInfo: nonMatchInfo});
        }
    }
    const returnObj = {dataValid: dataValid, dataType: dataType, dataSubTypeArr: dataSubTypeArr };

    return returnObj;
}
export default beforeAfterCompare;